import React from 'react';

import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import './styles.scss';
import { clearFilter as clearFilterCard } from 'store/reducers/cardsFilterSlice';
import { clearFilter as clearFilterCount } from 'store/reducers/countFilterSlice';
import { clearFilter as clearFilterProduction } from 'store/reducers/productionsFilterSlice';
import { clearFilter as clearFilterProduct } from 'store/reducers/productsFilterSlice';

function HoverItems({
  restaurantId = 0,
  currentRole = {},
  setOpenHover = () => {},
  reference = null,
  confirmModal,
  setConfirmModal,
  storedQuotationProductsSelected,
  isQuotationPage
}) {
  const dispatch = useDispatch();
  const openConfirmationDialog =
    storedQuotationProductsSelected?.length && isQuotationPage;

  const clearLocalStorageFilters = () => {
    localStorage.removeItem('cardId');
    localStorage.removeItem('productId');
    dispatch(clearFilterCard());
    dispatch(clearFilterCount());
    dispatch(clearFilterProduction());
    dispatch(clearFilterProduct());
  };

  const handleRedirect = (pathName) => {
    setConfirmModal({
      ...confirmModal,
      name: 'quotation',
      open: true,
      redirectPath: pathName,
      confirmText: 'Sim',
      cancelText: 'Não',
      content: (
        <Typography className="bold">
          Você perderá todos os itens selecionados, deseja continuar?
        </Typography>
      )
    });
  };

  const showJustLabel = currentRole?.subs?.length === 1;
  return (
    <div
      className={`onHoverSideBar ${currentRole?.name}`}
      onMouseLeave={() => setOpenHover(false)}
      ref={reference}
      id="hoverItemSideBar"
    >
      <div className="onHoverSideBarHeader">
        {currentRole.subs?.length === 1 ? (
          <Link
            className="hoverItemTitle"
            onClick={(e) => {
              if (!e.ctrlKey) {
                clearLocalStorageFilters();
              }
            }}
            to={`${currentRole.url !== '' ? '/'+currentRole.url : ''}/${restaurantId}`}
          >
            {currentRole?.label}
          </Link>
        ) : (
          <Typography className="hoverItemTitle">
            {currentRole?.label}
          </Typography>
        )}
      </div>

      {!showJustLabel && currentRole.subs && (
        currentRole?.subs?.map((subtitle) => (
          <Link
            className="hoverItemDefault"
            onClick={(e) => {
              if (!e.ctrlKey) {
                clearLocalStorageFilters();
              }

              if (openConfirmationDialog) {
                handleRedirect(`/${subtitle.url}/${restaurantId}`);
              }
            }}
            to={!openConfirmationDialog && `/${subtitle.url}/${restaurantId}`}
          >
            {subtitle?.label}
          </Link>
        ))
      )}
    </div>
  );
}

export default HoverItems;
