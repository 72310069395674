/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';

import {
  Fade,
  Tooltip,
  useMediaQuery
} from '@material-ui/core';
import moment from 'moment';
import { useParams } from 'react-router';

import ConfigColumnsIcon from 'images/icons/columns/collumnsWhiteOutline.svg';
import ConfigIcon from 'images/icons/configsAndSetups/configIconWhite.svg';
import DownloadIcon from 'images/icons/downloadUpload/downloadIcon.svg';

import {
  TrTablet,
  TrDesk,
} from './styles';

import { handleSelectedRealDiff } from '../MainFunctions';

function DiaryCountTableHead({
  countGroupInfos,
  handleProductDialog,
  periodicityDescription,
  periodicityId,
  storageLocationDescription,
  isSelectedCost,
  products,
  countDate,
  parametersInfos,
  differenceBetweenCountAndMenuAlert,
  downloadReportConsolidated,
  handlePopoverClick,
  showColumns,
  smallScreen
}) {
  const { id: paramsID } = useParams();
  const isMobile = useMediaQuery('(max-width: 821px)');
  const reportTabObj = countGroupInfos?.countGroup?.counts?.find(
    (el) => el?.type === 'report'
  );
  const hasOnlyOneCount =
    countGroupInfos?.countGroup?.counts?.filter(
      (el) => el?.type !== 'report' && el
    ).length === 1;

  const canShowTheoreticalAlert = useMemo(() => {
    const isSameDayOrAfter = moment(countDate).isSameOrAfter(moment(), 'day');

    return (
      countDate && isSameDayOrAfter && !parametersInfos?.closure?.runManually
    );
  }, [parametersInfos, countDate]);

  const colspan = () => {
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && showColumns?.find((el) => el === 'USUÁRIO') && !reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 3
    } 
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && !showColumns?.find((el) => el === 'USUÁRIO') && !reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 4
    }
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && showColumns?.find((el) => el === 'USUÁRIO') && reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length 
    }
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && !showColumns?.find((el) => el === 'USUÁRIO') && reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 1
    }
  }

  return (
    <thead
      style={{
        position: 'sticky',
        top:
          countGroupInfos?.countGroup?.counts?.length &&
            countGroupInfos.isCountGroup
            ? smallScreen 
              ? differenceBetweenCountAndMenuAlert
                ? '268px' 
                : '225px' 
              : differenceBetweenCountAndMenuAlert 
                ? '135px'
                : '127px'
            : '127px',
        backgroundColor: 'white',
        zIndex: '990'
      }}
    >
      <TrDesk>
        <th style={{ width: '21%' }} className="subtitle">
          <div className="dFlex alignCenter">
            <span
              onClick={() =>
                handleProductDialog(
                  periodicityDescription,
                  storageLocationDescription
                )
              }
              className="cursorPointer white"
            >
              {products?.length} PRODUTOS
            </span>

            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <p
                  className="dFlex mb0"
                  style={{
                    padding: '2px 5px'
                  }}
                >
                  Gerenciar os Produtos desta Contagem
                </p>
              }
            >
              <img
                style={{ width: '15px' }}
                src={ConfigIcon}
                alt="ConfigIcon"
                className="ml10 cursorPointer"
                onClick={() =>
                  handleProductDialog(
                    periodicityDescription,
                    storageLocationDescription
                  )
                }
              />
            </Tooltip>

            {countGroupInfos.isCountGroup && !hasOnlyOneCount && (
              <p
                className="m0 ml5 cursorPointer dFlex justifyEnd"
                style={{
                  color: 'rgba(80,98,240,1)',
                  textDecoration: 'underline',
                  padding: '0 6px'
                }}
                onClick={() =>
                  downloadReportConsolidated(periodicityId, countDate)
                }
              >
                <Tooltip
                  placement="top"
                  title={
                    <p className="mb0" style={{ padding: '4px 2px' }}>
                      Download do relatório
                    </p>
                  }
                >
                  <img src={DownloadIcon} alt="Report" />
                </Tooltip>
              </p>
            )}

            <p
              className="m0 cursorPointer dFlex justifyEnd"
              style={{
                color: 'rgba(80,98,240,1)',
                textDecoration: 'underline',
              }}
              onClick={(e) => handlePopoverClick(e)}
            >
              <Tooltip
                placement="top"
                title={
                  <p className="mb0" style={{ padding: '4px 2px' }}>
                    Exibir/Ocultar colunas
                  </p>
                }
              >
                <img src={ConfigColumnsIcon} alt="Config Show Columns" />
              </Tooltip>
            </p>
          </div>
        </th>

        {!reportTabObj?.active && (
          <>
            <th style={{ borderLeft: 'solid 1px #E6E7F0' }} className="subtitle p8">
              SKU
            </th>

            <th style={{ width: '5%', borderLeft: 'solid 1px #E6E7F0' }} className="subtitle p8">
              EMBALAGEM
            </th>

            <th
              style={{ width: '9%', borderRight: 'solid 1px #BFC0CB' }}
              className="subtitle right p8"
            >
              CONTAGEM
            </th>
          </>
        )}

        <th
          style={{ width: '9.5%' }}
          className={`subtitle p8 ${!showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
        >
          {reportTabObj?.active ? 'ESTOQUES' : 'OUTROS ESTOQUES'}
        </th>

        <th
          style={{ width: '7.5%', borderLeft: 'solid 1px #0B1A8E', background: '#0B1A8E' }}
          className={`subtitle p8 right ${!showColumns?.includes('ÚLTIMA') && 'dNone'
            }`}
        >
          ESTOQUE INICIAL
        </th>

        <th
          className={`subtitle p8 right ${!showColumns?.includes('CONSUMO') && 'dNone'
            }`}
          style={{ width: '7.5%', borderLeft: 'solid 1px #E6E7F0', background: '#0B1A8E' }}
        >
          ENTRADAS/SAÍDAS
        </th>

        <th
          style={{ width: '7.5%', borderLeft: 'solid 1px #E6E7F0', background: '#0B1A8E' }}
          className={`subtitle pr8 right ${!showColumns?.includes('TEÓRICO') && 'dNone'
            }`}
        >
          ESTOQUE TEÓRICO
        </th>

        <th
          style={{ width: '6.5%', borderLeft: 'solid 1px #E6E7F0', background: '#0B1A8E' }}
          className={`subtitle pl8 pr8 right ${!showColumns?.includes('REAL') && 'dNone'
            }`}
        >
          CONTAGEM
        </th>

        {/* <th
            style={{ width: '5%', borderLeft: 'solid 1px #BFC0CB' }}
            className="subtitle right p8"
          >
            PREÇO R$
          </th>

          <th style={{ width: '5%' }} className="subtitle right p8">
            TOTAL R$
          </th> */}

        <th
          style={{ width: '6.5%', borderLeft: 'solid 1px #5062F0', background: '#5062F0' }}
          className={`subtitle p8 right ${!showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
            } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
        >
          EM {isSelectedCost ? 'R$' : 'QTDE'}
        </th>

        <th
          style={{ width: '6%', borderLeft: 'solid 1px #E6E7F0', background: '#5062F0' }}
          className={`subtitle p8 right ${!showColumns?.includes('DIFERENÇA EM %') && 'dNone'
            }`}
        >
          % / CONSUMO
        </th>

        <th
          style={{ width: '5%', borderLeft: 'solid 1px #E6E7F0' }}
          className={`subtitle p8 ${!showColumns?.includes('USUÁRIO') && 'dNone'
            }`}
        >
          USUÁRIO
        </th>
      </TrDesk>

      <TrTablet>
        <th width={reportTabObj?.active ? "80%" : "37%"} className="subtitle">
          <div className="dFlex alignCenter">
            <span
              onClick={() =>
                handleProductDialog(
                  periodicityDescription,
                  storageLocationDescription
                )
              }
              className="cursorPointer white"
            >
              {isMobile ? 'PRODUTO' : `${products?.length} PRODUTOS`}
            </span>
            
            {!isMobile ?
              <>
                <Tooltip
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                  title={
                    <p
                      className="dFlex mb0"
                      style={{
                        padding: '2px 5px'
                      }}
                    >
                      Gerenciar os Produtos desta Contagem
                    </p>
                  }
                >
                  <img
                    style={{ width: '15px' }}
                    src={ConfigIcon}
                    alt="ConfigIcon"
                    className="ml10 cursorPointer"
                    onClick={() =>
                      handleProductDialog(
                        periodicityDescription,
                        storageLocationDescription
                      )
                    }
                  />
                </Tooltip>

                <p
                  className="m0 ml5 cursorPointer dFlex justifyEnd"
                  style={{
                    color: 'rgba(80,98,240,1)',
                    textDecoration: 'underline',
                    padding: '0 6px'
                  }}
                  onClick={(e) => handlePopoverClick(e)}
                >
                  <Tooltip
                    placement="top"
                    title={
                      <p className="mb0" style={{ padding: '4px 2px' }}>
                        Exibir/Ocultar colunas
                      </p>
                    }
                  >
                    <img src={ConfigColumnsIcon} alt="Config Show Columns" />
                  </Tooltip>
                </p>
              </>
            : null}
          </div>
        </th>
        
        {!reportTabObj?.active ?
          <>
            <th style={{ width: '25%', borderLeft: 'solid 1px #E6E7F0' }} className="subtitle p8">
              SKU
            </th>

            <th style={{ width: '10%', borderLeft: 'solid 1px #E6E7F0' }} className="subtitle p8">
              EMBALAGEM
            </th>
          
            <th style={{ width: '14%' }} className="subtitle p8 right">
              CONTAGEM
            </th>
          </>
        : null}

        <th
          style={{ width: '14%' }}
          className={`subtitle p8 right ${!showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
        >
          ESTOQUES
        </th>
      </TrTablet>
    </thead>
  );
}

export default DiaryCountTableHead;
