/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
import { memo, useEffect, useState } from 'react';

import { CircularProgress, Fade, Tooltip, useMediaQuery } from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment/moment';

import StockNoEntryOrExit from 'images/icons/arrows/arrowUpAndDownGrayOutline.svg';
import StockEntryNoExit from 'images/icons/arrows/arrowUpGrayAndDownNavyBlueOutline.svg';
import StockNoEntryExit from 'images/icons/arrows/arrowUpRedAndDownGrayOutline.svg';
import ExtractsDialogIcon from 'images/icons/objects/clocks/clockArrowBackLightBlueOutline.svg';
import InfoIcon from 'images/icons/signals/infos/info.svg';
import StocksMoreInfoIcon from 'images/icons/signals/moreInfos/threeDotsLightBlueWhiteBgRectangleRounded.svg';
import StocksMoreInfoIconSelected from 'images/icons/signals/moreInfos/threeDotsWhiteLightBlueBgRectangleRounded.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function ReportTableBody({
  getId,
  setModalStates,
  modalStates,
  setParameters,
  setProductModal,
  setProductDescription,
  products,
  parametersInfos,
  isSelectedRealDiff,
  countDate,
  showColumns,
  isSelectedCost,
  setRealStockModal,
  setModalSettings,
  modalSettings,
  handleGetExtract,
  consumedInOutInfos,
  setConsumedInOutInfos,
  loadingInOutInfos,
  setLoadingInOutInfos,
  setStockDetailsAnchor,
  popoverOpenStockDetails,
  setSelectedProduct,
  selectedProduct
}) {
  const isMobile = useMediaQuery('(max-width: 821px)');
  const filteredProducts = products?.filter((el) => !el?.hide);
  const [extractItems, setExtractItem] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [hoveredOut, setHoveredOut] = useState(false);
  const [realStock, setRealStock] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const mouseEnterHandler = (product) => {
    setHovered(true);
    setCurrentProduct(product);
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
    setHoveredOut(true);
    setCurrentProduct();
    setRealStock([]);
  };

  const onTimeout = (id) => {
    axios
      .get(
        `${environments.catalog}/inventories/counts/${currentProduct?.id || id}/stocks/${countDate}`
      )
      .then((response) => {
        setRealStock(response.data.content);
        setRealStockModal(response.data.content)
      })
      .catch((error) => {
        console.log(error);
      })
  };

  const realStockItemValues = (product) => (
    <div style={{ width: '20px' }}>
      {product?.stocks?.totalStocks > 0 && (
        <div
          className="dFlex alignCenter"
          onMouseOver={() => mouseEnterHandler(product)}
          onMouseLeave={mouseLeaveHandler}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <div
                className="dFlex flexColumn"
                style={{ width: '380px', paddingTop: 5 }}
              >
                {realStock?.length ? (
                  realStock.map((item) => (
                    <div className="dFlex justifyBetween">
                      <p
                        style={{
                          width: '50%',
                          padding: '0 8px 0 0',
                          margin: '0px 5px 5px 0px',
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12
                        }}
                      >
                        {item.stock}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                          textAlign: 'right'
                        }}
                      >
                        {item.quantity
                          ? parseFloat(item.quantity).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                          : '0,000'}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                        }}
                      >
                        {item.user ? item.user : '-'}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="countsCircularProgressBar dFlex justifyCenter">
                    <CircularProgress />
                  </div>
                )}
              </div>
            }
          >
            <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      )}
    </div>
  );

  const setColor = (percentage, lossPercentage, isFirst, diff, consumed) => {
    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
    let color;

    if (isFirst)
      color =
        percentage >= range[0] && percentage <= range[1]
          ? '#D2F9D6'
          : percentage >= lossPercentage
          ? '#FFF2CC'
          : '#F7D4D7';
    else
      color =
        percentage >= range[0] && percentage <= range[1]
          ? '#006608'
          : percentage >= lossPercentage
          ? '#AA8208'
          : '#F53D4C';

    if (!consumed) {
      if (isFirst)
        color = diff == 0 ? '#D2F9D6' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
      else color = diff == 0 ? '#006608' : diff > 0 ? '#AA8208' : '#F53D4C';
    }

    return color;
  };

  const previousAndBeforeDate = () => {
    if (moment(countDate).isSameOrAfter(moment(), 'day')) {
      return moment(countDate).isSameOrAfter(moment(), 'day');
    }
    return moment(countDate).isBefore(moment(), 'day');
  };

  function validateNumber(number) {
    if (number === '-0,000') number = number.replace('-', '');

    return number;
  }

  async function fetchExtracts(product, isModal = true) {
    const fetchParameters = await fetch(
      `${environments.restaurants}/companies/${getId}/parameters`
    );
    const parameters = await fetchParameters.json();
    setParameters(parameters.product.type);

    const formattedProduct = {
      ...product,
      id: product?.productId
    }

    let date = format(new Date(), 'yyyy-MM-dd');

    if (formattedProduct.lastCount) {
      date = moment(formattedProduct?.lastCount)?.format('YYYY-MM-DD');
    }

    const response = await fetch(
      `${environments.catalog}/extracts?originId=${getId}&productId=${formattedProduct?.id}&date=${date}`
    );
    const extracts = await response.json();

    if (Array.isArray(extracts)) setExtractItem(extracts);
    else setExtractItem([]);

    setProductModal(formattedProduct);
    setProductDescription(formattedProduct.description);
    setModalStates((prevState) => ({ ...prevState, modalProduct: true }));
  }

  useEffect(() => {
    const timer = hovered && setTimeout(onTimeout, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [hovered]);

  return filteredProducts?.map((product, productIndex) => {
    const unitMeasurement = product?.consumptionUnity || product?.consumptionUnitsPrimary?.abbreviation
    const lossPercentage = product?.lossPercentage
      ? parseFloat(product?.lossPercentage)
      : 0;
    const theoreticalStock =
      product?.theoreticalStock || product?.theoricalStock || 0;
    const difference =
      product?.realStock !== null || product?.realStock !== undefined
        ? (product?.realStock ? parseFloat(Number(product?.realStock)) : 0) -
          (theoreticalStock ? parseFloat(Number(theoreticalStock)) : 0)
        : null;

    const percentage = product?.consumed
      ? (difference / parseFloat(Number(product?.consumed))) * 100
      : 0;
    const percent = percentage
      ? percentage.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      : '0,00';
    const differenceInReal =
      typeof parseFloat(product?.realStock) === 'number'
        ? Number(difference) * Number(product?.cost)
        : null;
    const parsedDifferenceInReal = differenceInReal
      ? differenceInReal.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      : '0,000';
    const diff = difference
      ? difference.toLocaleString('pt-br', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3
        })
      : '0,000';
    const entryAndExitValue = ((product?.theoreticalStock || 0) - (product?.historical?.realStock || 0))
    const entryAndExitSignal = entryAndExitValue === 0 || entryAndExitValue < 0
      ? ''
      : '+'

    return (
      <>
        <tr className="firstLine">
          <td
            colSpan={product.type === 'INTERNAL' ? 2 : 1}
            width="20"
            className="description"
            style={{
              paddingTop: 3,
              paddingLeft: 10
            }}
          >
            <p style={{ display: 'flex', gap: 2, lineHeight: 2 }}>{productIndex + 1} - {ValidationLength(product.description, 48)}</p>

            <div className="h100 " />
          </td>

          <td
            width="5"
            id={`otherStock${productIndex}`}
            className={`description right p8 pl12 ${
              !showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
            style={{
              borderLeft: 'solid 1px #E6E7F0',
              maxWidth: '10px',
              background:
                product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks) || !isMobile
                  ? 'transparent'
                  : setColor(
                      percentage,
                      lossPercentage,
                      true,
                      difference,
                      product?.consumed
                    ),
              color: product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks) || !isMobile
                ? '#1F2445'
                : setColor(
                  percentage,
                  lossPercentage,
                  false,
                  difference,
                  product?.consumed
              ),
            }}
            onClick={(event) => {
              setStockDetailsAnchor(event.currentTarget)
              setSelectedProduct(product)
            }}
          >
            <div className={`dFlex justifyBetween ${isMobile ? 'w100 justifyEnd' : 'justifyBetween'}`}>
              <div className={`dFlex ${isMobile ? 'flexColumn alignEnd othersContent' : 'alignCenter'}`}>
                <div className="othersStocksVolume">
                  <span className={`${product?.stocks?.countedStocks === product?.stocks?.totalStocks && product?.stocks?.countedStocks > 0 ? 'bold' : ''}`}>
                    {product?.stocks?.totalStocks > 0
                      ? `${product.stocks.countedStocks}/${product.stocks.totalStocks}`
                      : product?.stocks?.countedStocks
                    }
                  </span>

                  {isMobile ?
                    <div 
                      id={product?.id}
                      className="iconInfo alignCenter"
                    >
                      <img 
                        src={popoverOpenStockDetails && selectedProduct?.id === product?.id 
                          ? StocksMoreInfoIconSelected 
                          : StocksMoreInfoIcon
                        } 
                        alt="StockDetailsIcon" 
                        style={{ width: 35, height: 18 }} 
                      />
                    </div>
                  :
                    <div
                      onClick={() => {
                        onTimeout(product?.id)
                        setModalSettings(
                          modalSettings.map((modal) =>
                            modal.name === 'stockModal' ? { ...modal, open: true } : modal
                          )
                        );
                      }} 
                      className='iconInfo'
                    >
                      <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
                    </div>
                  }
                </div>

                {isMobile ?
                  <p className='content-report-tablet bold' style={{ marginBottom: 0 }}>
                    {typeof parseFloat(product?.realStock) === 'number' &&
                    product?.realStock !== null
                      ? (product?.realStock)?.toLocaleString('pt-br', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })
                      : '0,000'}{' '}
                    {unitMeasurement}
                  </p>
                : 
                  <span className="dFlex alignCenter reportSpan bold">
                    {realStockItemValues(product)}
                  </span>
                }
              </div>
            </div>
          </td>
          
          {!isMobile &&
            <>
              <td
                width="5"
                id={`lastRealStock${productIndex}`}
                className={`description right p8 bold ${
                  !showColumns?.includes('ÚLTIMA') && 'dNone'
                }`}
                style={{
                  maxWidth: 20,
                  color: '#333541',
                  background: '#F2F3F8'
                }}
              >
                <p className="content-report-desk">
                  {(product?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })}{' '}
                  {unitMeasurement}
                </p>

                <p className="content-report-tablet">
                  {(product?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })}{' '}
                  <br />
                  {unitMeasurement}
                </p>
              </td>

              <td
                width="5"
                id={`consumed${productIndex}`}
                className={`description right p8 bold ${
                  !showColumns?.includes('CONSUMO') && 'dNone'
                }`}
                style={{ 
                  borderLeft: 'solid 1px #E6E7F0', 
                  maxWidth: 20,
                  color: entryAndExitValue === 0 ? '#9296AC' : entryAndExitValue > 0 ? '#0B1A8E' : '#F53D4C', 
                }}
              >
                <div className="dFlex justifyEnd">
                  <p className="content-report-desk">
                    {`${entryAndExitValue}${unitMeasurement}`
                      ? `${entryAndExitSignal}${entryAndExitValue?.toLocaleString('pt-br', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })} ${unitMeasurement}`
                      : `0,000 ${unitMeasurement}`}
                  </p>

                  <p className="content-report-tablet">
                    {`${entryAndExitValue}${unitMeasurement}`
                      ? `${entryAndExitSignal}${entryAndExitValue?.toLocaleString('pt-br', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })}`
                      : `0,000`}
                    <br />
                    {unitMeasurement}
                  </p>

                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title={
                      <div
                        className="dFlex flexColumn"
                        style={{ paddingTop: 5 }}
                      >
                        {loadingInOutInfos ?
                          <div className="countsCircularProgressBar dFlex justifyCenter">
                            <CircularProgress color="#FFF" />
                          </div>  
                        : null}

                        {!loadingInOutInfos && !consumedInOutInfos?.positive?.length && !consumedInOutInfos?.negative?.length ?
                          <span className="white mb5">
                            Nenhuma entrada ou saída encontrada
                          </span>
                        : null}

                        {consumedInOutInfos?.positive?.length ?
                          <div className="consumedEntryBox mb10">
                            <span className="consumedEntryTitle">
                              ENTRADAS
                            </span>

                            {consumedInOutInfos?.positive.map((item) => (
                              <div className="dFlex justifyStart">
                                <p>
                                  {moment(item?.date).format('DD/MM')} - 
                                </p>

                                <p className="ml2">
                                  {item?.description}:
                                </p>

                                <p className="ml2">
                                  {item.quantity
                                    ? parseFloat(item.quantity).toLocaleString('pt-br', {
                                      minimumFractionDigits: 3
                                    })
                                    : '0,000'
                                  } {" "}
                                  {item?.unity?.abbreviation}
                                </p>
                              </div>
                            ))}
                          </div>
                        : null}

                        {consumedInOutInfos?.negative?.length ?
                          <div className="consumedExitBox">
                            <span className="consumedExitTitle">
                              SAÍDAS
                            </span>

                            {consumedInOutInfos?.negative.map((item) => (
                              <div className="dFlex justifyStart">
                                <p>
                                  {moment(item?.date).format('DD/MM')} - 
                                </p>

                                <p className="ml2">
                                  {item?.description}:
                                </p>

                                <p className="ml2">
                                  {item.quantity
                                    ? parseFloat(Math.abs(item.quantity)).toLocaleString('pt-br', {
                                      minimumFractionDigits: 3
                                    })
                                    : '0,000'
                                  } {" "}
                                  {item?.unity?.abbreviation}
                                </p>
                              </div>
                            ))}
                          </div>
                        : null}
                      </div>
                    }
                  >
                    <img 
                      onMouseEnter={() => {
                        setLoadingInOutInfos(true)
                        handleGetExtract(product, false)
                      }}
                      onMouseLeave={() => {
                        setConsumedInOutInfos({
                          positive: [],
                          negative: []
                        })
                      }}
                      src={entryAndExitValue === 0 ? StockNoEntryOrExit : entryAndExitValue > 0 ? StockEntryNoExit : StockNoEntryExit} 
                      alt="Stock In and Out Icon" 
                      style={{ marginLeft: 5 }} 
                    />
                  </Tooltip>
                </div>
              </td>

              <Tooltip
                id={`theoricalStock${productIndex}`}
                TransitionComponent={Fade}
                disableHoverListener
                TransitionProps={{ timeout: 600 }}
                title={`Consumo: ${
                  product?.consumed
                    ? product?.consumed.toLocaleString('pt-br', {
                        maximumFractionDigits: 3,
                        minimumFractionDigits: 3
                      })
                    : '0,000'
                }`}
              >
                <td
                  width="7%"
                  className={`description right p8 ${
                    !showColumns?.includes('TEÓRICO') && 'dNone'
                  }`}
                  style={{ background: 'rgba(0, 19, 115, 0.08)' }}
                >
                  <div className="dFlex alignCenter justifyEnd">
                    <p
                      id="teoricValue"
                      className="materialBlue mb0 cursorPointer content-report-desk"
                      onClick={() => {
                        fetchExtracts(product);
                      }}
                    >
                      {theoreticalStock
                        ? `${theoreticalStock?.toLocaleString('pt-br', {
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 3
                          })} ${unitMeasurement} `
                        : !parametersInfos?.closure?.runManually
                          ? '-'
                          : `0,000 ${unitMeasurement}`
                      }
                    </p>

                    <p
                      id="teoricValue"
                      className="materialBlue mb0 cursorPointer content-report-tablet"
                      onClick={() => {
                        fetchExtracts(product);
                      }}
                    >
                      {theoreticalStock
                        ? `${theoreticalStock?.toLocaleString('pt-br', {
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 3
                          })} `
                        : !parametersInfos?.closure?.runManually
                        ? '-'
                        : `0,000`}
                      <br />
                      {theoreticalStock
                        ? `${unitMeasurement}`
                        : !parametersInfos?.closure?.runManually
                        ? ''
                        : ''}
                    </p>

                    <img 
                      onClick={() => {
                        fetchExtracts(product);
                      }}
                      className="cursorPointer"
                      src={ExtractsDialogIcon} 
                      alt="Extract Dialog Icon" 
                      style={{ marginLeft: 5 }} 
                    />
                  </div>
                </td>
              </Tooltip>

              <td
                width="5"
                id={`realStock${productIndex}`}
                className={`description pr8 bold ${
                  !showColumns?.includes('REAL') && 'dNone'
                }`}
                style={{
                  background: 'transparent',
                  maxWidth: '10px',
                  fontWeight: 'bold',
                  color: '#1F2445'
                }}
              >
                <Tooltip
                  TransitionProps={{ timeout: 600 }}
                  placement="right-end"
                  title={
                    <div className="dFlex flexColumn">
                      <p
                        className="mb0"
                        style={{ padding: '4px 6px 0' }}
                      >
                        Usuário: {product?.user || '-'}
                      </p>

                      <p
                        className="mb0"
                        style={{ padding: '0 6px 4px' }}
                      >
                        Data/hora: {moment(product?.date).format('DD/MM/YYYY')} - {moment(product?.date).format('HH:mm')}hs
                      </p>

                      <p
                        className="mb0"
                        style={{ padding: '8px 6px 4px' }}
                      >
                        Última contagem: {(product?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })} {unitMeasurement}
                      </p>
                    </div>
                  }
                >
                  <div>
                    <div className="dFlex justifyEnd content-report-desk">
                      {typeof parseFloat(product?.realStock) === 'number' &&
                      product?.realStock !== null
                        ? (product?.realStock)?.toLocaleString('pt-br', {
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 3
                          })
                        : '0,000'}{' '}
                      {unitMeasurement}
                    </div>

                    <div
                      className="dFlex justifyEnd content-report-tablet"
                      style={{ textAlign: 'end' }}
                    >
                      {typeof parseFloat(product?.realStock) === 'number' &&
                      product?.realStock !== null
                        ? (product?.realStock)?.toLocaleString('pt-br', {
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 3
                          })
                        : '0,000'}{' '}
                      <br />
                      {unitMeasurement}
                    </div>
                  </div>
                </Tooltip>
              </td>

              {isSelectedRealDiff && (
                <td
                  width="5"
                  id={`difference${productIndex}`}
                  className={`description right bold ${
                    !showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
                  } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
                  style={{
                    maxWidth: '10px',
                    borderLeft: 'solid 1px #E6E7F0',
                    background:
                      product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                        ? 'transparent'
                        : setColor(
                            percentage,
                            lossPercentage,
                            true,
                            difference,
                            product?.consumed
                          ),
                    color: product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                      ? '#000'
                      : setColor(
                        percentage,
                        lossPercentage,
                        false,
                        difference,
                        product?.consumed
                    ),
                  }}
                >
                  <p className="content-report-desk">
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                      ? `R$ ${parsedDifferenceInReal}`
                      : null}
                  </p>

                  <p className="content-report-tablet">
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks) ? `R$` : null}
                    <br />
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                      ? `${parsedDifferenceInReal}`
                      : null}
                  </p>
                </td>
              )}

              {!isSelectedRealDiff && (
                <td
                  width="5"
                  id={`difference${productIndex}`}
                  className={`description p8 right bold ${
                    !showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
                  } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
                  style={{
                    maxWidth: '10px',
                    borderLeft: 'solid 1px #E6E7F0',
                    background:
                      product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                        ? 'transparent'
                        : setColor(
                            percentage,
                            lossPercentage,
                            true,
                            difference,
                            product?.consumed
                          ),
                    color: product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                      ? '#000'
                      : setColor(
                        percentage,
                        lossPercentage,
                        false,
                        difference,
                        product?.consumed
                    ),
                  }}
                >
                  <p className="content-report-desk">
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                      ? difference != null
                        ? `${validateNumber(diff)} ${unitMeasurement}`
                        : '-'
                      : '-'
                    }
                  </p>

                  <p className="content-report-tablet">
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                      ? difference != null
                        ? `${validateNumber(diff)}`
                        : '-'
                      : '-'}
                    <br />
                    {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                      ? difference != null
                        ? `${unitMeasurement}`
                        : '-'
                      : '-'}
                  </p>
                </td>
              )}

              <td
                width="7%"
                id={`percentage${productIndex}`}
                className={`description right p8 bold ${
                  !showColumns?.includes('DIFERENÇA EM %') && 'dNone'
                }`}
                style={{
                  maxWidth: '10px',
                  background:
                    product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                      ? 'transparent'
                      : setColor(
                          percentage,
                          lossPercentage,
                          true,
                          difference,
                          product?.consumed
                        ),
                  color: product?.realStock == null || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)
                    ? '#000'
                    : setColor(
                      percentage,
                      lossPercentage,
                      false,
                      difference,
                      product?.consumed
                  ),
                  borderLeft: 'solid 1px #E6E7F0',
                }}
              >
                <Tooltip
                  TransitionProps={{ timeout: 600 }}
                  placement="right-end"
                  disableHoverListener={product?.realStock === null || product?.otherStock === null || percentage === null || !product?.consumed || (product?.stocks?.countedStocks !== product?.stocks?.totalStocks)}
                  title={
                    <div className="dFlex flexColumn">
                      <p
                        className="mb0"
                        style={{ padding: '4px 6px 0' }}
                      >
                        Consumo: {(product?.consumed || 0)?.toLocaleString('pt-br', {
                          maximumFractionDigits: 3,
                          minimumFractionDigits: 3
                        })} {unitMeasurement}
                      </p>
                    </div>
                  }
                >
                  <div>
                    <p className="content-report-desk">
                      {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                        ? percentage != null
                          ? product?.consumed
                            ? `${validateNumber(percent)} %`
                            : '-'
                          : null
                        : '-'
                      }
                    </p>

                    <p className="content-report-tablet">
                      {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                        ? percentage != null
                          ? product?.consumed
                            ? `${validateNumber(percent)}`
                            : '-'
                          : null
                        : '-'}
                      <br />
                      {product?.realStock != null && (product?.stocks?.countedStocks === product?.stocks?.totalStocks)
                        ? percentage != null
                          ? product?.consumed
                            ? `%`
                            : '-'
                          : null
                        : '-'}
                    </p>
                  </div>
                </Tooltip>
              </td>
                
              <Tooltip
                TransitionProps={{ timeout: 600 }}
                disableHoverListener={!product.date && !product?.user}
                show={false}
                title={
                  <p
                    className="mb0"
                    style={{ padding: '2px 6px' }}
                  >
                    {product.date ? moment(product.date).format('DD-MM-YYYY HH:mm') : '-'}
                  </p>
                }
                placement="right-end"
              >
                <td
                  width="7%"
                  id={`percentage${productIndex}`}
                  className={`description p8 bold ${
                    !showColumns?.includes('USUÁRIO') && 'dNone'
                  }`}
                  style={{borderLeft: 'solid 1px #E6E7F0'}}
                >
                  <p className="content-report-desk">
                    {product.user ? product.user : '-'}
                  </p>
                </td>
              </Tooltip>
            </>
          }
        </tr>

        <tr>
          <td colSpan={(showColumns?.length || 0) + 1}>
            <hr style={{ borderTop: 'solid 1px rgb(119, 121, 139)' }} />
          </td>
        </tr>
      </>
    );
  });
}

export default memo(ReportTableBody);
