/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  FormControlLabel,
  LinearProgress,
  Switch,
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';

import ArrowDownCircleIcon from 'images/icons/arrows/arrowDownRedLightRedBgCircle.svg';
import ArrowUpCircleIcon from 'images/icons/arrows/arrowUpGreenLightGreenBgCircle.svg';

function SalesInfos({ salesInfos, salesInfoType, setSalesInfoType, filtersChanged, getCmvChartInfos }) {
  const salesInfoContent = salesInfos?.content
  const salesTotalValue = !salesInfoType ? salesInfoContent?.sales?.total : salesInfoContent?.sales?.totalWithDiscount
  const currentProjection = salesInfoContent?.projection?.totalProjection || salesInfoContent?.projection?.totalForecastNoSafeMargin
  const projectionTotalTarget = salesInfoContent?.projection?.days?.reduce(
    (total, value) =>
      value
        ? (value?.projection || value?.forecastNoSafeMargin || 0) + parseFloat(total)
        : total,
    0
  )?.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const projectionTotalSales = salesInfoContent?.projection?.days?.reduce(
    (total, value) =>
      value
        ? (value?.sales || 0) + parseFloat(total)
        : total,
    0
  )?.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
  const isPositiveTarget = salesTotalValue >= currentProjection

  return (
    <div className="cmvSalesCard">
      <p className="cardTitle">VENDAS</p>

      {salesInfos?.loading ?
        <div className="w100 dFlex">
          <LinearProgress className="w100" id="loading" variant="query" />
        </div>
      : 
        <div className="dFlex alignCenter justifyCenter pRelative w100">
          <span className="bold">
            R$ {(salesTotalValue || 0)?.toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </span>

          <Tooltip
            title={
              <div className="dFlex flexColumn" style={{ padding: '4px 2px' }}>
                <p className="mb5 bold">
                  {isPositiveTarget ? 'Acima' : 'Abaixo'} da meta <br/>
                  Meta: R$ {projectionTotalTarget?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </p>

                <p className="mb0 mt10 bold">
                  DATA: META / VENDA
                </p>

                {salesInfoContent?.projection?.days?.length ?
                  <>
                    <div className="salesTargetAndDaysBox">
                      {salesInfoContent?.projection?.days?.map(el => {
                        const dayFormatted = el?.day < 10 ? `0${el?.day}` : el?.day
                        const monthFormatted = el?.month < 10 ? `0${el?.month}` : el?.month
                        const dateFormatted = `${dayFormatted}/${monthFormatted}`
                        const targetValue = (el?.projection || el?.forecastNoSafeMargin)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                        const salesValue = (el?.sales || 0)?.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })

                        return (
                          <p className="mb0">
                            {dateFormatted}: 

                            <span>
                              R$ {targetValue}
                            </span>
                            / 
                            <span>
                              R$ {salesValue}
                            </span>
                          </p>
                        )
                      })}
                    </div>

                    <p className="mb5 bold">
                      TOTAL: {`R$ ${projectionTotalTarget} / R$ ${projectionTotalSales}`}
                    </p>
                  </>
                : null }
              </div>
            }
            aria-label="add"
          >
            <img
              className="pAbsolute"
              src={isPositiveTarget ? ArrowUpCircleIcon : ArrowDownCircleIcon}
              alt={isPositiveTarget ? ArrowUpCircleIcon : ArrowDownCircleIcon}
              style={{ width: '20px', marginLeft: 150 }}
            />
          </Tooltip>

          {/* <FormControlLabel
            className="toggleDefault m0 dFlex customToggle"
            control={
              <Switch
                checked={salesInfoType}
                onChange={() => {
                  setSalesInfoType(!salesInfoType);
                  
                  getCmvChartInfos(filtersChanged, !salesInfoType)
                }}
                name="View Mode"
              />
            }
            label={salesInfoType ? 'Vendas com desconto' : 'Vendas sem desconto'}
          /> */}
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(SalesInfos);
