/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React from 'react';

import { Checkbox, IconButton, LinearProgress, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';

import EmptyCheckboxIconWhite from 'images/icons/empties/emptyCheckboxIconWhite.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import ValidationLength from 'utils/utils';

import { StyledTBody, TableHeader, Wrapper } from './styles';

function LoadingComponent() {
  return (
    <tr>
      <th colSpan="4" className="m10 textAlignCenter p0" style={{ margin: '10px', textAlign: 'center' }}>
        <LinearProgress
          style={{ height: '7px' }}
          id="loading"
          variant="query"
        />
      </th>
    </tr>
  );
}

function EmptyState() {
  return (
    <tr>
      <th colSpan="4" style={{ margin: '10px', textAlign: 'center' }}>
        Nenhum SKU encontrado
      </th>
    </tr>
  );
}

function CmvAndCountSetupTable({
  isLoading,
  mainList,
  setMainList,
  setModalSettings,
  modalSettings,
  setPendentItems,
  pendentItems,
  storageLocationOptions,
  getInternalProductRelated,
  getProductRelatedMenus,
  setUpdatedItems,
  updatedItems,
  setHasProductUpdates
}) {
  const userInfo = useSelector((currState) => currState.user);
  const productsList = mainList?.products
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const bigScreen = useMediaQuery('(min-width:1281px)');

  return (
    <Wrapper className="desktop" cellPadding="0" cellSpacing="0">
      <TableHeader>
        <tr>
          <th width="38%" className="subtitle">
            PRODUTO
          </th>

          <th width="32%" className="subtitle pl8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
            <div className="dFlex alignCenter">
              LOCAL DE ESTOQUE

              <Tooltip title="Cadastrar Local de estoque" aria-label="add">
                <IconButton
                  classes={{ root: 'modalButton' }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                  onClick={() => {
                    setModalSettings(
                      modalSettings.map((modal) =>
                        modal.name === 'storageLocationDialog' ? { ...modal, open: true } : modal
                      )
                    );
                  }}
                >
                  <img
                    style={{ width: '18px' }}
                    src={AddIcon}
                    alt="AddIcon"
                  />
                </IconButton>
              </Tooltip>

              <div
                className='dFlex alignCenter mr0 mlAuto'
              >
                <Checkbox
                  style={{
                    marginTop: 4,
                    color: '#1F2445'
                  }}
                  name="View Mode"
                  checked={pendentItems}
                  className="checkBoxSmall"
                  onChange={(_, checked) => {
                    setPendentItems(checked);
                    setMainList({
                      ...mainList,
                      products: productsList?.map(el => {
                        if (checked) {
                          return {
                            ...el,
                            hide: !el?.pending,
                          }
                        }

                        return {
                          ...el,
                          hide: false,
                        }
                      }),
                    })
                  }}
                  icon={
                    <img className="pendentImg" src={EmptyCheckboxIconWhite} alt="EmptyCheckboxIconWhite" />
                  }
                  checkedIcon={
                    <img
                      className="pendentImg" 
                      src={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                      alt={`${process.env.PUBLIC_URL}/SVGicons/checkboxIcon.svg`}
                    />
                  }
                  size="small"
                />

                <label
                  onClick={() => {
                    setPendentItems(!pendentItems)
                    setMainList({
                      ...mainList,
                      products: productsList?.map(el => {
                        if (!pendentItems) {
                          return {
                            ...el,
                            hide: !el?.pending,
                          }
                        }

                        return {
                          ...el,
                          hide: false,
                        }
                      }),
                    })
                  }}
                  className='initialTransform white fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer mr8'
                  style={{
                    lineHeight: 'normal',
                  }}
                >
                  Pendente
                </label>
              </div>
            </div>
          </th>

          <th width="15%" className="subtitle right pr8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
            FICHA DE CARDÁPIO RELAC.
          </th>

          <th width="15%" className="subtitle right pr8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
            PRODUTO INTERNO RELAC.
          </th>
        </tr>
      </TableHeader>

      <StyledTBody>
        {isLoading && <LoadingComponent />}

        {(productsList?.length === 0) && <EmptyState />}

        {productsList?.map((product) => (
          <>
            <tr className={`${product?.hide && 'dNone'}`}>
              <td className="description pl8 pr8">
                {ValidationLength(
                  product.description,
                  isAdminPage ? 50 : bigScreen ? 50 : 30
                )}
              </td>

              <td className="description p8 pb5 pt5" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
                <Autocomplete
                  multiple
                  limitTags={1}
                  size="small"
                  onChange={(_, value) => {
                    setHasProductUpdates(true)

                    const formattedBody = {
                      id: product?.id,
                      storageLocations: value?.map(el => el?.id),
                    };

                    setMainList({
                      ...mainList,
                      products: productsList?.map(el => {
                        if (el?.id === product?.id) {
                          return {
                            ...el,
                            storageLocations: value
                          }
                        }

                        return {
                          ...el
                        }
                      })
                    })

                    if (updatedItems?.find(el => el?.id === product?.id)) {
                      setUpdatedItems(updatedItems?.map(el => {
                        if (el?.id === product?.id) {
                          return {
                            ...el,
                            storageLocations: value?.map(item => item?.id)
                          }
                        }
  
                        return { ...el }
                      }))
                    } else {
                      setUpdatedItems([...updatedItems, formattedBody])
                    }
                  }}
                  value={product?.storageLocations?.filter(item => storageLocationOptions?.map(el => el?.id)?.includes(item?.id))}
                  getOptionSelected={(option, value) =>
                    option?.description === value?.description
                  }
                  getOptionLabel={(option) => option?.description}
                  getOptionDisabled={(option) =>
                    product?.storageLocations?.some(
                      (item) => item?.id === option?.id
                    )
                  }
                  options={storageLocationOptions || []}
                  className={`autocompleteSmall withoutLabel ${product?.pending && 'pendingItem'}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      placeholder="Selecione..."
                      required
                      InputLabelProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params?.description}
                    </Typography>
                  )}
                />
              </td>

              <td  className="description right p8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
                <span 
                  className="lightBlue cursorPointer"
                  onClick={() => {
                    if (!product?.nMenus) return

                    getProductRelatedMenus(product)
                  }}
                >
                  {product?.nMenus || '-'}
                </span>
              </td>

              <td className="description right p8" style={{ borderLeft: 'solid 1px #D0D3E2' }}>
                <span 
                  className="lightBlue cursorPointer"
                  onClick={() => {
                    if (!product?.nCards) return

                    getInternalProductRelated(product)
                  }}
                >
                  {product?.nCards || '-'}  
                </span>
              </td>
            </tr>
            
            <tr className={`${product?.hide && 'dNone'}`}>
              <td colSpan="4" className="m10 textAlignCenter p0"> 
                <hr />
              </td>
            </tr>
          </>
        ))}
      </StyledTBody>
    </Wrapper>
  );
}

export default CmvAndCountSetupTable;
