/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useCallback, useEffect } from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import InvoiceModal from 'components/Dialogs/InvoiceModal';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import ImageWrapper from 'components/ImageWrapper';
import PaperComponent from 'components/PaperComponent';
import Register from 'components/Register';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import {CardsFormContainer, StyledTabs, StyledTypography } from './index.style';

const useStyles = makeStyles((theme) => ({
  input: {
    fontFamily: 'Lato',
    fontSize: '12px'
  },

  option: {
    fontFamily: 'Lato',
    fontSize: '12px'
  }
}));

function ProductTable({
  setHasMenuModalChange,
  setEditProducts,
  createProducts,
  userInfo,
  productInfo,
  data,
  setData,
  setEditCards,
  unitsMeasurements,
  consumptionUnits,
  storageLocations,
  periodicities,
  parameters,
  brands,
  packageTypes,
  onlyView = false,
  menuLowAutomaticParams,
  menuInfo,
  processDefaultOptions,
  getMenuInfos,
  commonMessage,
  setCommonMessage,
}) {
  const [productFilter, setProductFilter] = useState('');
  const [newProductRow, setNewProductRow] = useState('');
  const [newProductValue, setNewProductValue] = useState('');
  const [getId, setGetId] = useState(userInfo.companiesActive.id);
  const [products, setProducts] = useState([]);
  const [cardapio, setCardapio] = useState([]);
  const [menuId, setMenuId] = useState();
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [percentageDiscountValue, setPercentageDiscountValue] = useState();
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [hasMenuModalChangeTable, setHasMenuModalChangeTable] = useState(false);
  const [confirmCloseMenuDialog, setConfirmCloseMenuDialog] = useState(false);
  const [filtersChanged, setFiltersChanged] = useState();
  const [menuLowAutomaticParamsTable, setMenuLowAutomaticParamsTable] = useState();
  const [parametersProductType, setParametersProductType] =
    useState(parameters);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [invoiceDates, setInvoiceDates] = useState({
    startDate: menuLowAutomaticParams?.startDate,
    endDate: menuLowAutomaticParams?.endDate
  })
  const segment = userInfo?.companiesActive?.segment;
  const { errors, register } = useForm();
  const classes = useStyles();
  const environments = Environment(process.env.REACT_APP_ENV);
  const productTableCurrentItems = data?.data?.submenus?.length ? data?.data?.submenus : data?.data?.cards?.items
  const quantityTotal = data?.data?.itemsMenu?.length
    ? data?.data?.itemsMenu?.reduce(
      (total, value) =>
        value
          ? parseFloat(Number(value.quantity)) + parseFloat(total)
          : total,
      0
    )
    : data?.data?.submenus?.length
      ? data?.data?.submenus?.reduce(
        (total, value) =>
          value
            ? parseFloat(Number(value.quantity)) + parseFloat(total)
            : total,
        0
      )
      : 0;
  const salesQuantityTotal = menuLowAutomaticParams?.salesInfos?.length ? menuLowAutomaticParams?.salesInfos[0] : null
  const salesDiffValue = salesQuantityTotal ? (salesQuantityTotal?.quantity || 0) - quantityTotal : 0

  const orderedAndFlatData = [
    data?.data?.cards?.items
      ?.filter((item) => item?.id)
      .sort((a, b) => (a.id > b.id ? 1 : -1)),
    data?.data?.cards?.items?.filter((item) => !item?.id)
  ].flat();
  const orderedAndFlatDeliveryData = data?.data?.cards?.deliveryItems ? [
    data?.data?.cards?.deliveryItems
      ?.filter((item) => item?.id)
      .sort((a, b) => (a.id > b.id ? 1 : -1)),
    data?.data?.cards?.deliveryItems?.filter((item) => !item?.id)
  ].flat() : [];
  const [updateList, setUpdateList] = useState(false);
  const [newProductProcessOptions, setNewProductProcessOptions] = useState([]);
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'productModal', open: false, fullScreen: false },
    { id: 2, name: 'cataloguingModal', open: false, fullScreen: false },
    { id: 3, name: 'modalTechnicalSheet', open: false, fullScreen: false }
  ]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [sku, setSku] = useState({
    ean: null,
    status: 'ANALYZE',
    description: '',
    specification: null,
    brandsId: null,
    restaurants: null,
    providers: selectedSku,
    product: null,
    categoryId: null,
    quantity: 1,
    unitsMeasurementsId: null,
    user: userInfo.user
  });

  const orderingList = (propName) => {
    const currentItems = propName === 'itemsMenu' || propName === 'submenus' ? data?.data : data?.data?.cards

    if (!currentItems?.[propName]) return [];
    const orderedAndFlatData = [
      currentItems?.[propName]
        ?.filter((item) => item?.id)
        .sort((a, b) => (a.id > b.id ? 1 : -1)),
      currentItems?.[propName]?.filter((item) => !item?.id)
    ].flat();

    return orderedAndFlatData;
  }

  const currentItems = () => {
    if (data?.data?.isSubMenu && !data?.data?.mainMenus?.length) {
      return 'submenus'
    }

    return menuLowAutomaticParams?.lowAutomatic ? 'itemsMenu' : 'items'
  }

  const isSubMenu = data?.data?.isSubMenu && !data?.data?.mainMenus?.length;
  const disabled = isSubMenu || data.data.lowAutomatic || onlyView;

  const [tabs, setTabs] = useState([{
    name: 'FICHA DE CARDÁPIO',
    active: true,
    propName: currentItems(),
    productListOrdered: orderingList(currentItems())
  },
  {
    name: 'ADICIONAL DELIVERY',
    active: false,
    propName: 'deliveryItems',
    productListOrdered: orderingList('deliveryItems')
  }]);

  const tabDeliveryActive = tabs.find((item) => item.propName === 'deliveryItems').active

  const getProducts = useCallback(() => {
    (async () => {
      const response = await fetch(
        `${environments.catalog}/menu/productProvider?page=1&size=100000&originId=${getId}&useOnCards=true`
      );
      const productsJson = await response.json();

      setProducts(productsJson.content);
    })();
  }, [getId]);

  const getActiveTab = () => tabs.filter(item => item.active)[0]

  const setProductProcessLoss = (product, or) => {
    let lossPercentageByProccess = 0

    if (product?.process?.percentage) {
      lossPercentageByProccess = product?.process?.percentage
    }

    if (product?.productProcessId && product?.products?.productProcesses) {
      const [process = undefined] = product?.products?.productProcesses.filter(pp => pp.id === product?.productProcessId)

      if (process && process?.percentage) {
        lossPercentageByProccess = process?.percentage || 0
      }
    }

    return lossPercentageByProccess
  }

  const LastProductCost = (lowAutomatic, product) => {
    if (lowAutomatic) {
      return product?.cost
    }

    const lossPercentageByProccess = setProductProcessLoss(product)

    return product?.quantity * parseFloat(((product?.products?.lastCost ? product?.products?.lastCost : product?.products?.lastCostStock) || 0) / (1 - lossPercentageByProccess / 100))
  }

  const AverageProductCost = (lowAutomatic, product) => {
    if (lowAutomatic) {
      return product?.cost
    }

    const lossPercentageByProccess = setProductProcessLoss(product)

    return product?.quantity * parseFloat(((product?.products?.averageCostStock ? product?.products?.averageCostStock : product?.products?.averageCost) || 0) / (1 - lossPercentageByProccess / 100))
  }

  const setActiveTab = (tabName) => {
    const newArray = [...tabs];
    newArray.map((item) => {
      if (tabName === item.name) {
        item.active = true;
      } else {
        item.active = false;
      }
    });
    setTabs([...newArray]);
  }

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
          ? card.unitsMeasurementsMenu.unitsMeasurements
          : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
          _id: card.frequencies.frequencyId,
          description: card.frequencies.description
        }
        : null;

      card.storageLocation = card.storageLocation
        ? {
          _id: card.storageLocation.storageLocationId,
          description: card.storageLocation.description
        }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCostLast:
                product.products &&
                  product.products.lastCostStock &&
                  !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : '0,000',
              costLast:
                product.products &&
                  product.products.lastCostStock &&
                  !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                    : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 9
                })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
            ? card.itemsTransformations.products.approximateCost
            : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product?.products
                ? product?.products?.description
                : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCostLast:
                product.products &&
                  product.products.lastCost &&
                  product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                    : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : '0,000',
              costLast:
                product.products &&
                  product.products.lastCost &&
                  product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                  : product.products.approximateCost
                    ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                    : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                  minimumFractionDigits: 9
                })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'modalTechnicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  const handleModal = (title, value) => {
    if (value) {
      const key = Object.keys(value)[0];
      const objValue = Object.values(value)[0];

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === title ? { ...modal, [key]: objValue } : modal
        )
      );
    } else {
      const { id } = modalSettings.find((modal) => modal.name === title);
      const { name } = modalSettings.find((modal) => modal.name === title);
      const { open } = modalSettings.find((modal) => modal.name === title);

      return { id, name, open };
    }

    if (title === 'productModal' && value.open === false) {
      setUpdateList(false);
    }

    if (title === 'cataloguingModal' && value.open === false) {
      setSelectedSku();
    }
  };

  const handleSelectedSku = (sku) => {
    setSku((item) => ({
      ...item,
      ean: sku.ean ? sku.ean : null,
      status: 'ANALYZE',
      description: sku.description ? sku.description : '',
      specification: '',
      quantity: sku.quantity ? sku.quantity : null,
      unitsMeasurementsId: sku.unitsMeasurements
        ? sku.unitsMeasurements.id
        : null,
      unitsMeasurements: sku.unitsMeasurements ? sku.unitsMeasurements : null
    }));

    setSelectedSku(sku);

    handleModal('cataloguingModal', { open: true });
  };

  const renderMenuLowAutomaticInfos = () => (
    <div className="menuLowAutomaticInfosBox">
      <div className="defaultInfoBox">
        <span>Diferença</span>

        <div className="chipBox" style={{ background: salesDiffValue !== 0 ? '#F7D4D7' : '#818182' }}>
          <span style={{ fontWeight: salesDiffValue !== 0 ? 'bold' : 'normal', color: salesDiffValue !== 0 ? '#F53D4C' : '#666770' }}>
            {salesDiffValue?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })} {data?.data?.unitsMeasurements?.abbreviation}
          </span>
        </div>
      </div>

      <div className="defaultInfoBox">
        <span>Produção</span>

        <div className="chipBox" style={{ background: '#FFF2CC' }}>
          <span style={{ color: '#AA8208' }}>
            {quantityTotal?.toLocaleString('pt-br', {
              minimumFractionDigits: 3
            })} {data?.data?.unitsMeasurements?.abbreviation}
          </span>
        </div>
      </div>

      <div className="defaultInfoBox">
        <span>Venda</span>

        <div className="chipBox" style={{ background: '#DADDF3' }}>
          <span className="dFlex" style={{ color: '#5062F0' }}>
            {data?.data?.pdv?.code ?
              <InvoiceModal
                restaurantId={getId}
                rangeDate={invoiceDates}
                setRangeDate={setInvoiceDates}
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                customParams={data?.data}
                customMainValue={(salesQuantityTotal?.quantity || 0)?.toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })}
              />
              : null} {' '}

            <span className="ml3" style={{ color: '#5062F0' }}>
              {data?.data?.unitsMeasurements?.abbreviation}
            </span>
          </span>
        </div>
      </div>
    </div>
  )

  useEffect(() => {
    setGetId(userInfo.companiesActive.id);
    getProducts();

    if (!parameters) {
      (async () => {
        const response = await fetch(
          `${environments.restaurants}/companies/${getId}/parameters`
        );
        const parametersJson = await response.json();
        const { product: { type } = '' } = parametersJson;
        setParametersProductType(type);
      })();
    }
  }, [
    userInfo,
    updateList,
    modalSettings.find((item) => item?.name === 'cataloguingModal')?.open,
    getId
  ]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    const newTabs = [{
      name: 'FICHA DE CARDÁPIO',
      active: getActiveTab().name === 'FICHA DE CARDÁPIO',
      propName: currentItems(),
      productListOrdered: orderingList(currentItems())
    },
    {
      name: 'ADICIONAL DELIVERY',
      active: getActiveTab().name === 'ADICIONAL DELIVERY',
      propName: 'deliveryItems',
      productListOrdered: orderingList('deliveryItems')
    }]
    setTabs([...newTabs]);
  }, [data])

  const handleModalMenuToggle = (confirmClose = false) => {
    if (openModalMenu && hasMenuModalChangeTable && !confirmClose) {
      setConfirmCloseMenuDialog(true);
    } else {
      setConfirmCloseMenuDialog(false);
      setHasMenuModalChangeTable(false);
      setOpenModalMenu(!openModalMenu);
    }
  };

  const BodyModal = (
    <Register
      userInfo={userInfo}
      menuInfo={menuInfo}
      handleModalToggle={handleModalMenuToggle}
      data={cardapio}
      menuId={menuId}
      salesAverageDiscount={salesAverageDiscount}
      percentageDiscountValue={percentageDiscountValue}
      openModalMenu={openModalMenu}
      setHasMenuModalChange={setHasMenuModalChangeTable}
      hasMenuModalChange={hasMenuModalChangeTable}
      setSalesAverageDiscount={setSalesAverageDiscount}
      showInactiveItems={
        filtersChanged?.find((el) => el.param === 'status')?.value
      }
      menuLowAutomaticParams={menuLowAutomaticParamsTable}
      setMenuLowAutomaticParams={setMenuLowAutomaticParamsTable}
      onlyView
    />
  );

  return (
    <CardsFormContainer>
      <>
        <StyledTabs>
          <ul className="tabsList">
            {tabs.map(tab => (
              <li className={`tab ${getActiveTab().name === tab.name ? 'is-active' : ''}`} onClick={() => { setActiveTab(tab.name) }}>
                <span>{tab.name}</span>
                <span>{tab.productListOrdered.length} {tab.productListOrdered.length > 1 ? 'PRODUTOS' : 'PRODUTO'}</span>
              </li>
            ))}
          </ul>


          {data.data.lowAutomatic ? renderMenuLowAutomaticInfos() : null}
        </StyledTabs>

        <div className="productTableModal materialTableDefault selfShadow">
          <Grid item xs={12} className="materialTableDefault p0">
            <table className="desktop">
              <thead>
                <tr className="headerTitle">
                  <th width="34%" className="subtitle fontSizeDefault pr0">
                    {segment === 'Restaurante/Rodizio' ?
                      `PRODUTO (${getActiveTab().productListOrdered?.length}) / SUBFICHA (${getActiveTab().productListOrdered?.length})`
                      : `PRODUTO (${getActiveTab().productListOrdered?.length})`
                    }
                  </th>

                  <th width="15%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    PROCESSO
                  </th>

                  <th width="9%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    ESTOQUE
                  </th>

                  <th width="8%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    MÉD. $
                  </th>

                  <th width="8%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    ÚLT. $
                  </th>

                  <th width="10%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    QTD.
                  </th>

                  <th width="2%" className="subtitle fontSizeDefault center pl0 pt0 pb0 pr0">
                    UN.
                  </th>

                  <th width="9%" className="subtitle fontSizeDefault right pl8 pt0 pb0 pr8">
                    CUSTO $
                  </th>

                  <th width="2%" className="subtitle fontSizeDefault pl0 pr8">
                    &nbsp;
                  </th>
                </tr>
              </thead>

              <tbody>
                {getActiveTab().productListOrdered?.length
                  ? getActiveTab().productListOrdered.map((product, index) => {
                    let currentProduct = menuLowAutomaticParams?.lowAutomatic ? product?.product : product?.products;
                    const currentUnitOptions = [currentProduct?.consumptionUnitsPrimary, currentProduct?.consumptionUnitsSecondary]
                    const productProcess = product?.products?.productProcesses?.find(el => el?.id === product?.productProcessId)

                    if (isSubMenu) {
                      currentProduct = product;
                    }

                    return (
                      <>
                        <tr className="lineBorder">
                          <td className="description left pl8 pr0">
                            <div className="dFlex alignCenter justifyBetween">
                              <Typography
                                onClick={() => {
                                  if (currentProduct?.type === 'INTERNAL') {
                                    getOptions(
                                      `${environments.catalog}/cards/${currentProduct?.originIdCard}`,
                                      'cards',
                                      selectedCard,
                                      setSelectedCard,
                                      getId,
                                      null,
                                      null,
                                      null,
                                      true
                                    );
                                  } else if (isSubMenu) {
                                    setMenuId(currentProduct?.id);
                                    setSalesAverageDiscount(
                                      currentProduct?.averageCost
                                    );
                                    setPercentageDiscountValue(
                                      currentProduct?.averageCostStock
                                    );
                                    handleModalMenuToggle();
                                    setMenuLowAutomaticParamsTable({
                                      startDate: filtersChanged?.find(el => el.param === 'dateStart')?.value,
                                      endDate: filtersChanged?.find(el => el.param === 'dateEnd')?.value,
                                    })
                                  } else {
                                    setSelectedProduct({
                                      ...currentProduct,
                                      id: currentProduct?.id
                                    })
                                    setOnlyViewProduct(false);
                                    handleModal('productModal', { open: true });
                                  }
                                }}
                                className="cursorPointer lightBlue fontSizeDefault p0"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                {`${index + 1}- `}
                                {ValidationLength(
                                  currentProduct?.description || '-',
                                  30
                                )}
                              </Typography>

                              <div className={`${product?.image?.url ? 'visibilityVisible' : 'visibilityHidden'}`}>
                                <ImageWrapper
                                  width="30px"
                                  height="28px"
                                  url={product?.image?.url}
                                />
                              </div>
                            </div>
                          </td>
                          
                          <td className="description left pl8 pr8">
                            <FormControl className="dFlex flexRow w100">
                              <Autocomplete
                                size="small"
                                style={{ width: "100%" }}
                                classes={{
                                  input: classes.input,
                                  option: classes.option
                                }}
                                value={productProcess || ''}
                                inputValue={productProcess ? `${productProcess?.processes?.description} (${productProcess?.percentage?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2
                                })}%)` : ''}
                                onChange={(_, value) => {
                                  const items = data?.data?.cards?.[getActiveTab().propName];
                                  setHasMenuModalChange &&
                                    setHasMenuModalChange(true);

                                  setEditCards(true);

                                  setData({
                                    ...data,
                                    data: {
                                      ...data.data,
                                      cards: {
                                        ...data?.data?.cards,
                                        [getActiveTab().propName]: items?.map((el) => {
                                          if (
                                            el?.products?.id ===
                                            currentProduct?.id
                                          ) {
                                            return {
                                              ...el,
                                              process: value,
                                              productProcessId: value?.id
                                            };
                                          }

                                          return { ...el };
                                        })
                                      }
                                    }
                                  });
                                }}
                                getOptionSelected={(option, value) =>
                                  option?.processes?.description === value?.description
                                }
                                getOptionDisabled={(option) => {
                                  product?.process?.id === option?.id
                                }}
                                getOptionLabel={(option) => option?.processes?.description || ''}
                                options={product?.products?.productProcesses?.map(el => ({
                                  ...el,
                                  processes: {
                                    ...el?.processes,
                                    description: `${el?.processes?.description} (${el?.percentage})`
                                  }
                                })) || []}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id={params.id}
                                    placeholder="Processo"
                                    name='Process'
                                    InputLabelProps={{
                                      style: {
                                        fontFamily: 'Lato',
                                        fontSize: '12px'
                                      }
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </td>

                          <td className="description right pl8 pr8">
                            {(currentProduct?.realStock || 0).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            )}
                          </td>

                          <td className="description right pl8 pr8">
                            {(
                              currentProduct?.averageCostStock ||
                              currentProduct?.averageCost ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>

                          {/* último custo (ÚLT. $) */}
                          <td className="description right pl8 pr8">
                            {(
                              currentProduct?.lastCostStock ||
                              currentProduct?.lastCost ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>

                          <td
                            className="description right pl8 pr8"
                            style={{ height: 34 }}
                          >
                            <FormControl required>
                              <CurrencyTextField
                                variant="standard"
                                size="small"
                                currencySymbol=""
                                minimumValue="0"
                                maximumValue="999999999999999"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={3}
                                decimalPlacesShownOnFocus={3}
                                type="text"
                                name="quantity"
                                fullWidth
                                placeholder="Qtd"
                                required
                                value={product?.quantity}
                                disabled={disabled}
                                InputProps={{
                                  maxLength: 8,
                                  classes: { input: classes.input }
                                }}
                                onBlur={(e, value) => {
                                  const items = data?.data?.cards?.[getActiveTab().propName];
                                  setHasMenuModalChange &&
                                    setHasMenuModalChange(true);

                                  if (product?.quantity === parseFloat(value))
                                    return;

                                  setEditCards(true);

                                  let lossPercentageByProccess = 0

                                  if (product?.process?.percentage) {
                                    lossPercentageByProccess = product?.process?.percentage || 0
                                  }

                                  setData({
                                    ...data,
                                    data: {
                                      ...data.data,
                                      cards: {
                                        ...data?.data?.cards,
                                        [getActiveTab().propName]: items?.map((el) => {
                                          if (
                                            el?.products?.id ===
                                            currentProduct?.id
                                          ) {
                                            return {
                                              ...el,
                                              quantity: parseFloat(value),
                                              cost:
                                                value &&
                                                  parametersProductType ===
                                                  'LAST_PURCHASE'
                                                  ? value *
                                                  parseFloat(
                                                    (currentProduct?.lastCost / (1 - lossPercentageByProccess / 100))
                                                  )
                                                  : value
                                                    ? value *
                                                    parseFloat(
                                                      (currentProduct?.averageCost / (1 - lossPercentageByProccess / 100))
                                                    )
                                                    : 0,
                                              products: {
                                                ...el?.products,
                                                cost:
                                                  value &&
                                                    parametersProductType ===
                                                    'LAST_PURCHASE'
                                                    ? value *
                                                    parseFloat(
                                                      (currentProduct?.lastCost / (1 - lossPercentageByProccess / 100))
                                                    )
                                                    : value
                                                      ? value *
                                                      parseFloat(
                                                        (currentProduct?.averageCost / (1 - lossPercentageByProccess / 100))
                                                      )
                                                      : 0
                                              }
                                            };
                                          }

                                          return { ...el };
                                        })
                                      }
                                    }
                                  });
                                }}
                              />
                            </FormControl>
                          </td>

                          <td className="description center pl0 pr0">
                            {currentProduct?.consumptionUnitsSecondary?.id ?
                              <Autocomplete
                                size="small"
                                id="asynchronous-demo"
                                disableClearable
                                onChange={(_event, value) => {
                                  if (!value) return null

                                  setHasMenuModalChange && setHasMenuModalChange(true);

                                  const items = data?.data?.cards?.[getActiveTab().propName];

                                  setData({
                                    ...data,
                                    data: {
                                      ...data.data,
                                      cards: {
                                        ...data?.data?.cards,
                                        [getActiveTab().propName]: items?.map((el) => {
                                          if (
                                            el?.products?.id ===
                                            currentProduct?.id
                                          ) {
                                            return {
                                              ...el,
                                              unitsMeasurementsId: value?.id,
                                              products: {
                                                ...el?.products,
                                                consumptionUnitsPrimary: value,
                                                consumptionUnitsSecondary: currentUnitOptions?.find(item => item?.id !== value?.id)
                                              }
                                            };
                                          }

                                          return { ...el };
                                        })
                                      }
                                    }
                                  });
                                }}
                                getOptionSelected={(optionsMenu, value) =>
                                  optionsMenu.id === value.id
                                }
                                getOptionLabel={(optionsMenu) => optionsMenu?.abbreviation}
                                options={unitsMeasurements?.filter(el => currentUnitOptions?.map(item => item?.id).includes(el?.id))}
                                value={unitsMeasurements?.find(el => el?.id === product?.unitsMeasurementsId)}
                                className="autocompleteSmall customAutocomplete"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    type="text"
                                    variant="outlined"
                                    required="Campo obrigatório"
                                    placeholder="Selecione..."
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                  />
                                )}
                                renderOption={(params) => (
                                  <Typography
                                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                  >
                                    {params.abbreviation}
                                  </Typography>
                                )}
                              />
                              :
                              unitsMeasurements?.find(el => el?.id === product?.unitsMeasurementsId)?.abbreviation || '-'
                            }
                          </td>

                          <td className="description right pl8 pr8">
                            {parametersProductType === 'LAST_PURCHASE'
                              ? (
                                LastProductCost(menuLowAutomaticParams?.lowAutomatic, product).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              )
                              : (
                                AverageProductCost(menuLowAutomaticParams?.lowAutomatic, product).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                })
                              )
                            }
                          </td>

                          <td className="description center pl0 pr8">
                            <IconButton
                              disabled={disabled}
                              aria-label="delete"
                              onClick={() => {
                                const items = data?.data?.cards?.[getActiveTab().propName];
                                setHasMenuModalChange(true);
                                setData({
                                  ...data,
                                  data: {
                                    ...data.data,
                                    cards: {
                                      ...data?.data?.cards,
                                      [getActiveTab().propName]: items?.filter(
                                        (el) =>
                                          el?.products?.id !== currentProduct?.id
                                      )
                                    }
                                  }
                                });
                              }}
                              style={{ padding: 0, outline: 0 }}
                            >
                              <DeleteOutline style={{ width: 20 }} />
                            </IconButton>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="8" className="m10 textAlignCenter p0">
                            <hr />
                          </td>
                        </tr>
                      </>
                    )
                  })
                  : null
                }

                <tr>
                  <td className="description left pl8 pr0">
                    <FormControl fullWidth error={Boolean(errors.units)}>
                      <Box display="flex" className="itemWithModal">
                        <Autocomplete
                          size="small"
                          onChange={(e, value) => {
                            setHasMenuModalChange && setHasMenuModalChange(true);
                            let newProductRowBody = {};

                            setNewProductProcessOptions(value?.item?.productProcesses)

                            if (
                              !newProductRow ||
                              value?.type === 'Provider' ||
                              !data.products
                            ) {
                              newProductRowBody = {
                                id: null,
                                description: null,
                                realStock: null,
                                consumptionUnitsPrimary: null,
                                consumptionUnitsSecondary: null,
                                unityOptions: [],
                                averageCost: null,
                                lastCost: null,
                                averageCostStock: null,
                                lastCostStock: null
                              };
                            }

                            if (value?.type === 'Provider') {
                              handleSelectedSku(value.item);
                            }

                            if (value) {
                              newProductRowBody = {
                                ...newProductRow,
                                unitsMeasurementsId: value?.item?.consumptionUnitsPrimary?.id,
                                products: {
                                  id: value?.item?.id,
                                  description: value.item.description,
                                  consumptionUnitsPrimary:
                                    value?.item?.consumptionUnitsPrimary || null,
                                  consumptionUnitsSecondary:
                                    value?.item?.consumptionUnitsSecondary || null,
                                  unityOptions: [value?.item?.consumptionUnitsPrimary, value?.item?.consumptionUnitsSecondary]?.filter(el => el),
                                  unitsMeasurementsId: value?.item?.consumptionUnitsPrimary?.id,
                                  averageCost: value?.item?.averageCost || 0,
                                  lastCost:
                                    value?.item?.lastCost ||
                                    value?.item?.approximateCost ||
                                    0,
                                  realStock: value?.item?.realStock || 0,
                                  lastCostStock: value?.item?.lastCostStock || 0,
                                  averageCostStock:
                                    value?.item?.averageCostStock || 0,
                                  type: value?.item?.type || null,
                                  originIdCard: value?.item?.originIdCard || null,
                                  productProcesses: value?.item?.productProcesses || []
                                }
                              };
                            }

                            setNewProductValue(
                              newProductRow?.quantity ? { description: '' } : value
                            );

                            if (value && newProductRow?.quantity) {
                              setData({
                                ...data,
                                data: {
                                  ...data.data,
                                  cards: {
                                    ...data.data.cards,
                                    [getActiveTab().propName]: [
                                      data?.data?.cards?.[getActiveTab().propName],
                                      newProductRowBody
                                    ].flat()
                                  }
                                }
                              });

                              setNewProductRow({
                                description: '',
                                quantity: '',
                                cost: 0,
                                products: {
                                  ...newProductRow?.products,
                                  consumptionUnitsPrimary: '',
                                  consumptionUnitsSecondary: '',
                                  unityOptions: [],
                                  unitsMeasurementsId: '',
                                  cost: 0,
                                  realStock: 0,
                                  averageCostStock: 0,
                                  averageCost: 0,
                                  lastCostStock: 0,
                                  lastCost: 0
                                }
                              });
                            } else {
                              setNewProductRow({
                                description: e.target.innerText,
                                quantity: newProductRow?.quantity,
                                cost: newProductRow?.cost,
                                products: newProductRowBody?.products,
                                unitsMeasurementsId: newProductRowBody?.unitsMeasurementsId
                              });
                            }
                          }}
                          fullWidth
                          value={newProductValue}
                          disabled={disabled}
                          inputValue={
                            newProductValue || newProductValue?.description
                          }
                          getOptionLabel={(option) =>
                            option.item ? option.item.description : ''
                          }
                          getOptionDisabled={(option) =>
                            getActiveTab().productListOrdered?.some(
                              (item) => item?.products?.id === option?.item?.id
                            )
                          }
                          options={products}
                          classes={{
                            input: classes.input,
                            option: classes.option
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id={params.id}
                              name="produtos"
                              placeholder="Produto"
                              inputRef={register}
                              error={errors.units}
                              InputLabelProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                            />
                          )}
                          renderOption={(option) => (
                            <StyledTypography
                              isProvider={option.type && option.type === 'Provider'}
                              className={
                                option?.type === 'Provider' ? '' : 'validProduct'
                              }
                            >
                              {option.item ? option.item.description : ''}
                            </StyledTypography>
                          )}
                        />

                        <Tooltip
                          placement="bottom"
                          title="Cadastrar Produto sem SKU"
                        >
                          <IconButton
                            className="modalButton"
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              setOnlyViewProduct(false);
                              createProducts();
                              handleModal('productModal', { open: true });
                            }}
                            disabled={disabled}
                          >
                            <img
                              style={{ width: '25px' }}
                              src={AddIcon}
                              alt="AddIcon"
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </FormControl>
                  </td>

                  <td className="description left pl8 pr8">
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        size="small"
                        classes={{
                          input: classes.input,
                          option: classes.option
                        }}
                        value={newProductRow?.process || ''}
                        onChange={(_, value) => {
                          setHasMenuModalChange && setHasMenuModalChange(true);

                          const newProductRowBody = {
                            ...newProductRow,
                            process: value,
                            productProcessId: value?.id
                          };

                          setNewProductRow(newProductRowBody);
                        }}
                        getOptionSelected={(option, value) =>
                          option?.processes?.description === value?.description
                        }
                        getOptionDisabled={(option) => {
                          newProductRow?.process?.id === option?.id
                        }}
                        getOptionLabel={(option) => option?.processes?.description || ''}
                        options={newProductProcessOptions || []}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            placeholder="Processo"
                            name='Process'
                            InputLabelProps={{
                              style: {
                                fontFamily: 'Lato',
                                fontSize: '12px'
                              }
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </td>

                  <td className="description right pl8 p8">
                    {(newProductRow?.products?.realStock || 0).toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )}
                  </td>

                  <td className="description right pl8 pr8">
                    {(
                      newProductRow?.products?.averageCostStock ||
                      newProductRow?.products?.averageCost ||
                      0
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </td>

                  {/* último custo (ÚLT.$) linha em espera */}
                  <td className="description right pl8 pr8">
                    {(
                      newProductRow?.products?.lastCostStock ||
                      newProductRow?.products?.lastCost ||
                      0
                    ).toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </td>

                  <td className="description right pl8 pr8">
                    <FormControl required>
                      <CurrencyTextField
                        id="menuQuantityInput"
                        variant="standard"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        maximumValue="999999999999999"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="quantity"
                        fullWidth
                        placeholder="Qtd"
                        value={newProductRow?.quantity}
                        disabled={disabled}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input }
                        }}
                        onBlur={(e, value) => {
                          setHasMenuModalChange && setHasMenuModalChange(true);

                          let lossPercentageByProccess = 0

                          if (newProductRow?.process?.percentage) {
                            lossPercentageByProccess = newProductRow?.process?.percentage || 0
                          }

                          const newProductRowBody = {
                            ...newProductRow,
                            quantity: parseFloat(value),
                            cost: parseFloat(newProductRow?.products?.cost || 0),
                            products: {
                              ...newProductRow?.products,
                              cost:
                                value && parametersProductType === 'LAST_PURCHASE'
                                  ? value *
                                  parseFloat((newProductRow?.products?.lastCost / (1 - lossPercentageByProccess / 100)))
                                  : value
                                    ? value *
                                    parseFloat((newProductRow?.products?.averageCost / (1 - lossPercentageByProccess / 100)))
                                    : 0
                            }
                          };

                          setNewProductValue(
                            newProductValue ? '' : newProductValue
                          );

                          if (value && newProductValue) {
                            setData({
                              ...data,
                              data: {
                                ...data.data,
                                cards: {
                                  ...data.data.cards,
                                  [getActiveTab().propName]: [
                                    data?.data?.cards?.[getActiveTab().propName],
                                    newProductRowBody
                                  ].flat()
                                }
                              }
                            });

                            setNewProductRow({
                              description: '',
                              quantity: NaN,
                              cost: 0,
                              products: {
                                ...newProductRow?.products,
                                consumptionUnitsPrimary: '',
                                consumptionUnitsSecondary: '',
                                unityOptions: [],
                                unitsMeasurementsId: '',
                                cost: 0,
                                realStock: 0,
                                averageCostStock: 0,
                                averageCost: 0,
                                lastCostStock: 0,
                                lastCost: 0
                              }
                            });
                          } else {
                            setNewProductRow(newProductRowBody);
                          }
                        }}
                      />
                    </FormControl>
                  </td>

                  <td className="description center pl0 pr0">
                    {newProductRow?.products?.consumptionUnitsPrimary
                      ?.abbreviation || '-'}
                  </td>

                  {/* custo total (CUSTO $) linha em espera */}
                  <td className="description right pl8 pr8">
                    {parametersProductType === 'LAST_PURCHASE'
                      ? (
                        newProductRow?.quantity *
                        (newProductRow?.products?.lastCostStock
                          ? newProductRow?.products?.lastCostStock
                          : newProductRow?.products?.lastCost) || 0
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })
                      : (
                        newProductRow?.quantity *
                        (newProductRow?.products?.averageCostStock
                          ? newProductRow?.products?.averageCostStock
                          : newProductRow?.products?.averageCost) || 0
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}
                  </td>

                  <td className="description pr8">&nbsp;</td>
                </tr>

                {!getActiveTab().productListOrdered?.length ? (
                  <tr>
                    <th colSpan="8" className="m10 textAlignCenter">
                      Não há dados a serem exibidos.
                    </th>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </Grid>

          <Grid
            container
            item
            xs={12}
            spacing={5}
            justify="flex-end"
            className="bgWhite"
          >
            <Grid item xs={3} className="pt5 pb5" style={{ paddingRight: 10 }}>
              <Typography
                className="bold textAlignRight"
                style={{
                  fontSize: '13px'
                }}
              >
                Custo Total {data?.data?.itemsMenu?.length && menuLowAutomaticParams?.lowAutomatic || data?.data?.submenus?.length
                  ? `(${quantityTotal.toLocaleString('pt-br', { minimumFractionDigits: 3 })} ${data?.data?.unitsMeasurements?.abbreviation})`
                  : ''}:
              </Typography>
            </Grid>

            <Grid item xs={1} className="pt5 pb5" style={{ padding: 0, marginRight: '3.8%', flexBasis: '9%', maxWidth: '9%' }}>
              {data?.data?.submenus?.length ? (
                <Typography
                  className="bold textAlignRight"
                  style={{
                    fontSize: '13px',
                    color: '#5062F0',
                  }}
                >
                  {menuLowAutomaticParams?.lowAutomatic
                    ? `R$ ${data?.data?.cards?.costs?.costTotal?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}`
                    : parametersProductType === 'LAST_PURCHASE'
                      ? `R$ ${tabDeliveryActive && data.data.cards.deliveryItems.length ? (data.data.cards.items
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ) + data.data.cards.deliveryItems
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        )).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                        : 
                        productTableCurrentItems?.reduce(
                        (total, value) =>
                          value
                            ? parseFloat(Number(value.quantity)) *
                            parseFloat(
                              Number(
                                value?.products?.lastCostStock
                                  ? value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                    : 0
                                  : value?.products?.lastCost
                                    ? value?.products?.lastCost
                                    : 0
                              )
                              /
                              (1 - setProductProcessLoss(value) / 100)
                            ) +
                            parseFloat(total)
                            : total,
                        0
                      )
                        .toLocaleString('pt-br', { minimumFractionDigits: 3 })}`
                      : `R$ ${productTableCurrentItems?.reduce(
                        (total, value) =>
                          value
                            ? parseFloat(Number(value.quantity)) *
                            parseFloat(
                              Number(
                                value?.products?.averageCostStock
                                  ? value?.products?.averageCostStock
                                    ? value?.products?.averageCostStock
                                    : 0
                                  : value?.products?.averageCost
                                    ? value?.products?.averageCost
                                    : 0
                              )
                              /
                              (1 - setProductProcessLoss(value) / 100)
                            ) +
                            parseFloat(total)
                            : total,
                        0
                      )
                        .toLocaleString('pt-br', { minimumFractionDigits: 3 })}`}
                </Typography>
              ) : (
                <Typography
                  className="bold textAlignRight"
                  style={{
                    fontSize: '13px',
                    color: '#5062F0',
                  }}
                >
                  {menuLowAutomaticParams?.lowAutomatic
                    ? `R$ ${data?.data?.cards?.costs?.costTotal?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}`
                    : parametersProductType === 'LAST_PURCHASE'
                      ? `R$ ${tabDeliveryActive && data.data.cards.deliveryItems.length ? 
                        (data.data.cards.items
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ) + data.data.cards.deliveryItems
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ))
                        .toLocaleString('pt-br', { 
                          minimumFractionDigits: 3 
                        }) 
                        :
                        data.data.cards.items
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ).toLocaleString('pt-br', { 
                          minimumFractionDigits: 3 
                        }) }`
                      : `R$ ${tabDeliveryActive && data.data.cards.deliveryItems.length ? 
                        (data.data.cards.items
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ) + data.data.cards.deliveryItems
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.lastCostStock
                                    ? value?.products?.lastCostStock
                                      ? value?.products?.lastCostStock
                                      : 0
                                    : value?.products?.lastCost
                                      ? value?.products?.lastCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        ))
                        .toLocaleString('pt-br', { 
                          minimumFractionDigits: 3 
                        })  : 
                        data.data.cards.items
                        .reduce(
                          (total, value) =>
                            value
                              ? parseFloat(Number(value.quantity)) *
                              parseFloat(
                                Number(
                                  value?.products?.averageCostStock
                                    ? value?.products?.averageCostStock
                                      ? value?.products?.averageCostStock
                                      : 0
                                    : value?.products?.averageCost
                                      ? value?.products?.averageCost
                                      : 0
                                )
                                /
                                (1 - setProductProcessLoss(value) / 100)
                              ) +
                              parseFloat(total)
                              : total,
                          0
                        )
                        .toLocaleString('pt-br', { minimumFractionDigits: 3 })}`}
                </Typography>
              )}
            </Grid>
          </Grid>

          {menuLowAutomaticParams?.lowAutomatic ?
            <Grid
              container
              item
              xs={12}
              spacing={5}
              justify="flex-end"
              className="bgWhite"
            >
              <Grid item xs={3} className="pt0 pb12" style={{ paddingRight: 10 }}>
                <Typography
                  className="bold textAlignRight"
                  style={{
                    fontSize: '13px'
                  }}
                >
                  Custo Unitário ({data?.data?.itemsMenu?.length ? data?.data?.unitsMeasurements?.abbreviation : 'un'}):
                </Typography>
              </Grid>

              <Grid item xs={1} className="pt0 pb0" style={{ padding: 0, marginRight: '4.1%', flexBasis: '10.2%', maxWidth: '10.2%' }}>
                <Typography
                  className="bold textAlignRight"
                  style={{
                    fontSize: '13px',
                    color: '#5062F0',
                  }}
                >
                  R$ {data?.data?.cards?.costs?.costUnit?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                </Typography>
              </Grid>
            </Grid>
            : null}

          {handleModal('cataloguingModal').open && (
            <SkuModal
              fullScreen
              open={handleModal('cataloguingModal').open}
              data={selectedSku}
              getId={getId}
              editMode={false}
              sku={sku}
              consumptionUnits={consumptionUnits}
              brands={brands}
              packageTypes={packageTypes}
              storageLocations={storageLocations}
              periodicities={periodicities}
              menuModal
              userInfo={userInfo}
              unitsMeasurements={unitsMeasurements}
              onClose={() => {
                handleModal('cataloguingModal', { open: false });
                setUpdateList(!updateList);
              }}
            />
          )}

          {handleModal('productModal').open &&
            (onlyViewProduct ? selectedProduct : true) && (
              <RegisterProductModal
                dialogOpen={handleModal('productModal').open}
                isModal
                onlyView={onlyViewProduct}
                consumptionUnitOptions={consumptionUnits}
                productDataModal={{
                  data: selectedProduct
                }}
                periodicityOptions={periodicities}
                storageLocationOptions={storageLocations}
                onClose={() => {
                  createProducts();
                  setSelectedProduct()
                  getMenuInfos()
                  handleModal('productModal', { open: false });
                }}
                onCloseFunc={() => {
                  createProducts();
                  setSelectedProduct()
                  getMenuInfos()
                  handleModal('productModal', { open: false });
                }}
                message={commonMessage}
                setMessage={setCommonMessage}
                setUpdateList={setUpdateList}
                onlyMainRequest
              />
            )}

          {selectedCard && (
            <TechnicalSheetDialog
              maxWidth={null}
              open={
                modalSettings.find((modal) => modal.name === 'modalTechnicalSheet')
                  .open
              }
              handleClose={() =>
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'modalTechnicalSheet'
                      ? { ...modal, open: false }
                      : modal
                  )
                )
              }
              cardDataTable={cardDataTable}
              cardTransformation={cardTransformation}
              selectedCard={selectedCard}
              ucPrimarySelectedId={ucPrimarySelectedId}
            />
          )}

          {openModalMenu && (
            <Dialog
              open={openModalMenu}
              onClose={() => handleModalMenuToggle(false)}
              className="defaultModal menuModal"
              maxWidth="md"
              fullWidth
              PaperComponent={PaperComponent}
            >
              <DialogTitle
                style={{ cursor: 'move' }}
                id="draggable-dialog-title"
                className="modalHeader"
              >
                <Typography
                  className="bold textAlignCenter"
                  style={{
                    fontSize: 16
                  }}
                >
                  CADASTRO DE CARDÁPIO
                </Typography>

                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => handleModalMenuToggle(false)}
                  aria-label="close"
                  style={{ outline: '0', background: 'none' }}
                >
                  <img src={CloseIcon} alt="CloseIcon" />
                </IconButton>
              </DialogTitle>

              <DialogContent className="p16">{BodyModal}</DialogContent>
            </Dialog>
          )}

          {confirmCloseMenuDialog && (
            <Dialog
              open={confirmCloseMenuDialog}
              onClose={() => handleModalMenuToggle(true)}
              aria-labelledby="responsive-dialog-title"
              transitionDuration={0}
            >
              <DialogTitle
                className="modalHeader navyBlue bold cursorMove"
                id="draggable-dialog-title"
              />

              <DialogContent className="modalContent">
                <Typography>
                  Deseja realmente cancelar a edição desse item?
                </Typography>
              </DialogContent>

              <DialogActions className="justifyCenter">
                <Button
                  className="defaultButton backButton"
                  design="outlined"
                  onClick={() => setConfirmCloseMenuDialog(false)}
                  label="Cancelar"
                  color="primary"
                />
                <Button
                  className="defaultButton submitButton"
                  design="contained"
                  label="Confirmar"
                  onClick={() => handleModalMenuToggle(true)}
                  color="primary"
                  autoFocus
                />
              </DialogActions>
            </Dialog>
          )}
        </div>
      </>
    </CardsFormContainer>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable);