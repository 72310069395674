/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
import { memo, useEffect, useState } from 'react';

import { FormControl, IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { format } from 'date-fns';

import ImageWrapper from 'components/ImageWrapper';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import configPackageCount from 'images/icons/objects/boxesAndPackages/configPackageCount.svg';
import infoRedIcon from 'images/icons/signals/alerts/infoRedIcon.svg';
import StocksMoreInfoIcon from 'images/icons/signals/moreInfos/threeDotsLightBlueWhiteBgRectangleRounded.svg';
import StocksMoreInfoIconSelected from 'images/icons/signals/moreInfos/threeDotsWhiteLightBlueBgRectangleRounded.svg';
import ValidationLength from 'utils/utils';

import { handleFocus } from '../count/MainFunctions';
import { TdCount, TdEmb } from '../count/styles';
import ValuesList from '../ValuesList';

function setColor(percentage, lossPercentage, isFirst, diff, consumed) {
  const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
  let color;

  if (isFirst)
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#D2F9D6'
        : percentage >= lossPercentage
          ? '#FFF2CC'
          : '#F7D4D7';
  else
    color =
      percentage >= range[0] && percentage <= range[1]
        ? '#006608'
        : percentage >= lossPercentage
          ? '#AA8208'
          : '#F53D4C';

  if (!consumed) {
    if (isFirst)
      color = diff == 0 ? '#D2F9D6' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
    else color = diff == 0 ? '#006608' : diff > 0 ? '#AA8208' : '#F53D4C';
  }

  return color;
}

function ProductListItem({
  disabledConfirmCount,
  countId,
  getId,
  setModalStates,
  modalStates,
  setModalConfirmCountObj,
  setParameters,
  setProductModal,
  setProductDescription,
  providers,
  product,
  confirmModalAction,
  showProductDescription,
  skuIndex,
  parametersInfos,
  hasCountedOthersStocks,
  setCurrentCountDivergentDialogData,
  isSelectedRealDiff,
  skus,
  sku,
  productIndex,
  orderedSkus,
  renderProductAdditional,
  KeyCheck,
  handleBlur,
  isLoadingAssortment,
  id,
  internalProduct,
  countDate,
  setNewData,
  lastProductCounted,
  userInfo,
  setLastProductCounted,
  countGroupInfos,
  showColumns,
  setUpdateValueByModal,
  updateValueByModal,
  setCurrentInput,
  openConfigPackageCount,
  alreadyListed,
  setModalSettings,
  modalSettings,
  consumedInOutInfos,
  setConsumedInOutInfos,
  loadingInOutInfos,
  setLoadingInOutInfos,
  handleGetExtract,
  openConsumedInfosTooltip,
  setOpenConsumedInfosTooltip,
  setStockDetailsAnchor,
  popoverOpenStockDetails,
  setSelectedProduct,
  selectedProduct
}) {
  const isMobile = useMediaQuery('(max-width: 821px)');
  const [showDiffStyles, setShowDiffStyles] = useState(false);

  const lossPercentage = product?.lossPercentage
    ? parseFloat(product?.lossPercentage)
    : 0;
  const difference =
    product?.realStock !== null ||
      product?.realStock !== undefined ||
      product?.otherStock !== null ||
      product?.otherStock !== undefined
        ? (product?.otherStock 
          ? parseFloat(Number(product?.otherStock)) : 0) 
          + (product?.realStock ? parseFloat(Number(product?.realStock)) : 0) 
          - (product?.theoricalStock ? parseFloat(Number(product?.theoricalStock)) : 0)
        : null;
  const percentage = product?.consumed
    ? (difference / parseFloat(Number(product?.consumed))) * 100
    : 0;

  const reportTabObj = countGroupInfos?.countGroup?.counts?.find(
    (el) => el?.type === 'report'
  );

  useEffect(() => {
    if (product?.skus) {
      for (const sku of product?.skus) {
        for (const provider of sku.providers) {
          if (provider.stock != null && !isNaN(provider.stock)) {
            setShowDiffStyles(true);
          } else {
            setShowDiffStyles(false);
          }
        }
      }
    }
  }, [product])

  return (
    providers?.map((provider, providerIndex) => {
      const packageFormatted = `E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}` 

      return (
        <tr className={`firstLine ${provider?.ignoreBrands && 'dNone'}`}>
          <td
            // colSpan={product.type === 'INTERNAL' ? 2 : 1}
            colSpan={1}
            width="20"
            className="description"
            style={{
              background:
                showProductDescription[skuIndex] && providerIndex === 0
                  ? '#F6F6F8'
                  : 'transparent',
              paddingTop: 3,
              paddingLeft: 10
            }}
          >
            <div
              className={`second-line dFlex ${showProductDescription[skuIndex] && providerIndex === 0
                ? 'justifyBetween'
                : 'justifyEnd'
                }`}
            >
              {showProductDescription[skuIndex] && providerIndex === 0 ? (
                <>
                  {ValidationLength(
                    product.description,
                    isMobile
                      ? 48
                      : product.type === 'INTERNAL' ? 48 : 30
                  )}
                </>
              ) : (
                ''
              )}

              <div className="dFlex">
                {product.type !== 'INTERNAL' && (
                  <div className="dFlex justifyBetween alignCenter ml10 mr5">
                    {product.hasDiffTheoreticalRealStock && skuIndex === 0 && (
                      <Tooltip
                        title={
                          <div>
                            <p>DIVERGÊNCIA</p>
                            <p>Clique para resolver</p>
                          </div>
                        }
                      >
                        <img
                          src={infoRedIcon}
                          alt="Info Icon"
                          style={{
                            marginRight: 5,
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setCurrentCountDivergentDialogData({
                              title: product.description,
                              productId: product.id,
                              countDate
                            });
                            setModalStates((prevState) => ({
                              ...prevState,
                              countDivergentDialog: true
                            }));
                          }}
                        />
                      </Tooltip>
                    )}

                    {!isMobile && showProductDescription[skuIndex] &&
                      providerIndex === 0 ? (
                      <img
                        src={configPackageCount}
                        alt="configPackageCount"
                        onClick={() => openConfigPackageCount(product)}
                        style={{
                          width: isMobile ? '20px' : '18px',
                          cursor: 'pointer'
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}

                {!isMobile && skuIndex === 0 && providerIndex === 0 && (
                  <Tooltip title="Lançar descarte">
                    <IconButton
                      className="mr5"
                      size="small"
                      onClick={() => {
                        setModalStates((prevState) => ({
                          ...prevState,
                          modalDiscard: true
                        }));
                        setNewData((prevState) => ({
                          ...prevState,
                          data: {
                            description: product?.description,
                            originId: getId,
                            unitsMeasurementsId:
                              product?.consumptionUnitsPrimary?.id,
                            unitsMeasurementsAbbreviation:
                              product?.consumptionUnitsPrimary?.abbreviation,
                            unitsMeasurementsSecondaryId:
                              product?.consumptionUnitsSecondary?.id,
                            unitsMeasurementsSecondaryAbbreviation:
                              product?.consumptionUnitsSecondary?.abbreviation
                          },
                          discard: [
                            {
                              id: product?.id,
                              typeEvent: 'DISCARD',
                              cost: product?.cost,
                              originId: getId,
                              quantity: product?.quantity,
                              eventDate: format(
                                new Date(countDate),
                                'yyyy-MM-dd'
                              ),
                              un: product?.consumptionUnitsPrimary?.abbreviation,
                              unSecondary: product?.consumptionUnitsSecondary?.abbreviation,
                              user: userInfo.user
                            }
                          ]
                        }));
                      }}
                    >
                      <Delete
                        style={{
                          width: isMobile ? '20px' : '15px',
                          height: isMobile ? '20px' : '15px',
                          color: '#313347'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>

            <div className='h100' />
          </td>

          {!provider.isAdditional ? (
            <>
              <td
                className="description p8"
                style={{
                  verticalAlign: 'middle',
                  borderLeft: 'solid 1px #E6E7F0',
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null
                }}
              >
                <div className={`dFlex ${isMobile ? 'mobileSkuDescription mt2' : 'alignCenter'}`}>
                  {product?.ignoreBrands ? 
                    '' 
                  : 
                    <>
                      <div className={`${isMobile ? 'mt5' : 'mt0'}`}>
                        <ImageWrapper
                          width={isMobile ? "38px" : "28px"}
                          height={isMobile ? "38px" : "28px"}
                          url={sku?.images?.url}
                          borderType={product?.type}
                        />
                      </div>

                      <Tooltip
                        title={
                          <p className="mb0" style={{ padding: '2px 6px' }}>
                            {sku.description}
                          </p>
                        }
                        placement="right"
                      >
                        <div className={sku?.images?.url ? 'ml5' : 'ml8'}>
                          {sku.infoToShow
                            ? ValidationLength(sku.infoToShow, isMobile ? 20 : 18)
                            : null
                          }
                        </div>
                      </Tooltip>
                    </>
                  }
                </div>
              </td>

              <TdEmb
                width="3"
                className="description pl8"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                  borderLeft: 'solid 1px #E6E7F0',
                  padding: '0 10px'
                }}
              >
                {packageFormatted}
              </TdEmb>
              
              {isMobile &&
                <>
                  <td 
                    className="description p8 mobileQuantityField"
                    style={{
                      borderTop:
                        !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                      background: 'rgba(117, 131, 240, .5)'
                    }}
                  >
                    <FormControl fullWidth>
                      <CurrencyTextFieldSumeValues
                        setCurrentInput={setCurrentInput}
                        updatedValue={updateValueByModal}
                        setUpdateValueByModal={setUpdateValueByModal}
                        inputMode="numeric"
                        confirmModalAction={confirmModalAction}
                        sourcePage="countPage"
                        id={`countInput-${productIndex}-${skuIndex}-${providerIndex}-tablet`}
                        isLast={
                          skuIndex >= orderedSkus.length - 1 &&
                          providerIndex >= providers.length - 1 &&
                          !renderProductAdditional
                        }
                        key={id}
                        name="contagem"
                        disabled={isLoadingAssortment || disabledConfirmCount}
                        placeholder="Qtde..."
                        onKeyDown={KeyCheck}
                        parametersInfos={parametersInfos}
                        product={product}
                        products={internalProduct}
                        modalStates={modalStates}
                        setModalConfirmCountObj={setModalConfirmCountObj}
                        setModalStates={setModalStates}
                        countObj={{
                          product,
                          inputType: 'count',
                          sku,
                          quantity: product.additional,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: false,
                          provider,
                          locationProps: {
                            skus: skus.length,
                            skuIndex
                          }
                        }}
                        handleBlurCountObj={
                          {
                            product,
                            inputType: 'count',
                            sku,
                            quantity: product.additional,
                            additional: false,
                            skuQuantity: sku.unityAdditional,
                            skuAdditional: false,
                            provider,
                            locationProps: {
                              skus: skus.length,
                              skuIndex
                            }
                          }
                        }
                        onBlur={(
                          stringSummableValues,
                          replacedValue,
                          e,
                          isLast
                        ) => {
                          const obj = {
                            product,
                            inputType: 'count',
                            sku,
                            quantity: product.additional,
                            additional: false,
                            skuQuantity: sku.unityAdditional,
                            skuAdditional: false,
                            provider,
                            locationProps: {
                              skus: skus.length,
                              skuIndex
                            }
                          };

                          setLastProductCounted({
                            productId: product.id,
                            productDescription: product.description,
                            realStock: product.realStock,
                            theoreticalStock: product.theoricalStock,
                            historical: product.historical
                          });
                          handleBlur(
                            stringSummableValues,
                            replacedValue,
                            obj,
                            e,
                            isLast
                          );
                        }}
                        onFocus={() =>
                          handleFocus(
                            product,
                            lastProductCounted,
                            parametersInfos,
                            countId,
                            getId,
                            userInfo
                          )
                        }
                        onFocusProps={{
                          product
                        }}
                        defaultValue={
                          provider?.labelQuantity
                            ? provider?.labelQuantity
                            : provider?.stock
                        }
                      />
                    </FormControl>
                  </td>

                  <td 
                    className="description p8 right"
                    onClick={(event) => {
                      setStockDetailsAnchor(event.currentTarget)
                      setSelectedProduct(product)
                    }}
                    style={{
                      boxShadow: showDiffStyles && !skuIndex ? '0px 4px 4px 0px #00000040' : null,
                      borderTop:
                        !skuIndex && !providerIndex ? '1px solid #ccc' : null,
                      background: showDiffStyles && !skuIndex && !providerIndex
                        ? product?.realStock == null && product?.otherStock == null
                          ? 'transparent'
                          : setColor(
                              percentage,
                              lossPercentage,
                              true,
                              difference,
                              product?.consumed
                            )
                        : 'transparent',
                      color: showDiffStyles && !skuIndex && !providerIndex
                        ? setColor(
                            percentage,
                            lossPercentage,
                            false,
                            difference,
                            product?.consumed
                          )
                        : '#000'
                    }}
                  >
                    {!skuIndex && !providerIndex && (
                      <div className="dFlex justifyBetween alignEnd flexColumn othersContent">
                        <div className="dFlex alignCenter">
                          <div className="othersStocksVolume mr0">
                            <span className={`mr5 ${product?.stocks?.countedStocks === product?.stocks?.totalStocks && product?.stocks?.countedStocks > 0 ? 'bold' : ''}`}>
                              {product?.stocks?.totalStocks > 0
                                ? `${product.stocks.countedStocks}/${product.stocks.totalStocks}`
                                : product?.stocks?.countedStocks
                              }
                            </span>

                            <div 
                              id={product?.id}
                              className="iconInfo alignCenter"
                            >
                              <img 
                                src={popoverOpenStockDetails && selectedProduct?.id === product?.id 
                                  ? StocksMoreInfoIconSelected 
                                  : StocksMoreInfoIcon
                                } 
                                alt="StockDetailsIcon" 
                                style={{ width: 35, height: 18 }} 
                              />
                            </div>
                          </div>
                        </div>

                        <p className='content-report-tablet' style={{ marginBottom: 0 }}>
                          {typeof parseFloat(product.realStock) === 'number'
                            ? (product.realStock + product.otherStock).toLocaleString('pt-br', {
                              maximumFractionDigits: 3,
                              minimumFractionDigits: 3
                            })
                            : '0,000'
                          }{' '} {product?.consumptionUnitsPrimary?.abbreviation}
                        </p>
                      </div>
                    )}
                  </td>
                </>
              }

              <TdCount
                width="3"
                className="description pl8"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                  borderLeft: 'solid 1px #E6E7F0',
                }}
              >
                {`E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}
              </TdCount>

              <TdCount
                width="8"
                className="description right p8"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                  borderRight: '1px solid #ccc',
                  background: 'rgba(117, 131, 240, .3)'
                }}
              >
                <FormControl fullWidth>
                  <CurrencyTextFieldSumeValues
                    setCurrentInput={setCurrentInput}
                    updatedValue={updateValueByModal}
                    setUpdateValueByModal={setUpdateValueByModal}
                    confirmModalAction={confirmModalAction}
                    inputMode="numeric"
                    sourcePage="countPage"
                    id={`countInput-${productIndex}-${skuIndex}-${providerIndex}`}
                    isLast={
                      skuIndex >= orderedSkus.length - 1 &&
                      providerIndex >= providers.length - 1 &&
                      !renderProductAdditional
                    }
                    key={id}
                    name="contagem"
                    placeholder="Qtde..."
                    parametersInfos={parametersInfos}
                    product={product}
                    products={internalProduct}
                    modalStates={modalStates}
                    setModalConfirmCountObj={setModalConfirmCountObj}
                    setModalStates={setModalStates}
                    countObj={{
                      product,
                      inputType: 'count',
                      sku,
                      quantity: product.additional,
                      additional: false,
                      skuQuantity: sku.unityAdditional,
                      skuAdditional: false,
                      provider,
                      locationProps: {
                        skus: skus.length,
                        skuIndex
                      }
                    }}
                    handleBlurCountObj={
                      {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      }
                    }
                    onKeyDown={KeyCheck}
                    onBlur={(
                      stringSummableValues,
                      replacedValue,
                      e,
                      isLast
                    ) => {
                      const obj = {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      };

                      setLastProductCounted({
                        productId: product.id,
                        productDescription: product.description,
                        realStock: product.realStock,
                        theoreticalStock: product.theoricalStock,
                        historical: product.historical
                      });
                      handleBlur(
                        stringSummableValues,
                        replacedValue,
                        obj,
                        e,
                        isLast
                      );
                    }}
                    onFocus={() =>
                      handleFocus(
                        product,
                        lastProductCounted,
                        parametersInfos,
                        countId,
                        getId,
                        userInfo
                      )
                    }
                    disabled={isLoadingAssortment || disabledConfirmCount}
                    onFocusProps={{
                      product
                    }}
                    defaultValue={
                      provider?.labelQuantity
                        ? provider?.labelQuantity
                        : isNaN(provider?.stock) ? '' : provider?.stock
                    }
                  />
                </FormControl>
              </TdCount>
            </>
          ) : !product?.ignoreBrands 
            ? (
              <>
                <td 
                  width="30" 
                  className="description pr8" 
                  style={{ 
                    borderLeft: 'solid 1px #E6E7F0',
                    borderTop: isMobile ? '1px solid #ccc' : 'transparent',
                    paddingLeft: 42,
                  }}
                >
                  {!product?.ignoreBrands ? 
                    `${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`
                  : ''}
                </td>

                <TdEmb 
                  width="3" 
                  className="description" 
                  style={{ 
                    padding: '0 10px', 
                    borderTop: isMobile ? '1px solid #ccc' : 'transparent', 
                    borderLeft: isMobile ? '1px solid #E6E7F0' : 'transparent' 
                  }}>
                  {`E1x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}
                </TdEmb>
                
                {isMobile &&
                  <td 
                    className="description p8 mobileQuantityField"
                    style={{ 
                      borderTop: '1px solid #ccc',
                      background: 'rgba(117, 131, 240, .5)'
                    }}
                  >
                    <FormControl fullWidth>
                      <CurrencyTextFieldSumeValues
                        setCurrentInput={setCurrentInput}
                        updatedValue={updateValueByModal}
                        confirmModalAction={confirmModalAction}
                        setUpdateValueByModal={setUpdateValueByModal}
                        inputMode="numeric"
                        sourcePage="countPage"
                        id={`countInput-${productIndex}-${skuIndex}-${providerIndex}-tablet`}
                        isLast={
                          skuIndex >= orderedSkus.length - 1 &&
                          providerIndex >= providers.length - 1 &&
                          !renderProductAdditional
                        }
                        key={skuIndex}
                        name="contagem"
                        disabled={isLoadingAssortment || disabledConfirmCount}
                        placeholder="Adicional em SKU"
                        onKeyDown={KeyCheck}
                        parametersInfos={parametersInfos}
                        product={product}
                        products={internalProduct}
                        modalStates={modalStates}
                        setModalConfirmCountObj={setModalConfirmCountObj}
                        setModalStates={setModalStates}
                        countObj={{
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        }}
                        handleBlurCountObj={
                          {
                            product,
                            sku,
                            inputType: 'skuAdd',
                            quantity: sku?.providers[0]?.stock,
                            additional: false,
                            skuQuantity: sku.unityAdditional,
                            skuAdditional: true,
                            provider: sku?.providers?.length
                              ? sku.providers[0]
                              : null,
                            locationProps: {
                              skus: skus.length,
                              skuIndex: 'addSKU'
                            }
                          }
                        }
                        onBlur={(
                          stringSummableValues,
                          replacedValue,
                          e,
                          isLast
                        ) => {
                          const obj = {
                            product,
                            sku,
                            inputType: 'skuAdd',
                            quantity: sku?.providers[0]?.stock,
                            additional: false,
                            skuQuantity: sku.unityAdditional,
                            skuAdditional: true,
                            provider: sku?.providers?.length
                              ? sku.providers[0]
                              : null,
                            locationProps: {
                              skus: skus.length,
                              skuIndex: 'addSKU'
                            }
                          };

                          setLastProductCounted({
                            productId: product.id,
                            productDescription: product.description,
                            realStock: product.realStock,
                            theoreticalStock: product.theoricalStock,
                            historical: product.historical
                          });
                          handleBlur(
                            stringSummableValues,
                            replacedValue,
                            obj,
                            e,
                            isLast
                          );
                        }}
                        onFocus={() =>
                          handleFocus(
                            product,
                            lastProductCounted,
                            parametersInfos,
                            countId,
                            getId,
                            userInfo
                          )
                        }
                        onFocusProps={{
                          product
                        }}
                        defaultValue={
                          sku?.labelUnityAdditional
                            ? sku?.labelUnityAdditional
                            : sku?.unityAdditional
                        }
                      />
                    </FormControl>
                  </td>
                }

                <TdCount width="3" className="description" style={{ padding: '0 10px', borderTop: isMobile ? '1px solid #ccc' : 'transparent', }}>
                  {`E1x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}
                </TdCount>

                <TdCount
                  width="8"
                  className="description right p8"
                  style={{
                    borderTop: isMobile ? '1px solid #ccc' : 'transparent',
                    borderRight: '1px solid #ccc',
                    background: 'rgba(117, 131, 240, .3)'
                  }}
                >
                  <FormControl fullWidth>
                    <CurrencyTextFieldSumeValues
                      setCurrentInput={setCurrentInput}
                      updatedValue={updateValueByModal}
                      confirmModalAction={confirmModalAction}
                      setUpdateValueByModal={setUpdateValueByModal}
                      inputMode="numeric"
                      sourcePage="countPage"
                      id={`countInput-${productIndex}-${skuIndex}-${providerIndex}`}
                      isLast={
                        skuIndex >= orderedSkus.length - 1 &&
                        providerIndex >= providers.length - 1 &&
                        !renderProductAdditional
                      }
                      key={skuIndex}
                      name="contagem"
                      disabled={isLoadingAssortment || disabledConfirmCount}
                      placeholder="Adicional em SKU"
                      onKeyDown={KeyCheck}
                      parametersInfos={parametersInfos}
                      product={product}
                      products={internalProduct}
                      modalStates={modalStates}
                      setModalConfirmCountObj={setModalConfirmCountObj}
                      setModalStates={setModalStates}
                      countObj={{
                        product,
                        sku,
                        inputType: 'skuAdd',
                        quantity: sku?.providers[0]?.stock,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: true,
                        provider: sku?.providers?.length
                          ? sku.providers[0]
                          : null,
                        locationProps: {
                          skus: skus.length,
                          skuIndex: 'addSKU'
                        }
                      }}
                      handleBlurCountObj={
                        {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        }
                      }
                      onBlur={(
                        stringSummableValues,
                        replacedValue,
                        e,
                        isLast
                      ) => {
                        const obj = {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        };

                        setLastProductCounted({
                          productId: product.id,
                          productDescription: product.description,
                          realStock: product.realStock,
                          theoreticalStock: product.theoricalStock,
                          historical: product.historical
                        });
                        handleBlur(
                          stringSummableValues,
                          replacedValue,
                          obj,
                          e,
                          isLast
                        );
                      }}
                      onFocus={() =>
                        handleFocus(
                          product,
                          lastProductCounted,
                          parametersInfos,
                          countId,
                          getId,
                          userInfo
                        )
                      }
                      onFocusProps={{
                        product
                      }}
                      defaultValue={
                        sku?.labelUnityAdditional
                          ? sku?.labelUnityAdditional
                          : sku?.unityAdditional
                      }
                    />
                  </FormControl>
                </TdCount>
              </>
          ) : null }

          {internalProduct.length && !isMobile && (
            <ValuesList
              skuIndex={skuIndex}
              providerIndex={providerIndex}
              productIndex={productIndex}
              internalProduct={internalProduct}
              countDate={countDate}
              hasCountedOthersStocks={hasCountedOthersStocks}
              isSelectedRealDiff={isSelectedRealDiff}
              parametersInfos={parametersInfos}
              countGroupInfos={countGroupInfos}
              showColumns={showColumns}
              consumedInOutInfos={consumedInOutInfos}
              setConsumedInOutInfos={setConsumedInOutInfos}
              loadingInOutInfos={loadingInOutInfos}
              setLoadingInOutInfos={setLoadingInOutInfos}
              handleGetExtract={handleGetExtract}
            />
          )}
        </tr>
      )
    })
  );
}

export default memo(ProductListItem);
