/* eslint-disable react/destructuring-assignment */
import React from 'react';

import { Tooltip, Fade } from '@material-ui/core';

export default function ValidationLength(
  data,
  charactersCount,
  noTooltip,
  fontBold,
  maxWidth,
  tooltipText = '',
  process = '',
  className = "fontSizeDefault pl0"
) {
  
  if (data?.length > charactersCount || process?.length > charactersCount) {
    return noTooltip ? (
      <td
        className="fontSizeDefault m0 p0"
        style={{
          maxWidth: maxWidth || 'inherit',
          minWidth: maxWidth || 'inherit',
          border: 'none',
          fontWeight: fontBold ? 'bold' : 'inherit'
        }}
        align="left"
        variant="body"
      >
        {`${data.substring(0, charactersCount)}...`}
      </td>
    ) : (
      <Tooltip
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterTouchDelay={0}
        title={tooltipText || `${data} ${process}`}
      >
        <td
          className="fontSizeDefault m0 p0"
          style={{
            maxWidth: maxWidth || 'inherit',
            minWidth: maxWidth || 'inherit',
            border: 'none',
            fontWeight: fontBold ? 'bold' : 'inherit'
          }}
          align="left"
          variant="body"
        >
          {`${data.substring(0, charactersCount)}...`}
        </td>
      </Tooltip>
    );
  }

  return (
    <td
      className={className ? className : "fontSizeDefault pl0"}
      style={{
        maxWidth: maxWidth || 'inherit',
        minWidth: maxWidth || 'inherit',
        border: 'none',
        fontWeight: fontBold ? 'bold' : 'inherit'
      }}
      align="left"
      variant="body"
    >
      {data} {process}
    </td>
  );
}
