/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import {
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import moment from 'moment/moment';
import { connect } from 'react-redux';

import StockInfosIcon from 'images/icons/objects/clocks/clockArrowBackWhiteOutline.svg';
import NoPerformedInfosIcon from 'images/icons/signals/alerts/alertWhiteLightRedBgCircle.svg';

function PerformedCmvInfos({ cmvPerformedInfos, salesInfoType, setModalSettings, modalSettings, filtersChanged, companyActive }) {
  const cmvPerformedInfosContent = cmvPerformedInfos?.content
  const cmv = cmvPerformedInfosContent?.cmv || 0
  const cmvWithDiscount = cmvPerformedInfosContent?.cmvWithDiscount || 0
  const currentCmvValue = salesInfoType ? cmv : cmvWithDiscount
  const percentCmv = cmvPerformedInfosContent?.cmvPercent || 0
  const cmvWithDiscountPercent = cmvPerformedInfosContent?.cmvWithDiscountPercent || 0
  const currentCmvPercent = salesInfoType ? percentCmv : cmvWithDiscountPercent
  const initialDate = moment(cmvPerformedInfosContent?.initialDate)?.format('DD/MM')
  const finalDate = moment(cmvPerformedInfosContent?.finalDate)?.format('DD/MM')
  const goalCmv = cmvPerformedInfosContent?.goalCmv || 0
  const initialStock = cmvPerformedInfosContent?.initialStock || 0
  const finalStock = cmvPerformedInfosContent?.finalStock || 0
  const orders = cmvPerformedInfosContent?.orders || 0
  const transfers = cmvPerformedInfosContent?.transfers || 0
  const isPositiveTarget = currentCmvPercent >= goalCmv

  return (
    <div className="cmvPerformedInfosCard pRelative">
      <div className="dFlex alignCenter mb10">
        <p className="cardTitle mb0">CMV REALIZADO</p>

        {currentCmvValue ?
          <Tooltip
            title={
              <div className="dFlex flexColumn" style={{ padding: '4px 2px' }}>
                <p className="mb0 dFlex flexColumn">
                  <span className="mb10">
                    <b>ESTOQUE INICIAL</b> <br/>
                    {initialDate} - R$ {" "}
                    {initialStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>

                  <span className="mb10">
                    Compras: R$ {" "}
                    {orders?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}<br/>
                    Transferências: R$ {" "}
                    {transfers?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>

                  <span>
                    <b>ESTOQUE FINAL</b> <br/>
                    {finalDate} - R$ {" "}
                    {finalStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>
                </p>
              </div>
            }
            aria-label="add"
          >
            <img
              src={StockInfosIcon}
              alt={StockInfosIcon}
              style={{ width: '12px', marginLeft: '5px' }}
            />
          </Tooltip>
        : null}

        {!currentCmvValue ?
          <Tooltip title="Contagens não configuradas ou não concluída">
            <img
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: true } : modal
                  )
                );
              }}
              src={NoPerformedInfosIcon}
              alt={NoPerformedInfosIcon}
              className="pAbsolute cursorPointer"
              style={{ width: '15px', right: 10, top: 11 }}
            />
          </Tooltip>
        : null}
      </div>
      
      {cmvPerformedInfos?.loading ?
        <div className="w100 dFlex">
          <LinearProgress className="w100" id="loading" variant="query" />
        </div>
      :
        <div className="dFlex alignCenter justifyCenter pRelative w100">
          <span className="bold">
            {currentCmvValue ?
              `R$
              ${currentCmvValue?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`
            : '-'}
          </span>

          <Tooltip
            title={
              <div className="dFlex flexColumn" style={{ padding: '4px 2px' }}>
                <p className="mb0 bold">
                  {isPositiveTarget ? 'Acima' : 'Abaixo'} da meta <br/>
                  META: {" "}
                  {goalCmv?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}%
                </p>
              </div>
            }
            disableHoverListener={!currentCmvValue}
            aria-label="add"
          >
            <div 
              className={`percentChip ${!isPositiveTarget ? 'positive' : 'negative'} ${!currentCmvValue ? 'disabled' : 'cursorPointer'}`}
              onClick={() => {
                if (!currentCmvValue) return;

                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'differenceInCount'
                      ? {
                          ...modal,
                          open: true,
                          currentRestaurant: companyActive,
                          currentStartDate: moment(filtersChanged?.find(
                            (el) => el.param === 'startDate'
                          ).value)?.add(1, 'day')?.format('YYYY-MM-DD'),
                          currentEndDate: filtersChanged?.find(
                            (el) => el.param === 'endDate'
                          ).value,
                          currentDiff: 'all'
                        }
                      : modal
                  )
                );
              }}
            >
              {currentCmvValue 
                ? `${currentCmvPercent?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}%`
                : '-'
              }
            </div>
          </Tooltip>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(PerformedCmvInfos);
