/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import { LinearProgress, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as UserActions from 'store/actions/user';

function ProductionInfosArea({ 
  productionInfos,
  actualDateLessOne,
  actualDateLessFourteen,
  paramsID,
  setModalSettings,
  modalSettings
}) {
  const mainContent = productionInfos?.content

  const costValue = (description) => (
    description === 'sobras' 
    ? `R$ ${(mainContent?.excessProductionsCost || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
    : description === 'faltas'
      ? `R$ ${(mainContent?.lackProductionsCost || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
      : ''
  )

  const progressBarItem = (description, legend, percent, color, url, type) => {
    const isLeftOverOrLack = (type === 'leftOver' || type === 'lack')

    const renderLegendInfos = () => (
      isLeftOverOrLack
      ?
        <div className="legendInfos leftOverOrLack">
          <span className="percent">
            {percent}
          </span>

          <span>
            {legend}
          </span>
        </div>
      :
        <div className="legendInfos">
          <span>
            {legend}
          </span>

          <span className="percent">
            {percent}
          </span>
        </div>
    )

    return (
      <Tooltip
        TransitionProps={{ timeout: 600 }}
        className={`barItem dBlock ${!url && 'disabledLink'}`}
        disableHoverListener={!url}
        title={
          <span
            className="dBlock"
            style={{ padding: '2px 5px' }}
          >
            Visualizar todas as produções
          </span>
        }
      >
        <Link
          target="_blank"
          to={{
            pathname: url
          }}
        >
          <div className="barInfos">
            <span>
              {description} {costValue(description)}
            </span>

            {renderLegendInfos()}
          </div>

          <div className="progressBarBorder">
            <div className="progressBar" style={{ background: color, width: percent }}/>
          </div>
        </Link>
      </Tooltip>
    )
  }

  return (
    <section className="cockpitProductionInfosContainer cockpitDefaultContainer">
      <div className="graphicContainer">
        <div className="graphicBox">
          <div className="graphicItem">
            <div className="dFlex justifyBetween">
              <span className="graphicTitle">
                produção
              </span>

              <span 
                className="bold mt5 cursorPointer fontSizeDefault" 
                style={{ color: '#1F2445' }}
                onClick={() => {
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
                    )
                  );
                }}
              >
                <span className="bold lightBlue mr5">
                  programação hoje:
                </span>

                {(mainContent?.scheduleCopilotWithAction || 0)?.toFixed(0)}%
              </span>
            </div>

            {productionInfos?.loading ? (
              <div className="dFlex">
                <LinearProgress className="w100 mb10" id="loading" variant="query" />
              </div>
            ) : 
              <section className="barInfosContainer">
                {progressBarItem(
                  "encerramento copiloto",
                  `${(mainContent?.closingCopilotTotals || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  /14 dias`,
                  `${(mainContent?.closingCopilotPercent || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#5062F0",
                )}

                {progressBarItem(
                  "sobras",
                  `(${(mainContent?.excessProductions || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/${(mainContent?.totalProductions || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })})`,
                  `${(mainContent?.excessProductionsPercent || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#F2B90D",
                  `/producao/${paramsID}?&startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}&difference=leftover`,
                  'leftOver',
                )}
                
                {progressBarItem(
                  "faltas",
                  `(${(mainContent?.lackProductions || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}/${(mainContent?.totalProductions || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })})`,
                  `${(mainContent?.lackProductionsPercent || 0)?.toLocaleString('pt-br', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}%`,
                  "#F53D4C",
                  `/producao/${paramsID}?&startAt=${actualDateLessFourteen}&endAt=${actualDateLessOne}&difference=lack`,
                  'lack'
                )}
              </section>
            }
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionInfosArea);
