/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef
} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  DeleteOutline,
} from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { TreeSelect } from 'antd';
import axios from 'axios';
import { setHours, setMinutes } from 'date-fns';
import formatISO9075 from 'date-fns/formatISO9075';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import FullScreenLoading from 'components/FullScreenLoading';
import ImageWrapper from 'components/ImageWrapper';
import PaperComponent from 'components/PaperComponent';
import ImageCropper from 'components/Register/ImageCropper';
import Register from 'components/Register/index';
import Layout from 'containers/layouts/layout';
import days from 'data/days.json';
import DefaultImage from 'images/defaultAndEmpties/defaultImageHorizontalBgGray.svg';
import boxBlue from 'images/icons/objects/boxesAndPackages/boxStockBlue.svg';
import boxGray from 'images/icons/objects/boxesAndPackages/boxStockGray.svg';
import ListIcon from 'images/icons/objects/clipboards/listIcon.svg';
import ListIconBlue from 'images/icons/objects/clipboards/listIconBlue.svg';
import AddIconDisabled from 'images/icons/signals/addsAndCreates/addDisabledOutline.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/addEan.svg';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import CloseGrayIcon from 'images/icons/signals/closesAndCancels/closeGrayOutline.svg';
import { InputFile } from 'pages/quotesUpload/style';
import getOptions from 'services/getOptions';
import * as MenuActions from 'store/actions/menu';
import * as ProductsActions from 'store/actions/products';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import {
  calcCmvSimulation,
  calcMarkupSimulation
} from 'utils/calcSimulation';
import convertToBase64 from 'utils/convertToBase64';
import { addUniqueIdAutoComplete } from 'utils/customMaterialTable/addUniqueIdAutocomplete';
import Environment from 'utils/environments';
import history from 'utils/history';
import { isEmpty } from 'utils/isEmpty';
import { setSubGroupsAndStores } from 'utils/setSubGroupsAndStores';

import RegisterProductModal from '../products/ProductForm';

import 'styles/scss/pages/Cadastro.scss';

const formattedDaysOptions = days.map((day) => ({
  ...day,
  id: day.id
}));

const useStyles = makeStyles(() => ({
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

function RegisterManufacturer({
  userInfo,
  cardInfo,
  menuInfo,
  productInfo,
  setEditProducts
}) {
  const storeSegment = userInfo?.companiesActive?.segment
  const environments = Environment(process.env.REACT_APP_ENV);
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const { id: paramsID, cardId } = useParams();
  const inputRef = useRef();
  const { register, handleSubmit, errors } = useForm();
  const blockUserNoAdm = userLocal.profile[0][0].name !== "restaurants" && !!cardId;
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [parameters, setParameters] = useState('');
  const [alertErr, setAlertErr] = useState(false);
  const [alertErrSkus, setAlertErrSkus] = useState(false);
  const [alertErrName, setAlertErrName] = useState(false);
  const [UseOnTransformation, setUseOnTransformation] = useState('')
  const [editMode, setEditMode] = useState(
    cardInfo?.action?.toLowerCase() === 'edit' || !!cardId || false
  );
  const [getAlert, setGetAlert] = useState('');
  const [storageLocationOptions, setStorageLocations] = useState(null);
  const [courts, setCourtsOptions] = useState([]);
  const [courtPreSelected, setCourtPreSelected] = useState(null);
  const [courtSelected, setCourtSelected] = useState(null);
  const [periodicityOptions, setPeriodicityOptions] = useState(null);
  const [products, setProducts] = useState([]);
  const [unitsConsumptions, setUnitsConsumptions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [originId, setOriginId] = useState(paramsID);
  const [enableToLoadPeriodicities, setEnableToLoadPeriodicities] = useState(false)
  const [enableToLoadStorageLocations, setEnableToLoadStorageLocations] = useState(false)
  const [enableToLoadUnits, setEnableToLoadUnits] = useState(false)
  const [enableToLoadMenuOptions, setEnableToLoadMenuOptions] = useState(false)
  const [treeView, setTreeView] = useState(
    cardInfo?.data?.categories?.id || ''
  );
  const [processData, setProcessData] = useState();
  const [processTable, setProcessTable] = useState();
  const [categoriesError, setCategoriesError] = useState(false);
  const [localStorageError, setLocalStorageError] = useState(false);
  const [periodicityError, setPeriodicityError] = useState(false);
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState(
    cardInfo?.data?.consumptionUnits ||
      cardInfo?.data?.unitsMeasurementsMenu?.unitsMeasurements ||
      null
  );
  const [hasControlProduction, setHasControlProduction] = useState(
    cardInfo?.data?.controlProduction
  );
  const [radioValue, setRadioValue] = useState(cardInfo?.data?.type || '');
  const [draft, setDraft] = useState(cardInfo?.data?.draft || false);
  const [active, setActive] = useState(cardInfo?.data?.status || 'ACTIVE');
  const [resales, setResales] = useState(cardInfo?.data?.resale || false);
  const [lowType, setLowType] = useState(cardInfo?.data?.lowType || "NORMAL");
  const [menuOptions, setMenuOptions] = useState([]);
  const [itemsMenu, setItemsMenu] = useState(cardInfo?.data?.itemsMenu || '');
  const [itemsMenuFormatted, setItemsMenuFormatted] = useState({
    added: [],
    deleted: [],
    noChanged: []
  });
  const [controlProduction, setControlProduction] = useState(
    cardInfo?.data?.controlProduction || false
  );
  const [requiredFields, setRequiredFields] = useState([]);
  const [priority, setPriority] = useState(cardInfo?.data?.priority || false);
  const [storageLocation, setStorageLocation] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [preparationMethod, setPreparationMethod] = useState();
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [cardDataTable, setCardDataTable] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });
  const [selectedStartTime, setSelectedStartTime] = useState(
    editMode && cardInfo?.data?.startTime
      ? setMinutes(
          setHours(
            new Date(),
            cardInfo?.data?.startTime[0] + cardInfo?.data?.startTime[1]
          ),
          cardInfo?.data?.startTime[3] + cardInfo?.data?.startTime[4]
        )
      : null
  );
  const [selectedEndTime, setSelectedEndTime] = useState(
    editMode && cardInfo?.data?.endTime
      ? setMinutes(
          setHours(
            new Date(),
            cardInfo?.data?.endTime[0] + cardInfo?.data?.endTime[1]
          ),
          cardInfo?.data?.endTime[3] + cardInfo?.data?.endTime[4]
        )
      : null
  );
  const cardDaysIds = cardInfo?.data?.daysWeeks?.map(
    (item) => item?.daysWeekId
  );
  const [daysSelect, setDaysSelect] = useState(
    formattedDaysOptions?.filter((day) => cardDaysIds?.includes(day.id)) || []
  );
  const [validade, setValidade] = useState(
    cardInfo?.data?.quantityValidDays || null
  );
  const [productionTime, setProductionTime] = useState(
    cardInfo?.data?.startTime || cardInfo?.data?.endTime
      ? true
      : !!(
          editMode &&
          cardInfo?.data?.daysWeeks &&
          cardInfo?.data?.daysWeeks?.length > 0
        )
  );
  const [costTotal, setCostTotal] = useState(
    cardInfo?.data?.costs?.costTotal || 0
  );
  const [description, setDescription] = useState(
    cardInfo?.data?.description?.trim() || ''
  );
  const [averagePriceSimulation, setAveragePriceSimulation] = useState(
    cardInfo?.data?.averagePriceSimulation || 0
  );
  const [markupSimulation, setMarkupSimulation] = useState(
    cardInfo?.data?.markupSimulation || 0
  );
  const [cmvSimulation, setCmvSimulation] = useState(
    cardInfo?.data?.cmvSimulation || 0
  );
  const [valueLoss, setValueLoss] = useState(
    cardInfo?.data?.productions?.loss || 0
  );
  const [lastCountObj, setLastCountObj] = useState(
    editMode
      ? cardInfo?.data?.type === 'RECIPE'
        ? [
            {
              ...cardInfo?.data?.product[0],
              description: cardInfo?.data?.description?.trim(),
              un: cardInfo?.data?.consumptionUnits,
              storageLocations: cardInfo?.data?.product[0]?.setups
                .reduce(
                  (acc, item) => [
                    ...acc,
                    item?.config && item?.config?.storageLocation?.id
                  ],
                  []
                )
                .flat(),
              periodicities: cardInfo?.data?.product[0]?.setups
                .reduce(
                  (acc, item) => [
                    ...acc,
                    item?.config && item?.config?.periodicity?.id
                  ],
                  []
                )
                .flat()
            }
          ]
        : cardInfo?.data?.items
      : []
  );
  const [margin, setMargin] = useState(
    editMode && cardInfo?.data?.menu?.length > 0
      ? cardInfo.data.menu[0]?.margin
      : null
  );
  const [cmv, setCmv] = useState(
    editMode && cardInfo?.data?.menu?.length > 0
      ? cardInfo?.data?.menu[0]?.cmv
      : null
  );
  const [markup, setMarkup] = useState(
    editMode && cardInfo?.data?.menu?.length > 0
      ? cardInfo?.data?.menu[0]?.markup
      : null
  );
  const [salesAverage, setSalesAverage] = useState(
    editMode && cardInfo?.data?.menu?.length > 0
      ? cardInfo?.data?.menu[0]?.salesAverage
      : null
  );
  const [display, setDisplay] = useState(editMode ? null : { display: 'none' });
  const [production, setProduction] = useState({
    yield: cardInfo?.data?.productions?.yield || null,
    yieldTotal: cardInfo?.data?.productions?.yieldTotal || null
  });
  const [productionMin, setProductionMin] = useState(
    cardInfo?.data?.productionMin || ''
  );

  const [transformation, setTransformation] = useState(
    cardInfo?.data?.transformation || {
      type: 'product',
      productId: null,
      stock: 0,
      consumptionUnitsPrimaryId: null,
      consumptionUnitsPrimary: null,
      cost: 0,
      averageCost: 0,
      lastCost: 0,
      quantity: 0
    }
  );
  const [productTransformation, setProductTransformation] = useState({});
  const [cost, setCost] = useState({
    discountPrice: 0,
    averagePrice: 0,
    costTotal: 0,
    costUnit: 0
  });
  const [sales, setSales] = useState({
    salesPrice: 0
  });
  const [newProductRow, setNewProductRow] = useState('');
  const [productMain, setProductMain] = useState(false);
  const [messagePersonal, setMessagePersonal] = useState('');
  const [newStorageLocationRow, setNewStorageLocationRow] = useState([]);
  const [newPeriodicityRow, setNewPeriodicityRow] = useState([]);
  const [showColumnsProducts, setShowColumnsProducts] = useState(true);
  const filter = createFilterOptions();
  const isGroupManage = paramsID?.includes(',');
  const [categoryType, setCategoryType] = useState(
    cardInfo?.data?.type === 'MENU' ? 'Sales' : 'Shopping'
  );
  const classes = useStyles();
  const [dataTable, setDataTable] = useState(
    cardInfo?.data?.items?.length
      ? {
          data: cardInfo.data.items
        }
      : { data: [] }
  );
  const [imageData, setImageData] = useState({
    data: {
      images: null,
    }
  });
  const [cropper, setCropper] = useState();
  const [allEqual, setAllEqual] = useState()
  let notHasSkus = cardInfo?.data?.notHasSkus || false;

  const validStorages = () => {
    if(newProductRow?.hasInventory) {
      if(newProductRow?.storageLocations?.length && newProductRow?.frequencies?.length) {
        return false
      }

      return true
    } 

    if(!newProductRow?.hasInventory) {
      return false
    }

  } 

  useEffect(() => {
    const productsEqual = dataTable.data?.every(item => item?.un?.id === dataTable?.data[0]?.un?.id);

    setAllEqual(productsEqual)

  }, [dataTable])
  const onDismiss = () => {
    setAlertErr(false);
    setAlertErrSkus(false);
    setAlertErrName(false);
    setLoading(false);
    setSaving(false);
    setMessagePersonal('')
  };
  const [toastOpen, setToastOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [hasProduction, setHasProduction] = useState(false);
  const [toastProps, setToastProps] = useState();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [imagePopoverOpen, setImagePopoverOpen] = useState(false);
  const [currentProduct, setCurrentProduct] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [percentAccepted, setPercentAccepted] = useState();
  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;
  const [modalStates, setModalStates] = useState({
    modalProduct: false,
    modalTechnicalSheet: false
  });
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'confirmItemsMenuDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentItem: null
    },
    {
      id: 2,
      name: 'imageCropper',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    }
  ]);
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [menuId, setMenuId] = useState();
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [percentageDiscountValue, setPercentageDiscountValue] = useState();
  const [currentCardDateInfos, setCurrentCardDateInfos] = useState({});
  const [restaurantsId, setRestaurantsId] = useState(
    userInfo.companies.groups[0].subGroups[0].stores
  );
  const [restaurantGroups, setRestaurantGroups] = useState([
    userInfo.companies.groups[0]
  ]);
  const [restaurantSubGroups, setRestaurantSubGroups] = useState([]);
  const [restaurantStores, setRestaurantStores] = useState([]);
  const [userGroups, setUserGroups] = useState({
    groups: [
      {
        subGroups: [
          {
            stores: [{}]
          }
        ]
      }
    ]
  });
  const [lastCountErr, setLastCountErr] = useState(false);

  const [internalModalSettings, setInternalModalSettings] = useState([
    {
      id: 1,
      name: "confirmDialog",
      open: false,
      fullScreen: false,
      maxWidth: "sm",
      currentUser: null
    }
  ])

  const confirmDialogInfo = internalModalSettings.find((modal) => modal?.name === "confirmDialog")

  const handleClickFakeImportPdf = () => {
    document.querySelector('#pdfImport').click();
  };

  const sortDataInfoItems = items => {
    const orderedItems = []

    if (items && Array.isArray(items) && items.length > 0) {
      // Separa os itens em principais e outros
      const mainItems = items.filter(item => item?.main)
      const otherItems = items.filter(item => !item?.main)

      // Ordena ambos os itens por ordem alfabética
      mainItems.sort((a, b) => a.description.localeCompare(b.description))
      otherItems.sort((a, b) => a.description.localeCompare(b.description))

      // Adiciona no array de itens ordenados
      orderedItems.push(...mainItems, ...otherItems)
    }

    return orderedItems
  }

  const uploadPdf = async (inputRef, event) => {
    const formatFileValid = ['pdf'];

    if (
      !formatFileValid.includes(event.target.files[0]?.name?.split('.').pop())
    ) {
      setToastProps({
        severity: 'error',
        message:
          'Formato de arquivo inválido. Favor utilizar arquivos em formato .pdf'
      });
      setToastOpen(true);
      return;
    }

    setHasChanged(true);

    const spreadsheetBase64 = await convertToBase64(event.target.files[0]);

    setPreparationMethod({
      url: spreadsheetBase64,
      name: event.target.files[0]?.name || 'pdf_file'
    });

    const { current: { value } = {} } = inputRef;

    if (value) {
      inputRef.current.value = null;
    }
  };

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
    setPopoverOpen(false);

    toastProps?.severity === 'success' &&
      history.push({
        pathname: `/catalogo/fichas/${paramsID}`,
        state: { prevPath: 'cardsForm' }
      });
  };

  const validateRequiredFields = () => {
    let emptyItems = [];
    const isTransformation = radioValue === 'TRANSFORMATION'

    if (!description) {
      emptyItems = [...emptyItems, 'description'];
    }

    if (!production?.yieldTotal) {
      emptyItems = [...emptyItems, 'yieldTotal'];
    }

    if (!ucPrimarySelectedId) {
      emptyItems = [...emptyItems, 'ucPrimarySelectedId'];
    }

    if (!treeView) {
      emptyItems = [...emptyItems, 'category'];
    }

    if (lowType === "AUTOMATIC" && !itemsMenu) {
      emptyItems = [...emptyItems, 'itemsMenu'];
    }

    if (!productTransformation?.description && isTransformation) {
      emptyItems = [...emptyItems, 'productTransformation'];
    }

    setRequiredFields(emptyItems);

    return (
      !!description &&
      (lowType === "AUTOMATIC" ? itemsMenu : true) &&
      (radioValue === 'RECIPE' ? !!production?.yieldTotal : true) &&
      (radioValue === 'RECIPE' ? !!ucPrimarySelectedId : true) &&
      !!treeView &&
      (!!productTransformation?.description || !isTransformation)
    );
  };

  const getMoreOptions = (getMoreCurrentOptions, value) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      getMoreCurrentOptions(value);
    }, 1500);
    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  // const hasDivergentUnitsTransformation = () => {
  //   const { consumptionUnitsPrimaryId = undefined } = transformation
  //   const { id: consumptionUnitsPrimarySelectedId = undefined } = ucPrimarySelectedId

  //   if (!isEmpty(consumptionUnitsPrimaryId) && !isEmpty(consumptionUnitsPrimarySelectedId)) {
  //     if (consumptionUnitsPrimaryId !== consumptionUnitsPrimarySelectedId) {
  //       return true
  //     }
  //   }

  //   return false
  // }

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products
                ? product.products.description?.trim()
                : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products
                ? product.products.description?.trim()
                : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalStates({ ...modalStates, modalTechnicalSheet: true });
  };

  const fetchRestaurantsOfCard = async (cardsId) => {
    const response = await fetch(
      `${environments.catalog}/cards/${cardsId}/groups`
    );
    const groups = await response.json();

    return groups;
  };

  const setRestaurants = (currentCard) => {
    (async () => {
      const groupActive =
        userInfo.companies.groups.length > 0
          ? userInfo.companies.groups.find((group) =>
              group.subGroups?.some((subGroup) =>
                subGroup.stores?.some(
                  (store) => store.id === userInfo.companiesActive.id
                )
              )
            )
          : {};

      const subGroupActive =
        userInfo.companies.groups.length > 0 ? groupActive.subGroups : {};

      if (editMode && currentCard?.id) {
        const cardsId = currentCard?.id;
        const origins = await fetchRestaurantsOfCard(cardsId);

        if (origins && origins.length > 0) {
          const { selectedSubGroups, selectedStores } = setSubGroupsAndStores({
            subGroups: groupActive.subGroups,
            originIds: origins
          });

          setRestaurantSubGroups(selectedSubGroups);
          setRestaurantStores(selectedStores);
        } else {
          setRestaurantSubGroups(groupActive?.subGroups);
          setRestaurantStores(
            subGroupActive
              .map((subGroup) => subGroup.stores.map((store) => store).flat())
              .flat()
          );
        }
      } else {
        setRestaurantSubGroups(groupActive?.subGroups);
        setRestaurantStores(
          subGroupActive
            .map((subGroup) => subGroup.stores.map((store) => store).flat())
            .flat()
        );
      }
    })();
  };

  const getCourts = () => {
    axios
      .get(`${environments.catalog}/courts?restaurantId=${originId}`)
      .then((response) => {
        setCourtsOptions(response.data.content);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCardProductionInfo = () => {
    const currentCardId = cardInfo?.data?.id || cardId;

    axios
      .get(
        `${environments.manufactures}/manufactures/hasProduction/${currentCardId}?restaurantId=${originId}`
      )
      .then((response) => {
        setHasProduction(response?.data?.hasProduction);

        !response?.data?.hasProduction &&
          setDataTable({
            ...dataTable,
            data: dataTable.data.map((item) => ({
              ...item,
              stock: '-',
              costAverage: '-',
              costLast: transformation.lastCost / (1 - valueLoss / 100)
              // costAverage:
              //   transformation.averageCost +
              //   transformation.averageCost * (value / 100)
            }))
          });
      })
      .catch((error) => {
        setHasProduction(false);
      });
  };

  const handleAutocompleteChange = (value) => {
    setProcessTable(value);
  }

  const handleOptionSelect = () => {
    // Remove o foco do campo de entrada quando uma opção é selecionada
    inputRef.current.blur();
  };

  const handleFilter = (text) => (d) => d.description.indexOf(text) > -1;

  const buildItemsMenu = () => {
    const addedItems = itemsMenuFormatted?.added?.filter(el => el?.id)?.map(added => (
      {
        menuId: added?.id,
        action: 'C'
      }
    ))
    
    const deletedItems = itemsMenuFormatted?.deleted?.filter(el => el?.id)?.map(deleted => (
      {
        menuId: deleted?.id,
        action: 'D'
      }
    ))

    const noChangedItems = itemsMenuFormatted?.noChanged?.filter(el => el?.id)?.map(noChanged => (
      {
        menuId: noChanged?.id,
        action: ''
      }
    ))

    return [addedItems, deletedItems, noChangedItems].flat()
  }

  const validateProductionHourInfos = useMemo(
    () => daysSelect.length || (selectedStartTime && selectedEndTime),
    [daysSelect.length, selectedEndTime, selectedStartTime]
  );

  const onSubmit = (data) => {
    const config = {
      headers: {
        User: `${userLocal?.user}`
      }
    };

    const userData = JSON.parse(localStorage.getItem("token"));

    if (productionTime && !validateProductionHourInfos) {
      setToastOpen(true);
      setToastProps({
        severity: 'error',
        color: '',
        message: 'Por favor, preencha as informações de produção.',
        timeOutOff: false
      });
      return;
    }

    if (!validateRequiredFields()) {
      setToastOpen(true);
      setToastProps({
        severity: 'error',
        color: '',
        message: 'Por favor, preencha os campos obrigatórios.',
        timeOutOff: false
      });

      return;
    }

    if (!allEqual) {
      setToastOpen(true);
      setToastProps({
        severity: 'error',
        color: '',
        message: 'Os produtos devem ter o mesmo tipo de unidade',
        timeOutOff: false
      });

      return;
    }

    setRequiredFields([]);

    if (!loading) {
      treeView === ''
        ? setCategoriesError(true)
        : setTimeout(() => {
            const groups = restaurantGroups || null;

            if (editMode) {
              setAlertErrSkus(false);
              if (dataTable.data.length !== 0) {
                if (radioValue === 'TRANSFORMATION') {
                  if (transformation.product.id) {
                    const daysWeek = [];
                    const items = [];
                    const storages = [];
                    const frequencies = [];
                    let currentLastCountItem = {};

                    daysSelect.forEach((day) => {
                      daysWeek.push({ id: day.id });
                    });

                    dataTable.data.forEach((item) => {
                      currentLastCountItem = lastCountObj.find(
                        (el) => el.description === item.description
                      );

                      items.push({
                        type: 'product',
                        productId: item.id,
                        description: item.description,
                        quantity: 0,
                        lossPercentage: item?.lossPercentage,
                        hasInventory: (item?.storageLocations?.length || item?.products?.storageLocations?.length) && (item?.frequencies?.length || item?.products?.frequencies?.length) ? item?.hasInventory : false,
                        cost: transformation.cost || 0,
                        unitsMeasurementsId: item?.consumptionUnitsPrimaryId || item?.un?.id,
                        storageLocations: item?.hasInventory ? item?.storageLocations || item?.products?.storageLocations : currentLastCountItem?.storageLocations || [],
                        frequencies: item?.hasInventory ? item?.frequencies || item?.products?.frequencies : currentLastCountItem?.frequencies || [],
                        realStock: currentLastCountItem?.realStock,
                        main: item.main,
                        image: item?.images?.url
                      });
                    });

                    const card = {
                      groups,
                      type: radioValue,
                      draft,
                      status: active,
                      lowType,
                      itemsMenu: buildItemsMenu(),
                      courtId: courtSelected?.id || null,
                      description: description.trim(),
                      preparationMethod,
                      lossPercentage: percentAccepted?.loss || 0,
                      variationPercentage: percentAccepted?.variation,
                      productionMin: productionMin 
                        ? ucPrimarySelectedId?.abbreviation !== 'un' ? parseFloat(productionMin) : parseInt(parseFloat(productionMin))
                        : null,
                      quantityValidDays: validade ? parseFloat(validade) : null,
                      daysWeek: productionTime ? daysWeek : null,
                      consumptionUnitsId: ucPrimarySelectedId?.id || 3,
                      restaurantId: isGroupManage ? currentCardDateInfos?.restaurantId : userInfo.companiesActive.id,
                      user: userInfo.user,
                      categoryId: treeView,
                      startTime:
                        productionTime && selectedStartTime
                          ? formatISO9075(selectedStartTime, {
                              representation: 'time'
                            })
                          : null,
                      endTime:
                        productionTime && selectedEndTime
                          ? formatISO9075(selectedEndTime, {
                              representation: 'time'
                            })
                          : null,
                      controlProduction,
                      priority,
                      cost: [cost],
                      sales: [sales],
                      production: [
                        {
                          yield: production.yield,
                          yieldTotal: production.yieldTotal,
                          loss: valueLoss
                        }
                      ],
                      transformation: [
                        {
                          type: 'product',
                          productId: transformation.product.id,
                          quantity: transformation.consumptionUnitsPrimary === 'un' ? parseInt(parseFloat(transformation?.quantity)) : transformation.quantity,
                          unitsMeasurementsId:
                            transformation.consumptionUnitsPrimaryId,
                          cost: transformation.cost || 0
                        }
                      ],
                      items
                    };
                    setLoading(true);
                    setSaving(true);

                    axios
                      .put(
                        `${environments.catalogV2}/cards/${
                          cardInfo?.data?.id || cardId
                        }`,
                        card,
                        config
                      )
                      .then((res) => {
                        setToastProps({
                          severity: 'success',
                          color: '',
                          message: 'Cadastro realizado com sucesso!',
                          timeOutOff: false
                        });
                        setToastOpen(true);
                        setLoading(false);
                        setSaving(false);
                        setHasChanged(false);
                      })
                      .catch((error) => {
                        setGetAlert(error.response.data.message);
                        setLoading(false);
                        setSaving(false);
                        setAlertErr(true);
                        setPopoverOpen(false);
                      });
                  } else {
                    setLoading(false);
                    setSaving(false);
                  }
                } else if (radioValue === 'RECIPE') {
                  const daysWeek = [];
                  const storages = [];
                  const frequencies = [];
                  const items = [];

                  periodicities.forEach((frequency) => {
                    frequencies.push({
                      id: frequency.id,
                      description: frequency.description
                    });
                  });

                  storageLocation.forEach((storage) => {
                    storages.push({
                      id: storage.id,
                      description: storage.description
                    });
                  });

                  daysSelect.forEach((day) => {
                    daysWeek.push({ id: day.id });
                  });

                  dataTable.data.forEach((item) => {
                    items.push({
                      description: item?.description || '',
                      productId: item.id,
                      quantity:
                        typeof item.quantity === 'string'
                          ? item.quantity.indexOf(',') > -1
                            ? parseFloat(
                                item.quantity
                                  .replace('.', '')
                                  .replace(/,/g, '.')
                              )
                            : parseFloat(item.quantity)
                          : parseFloat(item.quantity),
                      cost:
                        typeof item.cost === 'string'
                          ? parseFloat(
                              item.cost.replace('.', '').replace(/,/g, '.')
                            )
                          : parseFloat(item.cost),
                      unitsMeasurementsId: item?.consumptionUnitsPrimaryId || item?.un?.id,
                      main: item.main,
                      image: item?.images?.url
                    });
                  });

                  const recipe = {
                    groups,
                    type: radioValue,
                    draft,
                    status: active,
                    lowType,
                    itemsMenu: buildItemsMenu(),
                    description,
                    preparationMethod,
                    courtId: courtSelected?.id || null,
                    user: userInfo.user,
                    lossPercentage: percentAccepted?.loss || 0,
                    variationPercentage: percentAccepted?.variation,
                    productionMin: productionMin
                      ? parseFloat(productionMin)
                      : null,
                    quantityValidDays: validade ? parseFloat(validade) : null,
                    daysWeek: productionTime ? daysWeek : null,
                    consumptionUnitsId: ucPrimarySelectedId?.id,
                    restaurantId: isGroupManage ? currentCardDateInfos?.restaurantId : userInfo.companiesActive.id,
                    categoryId: treeView,
                    realStock: lastCountObj.length
                      ? lastCountObj[0].realStock
                      : 0,
                    startTime:
                      productionTime && selectedStartTime
                        ? formatISO9075(selectedStartTime, {
                            representation: 'time'
                          })
                        : null,
                    endTime:
                      productionTime && selectedEndTime
                        ? formatISO9075(selectedEndTime, {
                            representation: 'time'
                          })
                        : null,
                    controlProduction,
                    priority,
                    storageLocations: storages,
                    frequencies,
                    cost: [
                      {
                        discountPrice: 0,
                        averagePrice: 0,
                        costTotal,
                        costUnit: costTotal / production.yieldTotal
                      }
                    ],
                    sales: [sales],
                    production: [
                      {
                        yield: production.yieldTotal,
                        yieldTotal: production.yieldTotal,
                        loss: 0
                      }
                    ],
                    items,
                    averagePriceSimulation: averagePriceSimulation
                      ? parseFloat(
                          parseFloat(averagePriceSimulation).toFixed(3)
                        )
                      : null,
                    markupSimulation: markupSimulation
                      ? parseFloat(parseFloat(markupSimulation).toFixed(3))
                      : null,
                    cmvSimulation: cmvSimulation
                      ? parseFloat(parseFloat(cmvSimulation).toFixed(3))
                      : null,
                    image: imageData?.data?.images?.url
                  };

                  setLoading(true);
                  setSaving(true);
                  const hasSomeSetupInfo =
                    storageLocation.length || periodicities.length;
                  const hasOnlyOneSetupInfo =
                    (storageLocation.length && !periodicities.length) ||
                    (!storageLocation.length && periodicities.length);

                  if (hasSomeSetupInfo && hasOnlyOneSetupInfo) {
                    setLocalStorageError(true);
                    setPeriodicityError(true);
                    setLoading(false);
                    setSaving(false);
                  } else {
                    axios
                      .put(
                        `${environments.catalogV2}/cards/${
                          cardInfo?.data?.id || cardId
                        }`,
                        recipe,
                        config
                      )
                      .then((res) => {
                        setToastProps({
                          severity: 'success',
                          message: 'Cadastro realizado com sucesso!',
                          timeOutOff: false
                        });
                        setToastOpen(true);
                        setLoading(false);
                        setSaving(false);
                        setHasChanged(false);
                      })
                      .catch((error) => {
                        setGetAlert(error.response.data.message);
                        setLoading(false);
                        setSaving(false);
                        setAlertErr(true);
                        setPopoverOpen(false);
                      });
                  }
                } else if (radioValue === 'MENU') {
                  const daysWeek = [];
                  const storages = [];
                  const frequencies = [];
                  const items = [];
                  dataTable.data.forEach((item) => {
                    items.push({
                      productId: item.id,
                      quantity:
                        item.quantity.indexOf(',') > -1
                          ? parseFloat(
                              item.quantity.replace('.', '').replace(/,/g, '.')
                            )
                          : parseFloat(item.quantity),
                      cost: parseFloat(
                        item.cost.replace('.', '').replace(/,/g, '.')
                      ),
                      unitsMeasurementsId: item.consumptionUnitsPrimaryId
                    });
                  });

                  const card = {
                    groups,
                    type: radioValue,
                    draft,
                    status: active,
                    lowType,
                    itemsMenu: buildItemsMenu(),
                    courtId: courtSelected?.id || null,
                    description: description.trim(),
                    user: userInfo.user,
                    lossPercentage: percentAccepted?.loss || 0,
                    variationPercentage: percentAccepted?.variation,
                    unitsMeasurementsMenu: {
                      unitsMeasurementsId: ucPrimarySelectedId?.id,
                      quantity: parseFloat(production.yieldTotal)
                    },
                    restaurantId: isGroupManage ? currentCardDateInfos?.restaurantId : userInfo.companiesActive.id,
                    resale: resales,
                    categoryId: treeView,
                    cost: [
                      {
                        discountPrice: 0,
                        averagePrice: 0,
                        costTotal,
                        costUnit: costTotal
                      }
                    ],
                    sales: [sales],
                    production: [
                      {
                        yield: parseFloat(production.yieldTotal),
                        yieldTotal: parseFloat(production.yieldTotal),
                        loss: 0
                      }
                    ],
                    stock: [
                      {
                        stockTotal: 0
                      }
                    ],
                    items,
                    averagePriceSimulation: averagePriceSimulation
                      ? parseFloat(
                          parseFloat(averagePriceSimulation).toFixed(3)
                        )
                      : null,
                    markupSimulation: markupSimulation
                      ? parseFloat(parseFloat(markupSimulation).toFixed(3))
                      : null,
                    cmvSimulation: cmvSimulation
                      ? parseFloat(parseFloat(cmvSimulation).toFixed(3))
                      : null
                  };
                  setLoading(true);
                  axios
                    .put(
                      `${environments.catalogV2}/cards/${
                        cardInfo?.data?.id || cardId
                      }`,
                      card,
                      config
                    )
                    .then((res) => {
                      setToastProps({
                        severity: 'success',
                        message: 'Cadastro realizado com sucesso!',
                        timeOutOff: false
                      });
                      setToastOpen(true);
                      setLoading(true);
                      setSaving(true);
                    })
                    .catch((error) => {
                      setGetAlert(error.response.data.message);
                      setLoading(false);
                      setSaving(false);
                      setAlertErr(true);
                      setPopoverOpen(false);
                    });
                }
              } else {
                setGetAlert('Registration of at least 1 product is mandatory');
                setAlertErrSkus(true);
                setLoading(false);
                setSaving(false);
              }
            } else {
              setAlertErrSkus(false);
              setLoading(true);

              if (dataTable.data.length !== 0) {
                if (radioValue === 'TRANSFORMATION') {
                  if (transformation.productId) {
                    const daysWeek = [];
                    const items = [];
                    const storages = [];
                    const frequencies = [];
                    let currentLastCountItem = {};

                    daysSelect.forEach((day) => {
                      daysWeek.push({ id: day.id });
                    });

                    dataTable.data.forEach((item) => {
                      currentLastCountItem = lastCountObj.find(
                        (el) => el.description === item.description
                      );

                      items.push({
                        type: 'product',
                        productId: item?.id,
                        description: item?.description,
                        quantity: 0,
                        lossPercentage: item?.lossPercentage,
                        hasInventory: (item?.storageLocations?.length || item?.products?.storageLocations?.length) && (item?.frequencies?.length || item?.products?.frequencies?.length) ? item?.hasInventory : false,
                        cost: transformation?.cost || 0,
                        averageCostStock:
                          transformation?.averageCost ||
                          transformation?.averageCostStock ||
                          0,
                        lastCostStock:
                          transformation?.lastCost ||
                          transformation?.lastCostStock ||
                          0,
                        unitsMeasurementsId: item?.consumptionUnitsPrimaryId || item?.un?.id,
                        storageLocations: item?.hasInventory ? item?.storageLocations || item?.products?.storageLocations : currentLastCountItem?.storageLocations || [],
                        frequencies: item?.hasInventory ? item?.frequencies || item?.products?.frequencies : currentLastCountItem?.frequencies || [],
                        realStock: currentLastCountItem?.realStock,
                        main: item.main,
                        image: item?.images?.url
                      });
                    });

                    const card = {
                      user: userInfo.user,
                      groups,
                      type: radioValue,
                      draft,
                      status: active,
                      lowType,
                      itemsMenu: buildItemsMenu(),
                      courtId: courtSelected?.id || null,
                      description: description.trim(),
                      preparationMethod,
                      productionMin: productionMin
                        ? parseFloat(productionMin)
                        : null,
                      quantityValidDays: validade ? parseFloat(validade) : null,
                      daysWeek: productionTime ? daysWeek : null,
                      consumptionUnitsId: ucPrimarySelectedId?.id || 3,
                      restaurantId: isGroupManage ? userInfo.companiesActive.id?.split(',')[0] : userInfo.companiesActive.id,
                      lossPercentage: percentAccepted?.loss || 0,
                      variationPercentage: percentAccepted?.variation,
                      categoryId: treeView,
                      startTime:
                        productionTime && selectedStartTime
                          ? formatISO9075(selectedStartTime, {
                              representation: 'time'
                            })
                          : null,
                      endTime:
                        productionTime && selectedEndTime
                          ? formatISO9075(selectedEndTime, {
                              representation: 'time'
                            })
                          : null,
                      controlProduction,
                      priority,
                      cost: [cost],
                      sales: [sales],
                      production: {
                        yield: production.yield,
                        yieldTotal: production.yieldTotal,
                        loss: valueLoss
                      },
                      transformation: [
                        {
                          type: 'product',
                          productId: transformation.productId,
                          quantity: transformation.quantity,
                          unitsMeasurementsId:
                            transformation.consumptionUnitsPrimaryId,
                          cost: transformation.cost || 0
                        }
                      ],
                      items
                    };

                    setLoading(true);
                    setSaving(true);

                    axios
                      .post(`${environments.catalog}/cards`, card, config)
                      .then((res) => {
                        setToastProps({
                          severity: 'success',
                          message: 'Cadastro realizado com sucesso!',
                          timeOutOff: false
                        });
                        setToastOpen(true);
                        setLoading(false);
                        setSaving(false);
                      })
                      .catch((error) => {
                        setGetAlert(error.response.data.message);
                        if (error.response.status === 470)
                          setAlertErrName(true);
                        else setAlertErr(true);
                        setLoading(false);
                        setSaving(false);
                        setPopoverOpen(false);
                      });
                  } else {
                    setSaving(false);
                  }
                }

                if (radioValue === 'RECIPE') {
                  const daysWeek = [];
                  const storages = [];
                  const frequencies = [];
                  const items = [];

                  storageLocation.forEach((storage) => {
                    storages.push({
                      id: storage.id,
                      description: storage.description
                    });
                  });

                  periodicities.forEach((frequency) => {
                    frequencies.push({
                      id: frequency.id,
                      description: frequency.description
                    });
                  });

                  daysSelect.forEach((day) => {
                    daysWeek.push({ id: day.id });
                  });

                  dataTable.data.forEach((item) => {
                    items.push({
                      description: item?.description || '',
                      productId: item.id,
                      quantity:
                        item.quantity.indexOf(',') > -1
                          ? parseFloat(
                              item.quantity.replace('.', '').replace(/,/g, '.')
                            )
                          : parseFloat(item.quantity),
                      cost: parseFloat(
                        item.cost.replace('.', '').replace(/,/g, '.')
                      ),
                      unitsMeasurementsId: item?.consumptionUnitsPrimaryId || item?.un?.id,
                      main: item.main,
                      image: item?.images?.url
                    });
                  });

                  const recipe = {
                    user: userInfo.user,
                    groups,
                    type: radioValue,
                    draft,
                    status: active,
                    lowType,
                    itemsMenu: buildItemsMenu(),
                    courtId: courtSelected?.id || null,
                    description: description.trim(),
                    preparationMethod,
                    image: imageData?.data?.images?.url,
                    lossPercentage: percentAccepted?.loss || 0,
                    variationPercentage: percentAccepted?.variation,
                    productionMin: productionMin
                      ? parseFloat(productionMin)
                      : null,
                    quantityValidDays: validade ? parseFloat(validade) : null,
                    daysWeek: productionTime ? daysWeek : null,
                    consumptionUnitsId: ucPrimarySelectedId?.id,
                    restaurantId: isGroupManage ? userInfo.companiesActive.id[0] : userInfo.companiesActive.id,
                    realStock: lastCountObj.length
                      ? lastCountObj[0].realStock
                      : 0,
                    categoryId: treeView,
                    startTime:
                      productionTime && selectedStartTime
                        ? formatISO9075(selectedStartTime, {
                            representation: 'time'
                          })
                        : null,
                    endTime:
                      productionTime && selectedEndTime
                        ? formatISO9075(selectedEndTime, {
                            representation: 'time'
                          })
                        : null,
                    controlProduction,
                    priority,
                    storageLocations: storages,
                    frequencies,
                    cost: [
                      {
                        discountPrice: 0,
                        averagePrice: 0,
                        costTotal,
                        costUnit: costTotal / production.yieldTotal
                      }
                    ],
                    sales: [sales],
                    production: {
                      yield: production.yieldTotal,
                      yieldTotal: production.yieldTotal,
                      loss: 0
                    },
                    items,
                    averagePriceSimulation: averagePriceSimulation
                      ? parseFloat(
                          parseFloat(averagePriceSimulation).toFixed(3)
                        )
                      : null,
                    markupSimulation: markupSimulation
                      ? parseFloat(parseFloat(markupSimulation).toFixed(3))
                      : null,
                    cmvSimulation: cmvSimulation
                      ? parseFloat(parseFloat(cmvSimulation).toFixed(3))
                      : null
                  };

                  const hasSomeSetupInfo =
                    storageLocation.length || periodicities.length;
                  const hasOnlyOneSetupInfo =
                    (storageLocation.length && !periodicities.length) ||
                    (!storageLocation.length && periodicities.length);

                  if (hasSomeSetupInfo && hasOnlyOneSetupInfo) {
                    setLocalStorageError(true);
                    setPeriodicityError(true);
                  } else {
                    setLoading(true);
                    setSaving(true);

                    axios
                      .post(`${environments.catalog}/cards`, recipe, config)
                      .then((res) => {
                        setToastProps({
                          severity: 'success',
                          message: 'Cadastro realizado com sucesso!',
                          timeOutOff: false
                        });
                        setToastOpen(true);
                        setLoading(false);
                        setSaving(false);
                      })
                      .catch((error) => {
                        setGetAlert(error.response.data.message);
                        if (error.response.status === 470)
                          setAlertErrName(true);
                        else setAlertErr(true);

                        setLoading(false);
                        setSaving(false);
                        setPopoverOpen(false);
                      });
                  }
                }

                if (radioValue === 'MENU') {
                  const items = [];
                  dataTable.data.forEach((item) => {
                    items.push({
                      productId: item.id,
                      quantity: item.quantity,
                      cost: item.cost,
                      unitsMeasurementsId: item.consumptionUnitsPrimaryId
                    });
                  });

                  const card = {
                    user: userInfo.user,
                    groups,
                    type: radioValue,
                    draft,
                    status: active,
                    lowType,
                    itemsMenu: buildItemsMenu(),
                    description: description.trim(),
                    courtId: courtSelected?.id || null,
                    lossPercentage: percentAccepted?.loss || 0,
                    variationPercentage: percentAccepted?.variation,
                    unitsMeasurementsMenu: {
                      unitsMeasurementsId: ucPrimarySelectedId?.id,
                      quantity: parseFloat(production.yieldTotal)
                    },
                    restaurantId: isGroupManage ? userInfo.companiesActive.id[0] : userInfo.companiesActive.id,
                    resale: resales,
                    categoryId: treeView,
                    cost: [
                      {
                        discountPrice: 0,
                        averagePrice: 0,
                        costTotal,
                        costUnit: costTotal
                      }
                    ],
                    sales: [sales],
                    production: {
                      yield: parseFloat(production.yieldTotal),
                      yieldTotal: parseFloat(production.yieldTotal),
                      loss: 0
                    },
                    stock: [
                      {
                        stockTotal: 0
                      }
                    ],
                    items,
                    averagePriceSimulation: averagePriceSimulation
                      ? parseFloat(
                          parseFloat(averagePriceSimulation).toFixed(3)
                        )
                      : null,
                    markupSimulation: markupSimulation
                      ? parseFloat(parseFloat(markupSimulation).toFixed(3))
                      : null,
                    cmvSimulation: cmvSimulation
                      ? parseFloat(parseFloat(cmvSimulation).toFixed(3))
                      : null
                  };
                  axios
                    .post(`${environments.catalog}/cards`, card, config)
                    .then((res) => {
                      setToastProps({
                        severity: 'success',
                        message: 'Cadastro realizado com sucesso!',
                        timeOutOff: false
                      });
                      setToastOpen(true);
                      setLoading(true);
                      setSaving(true);
                    })
                    .catch((error) => {
                      setGetAlert(error.response.data.message);
                      setLoading(false);
                      setSaving(false);
                      setAlertErr(true);
                      setPopoverOpen(false);
                    });
                }
              } else {
                setGetAlert('Registration of at least 1 product is mandatory');
                setAlertErrSkus(true);
                setLoading(false);
                setSaving(false);
              }
            }
          }, 100);
    }
  };

  const renderHeaderOptions = () => {
    if (userGroups.groups.map((group) => group.hasOwnProperty('id'))[0]) {
      return (
        <>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={userGroups?.groups}
              disableCloseOnSelect
              size="small"
              value={restaurantGroups}
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_, value) => {
                if (value) {
                  setRestaurantGroups(value);
                  setRestaurantSubGroups(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                  );
                  setRestaurantStores(
                    value
                      .reduce((acc, group) => [...acc, group.subGroups], [])
                      .flat()
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Grupos"
                  placeholder="Grupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantGroups
                ?.reduce((acc, group) => [...acc, group.subGroups], [])
                ?.flat()}
              value={restaurantSubGroups}
              disableCloseOnSelect
              size="small"
              getOptionLabel={(option) => option.name}
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_, value) => {
                if (value) {
                  setRestaurantSubGroups(value);
                  setRestaurantStores(
                    value
                      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                      .flat()
                  );
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Subgrupos"
                  placeholder="Subgrupos"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              multiple
              className="autocompleteSmall"
              limitTags={1}
              options={restaurantSubGroups
                ?.reduce((acc, subGroup) => [...acc, subGroup.stores], [])
                ?.flat()}
              value={restaurantStores}
              disableCloseOnSelect
              size="small"
              getOptionLabel={(option) =>
                radioValue === 'RECIPE'
                  ? ValidationLength(option.name, 15)
                  : option.name
              }
              classes={{ input: classes.input, option: classes.option }}
              onChange={(_, value) => {
                if (value) {
                  setRestaurantStores(value);
                }
              }}
              renderOption={(option, { selected }) => (
                <>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Restaurantes"
                  placeholder="Restaurantes"
                  InputLabelProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                />
              )}
            />
          </Grid>
        </>
      );
    }

    return (
      <Grid item xs={12}>
        <Autocomplete
          options={userInfo?.companies?.groups[0].subGroups[0].stores}
          getOptionLabel={(option) => option.name}
          size="small"
          limitTags={1}
          className="restaurantsCustomSelect autocompleteSmall"
          value={restaurantsId}
          multiple
          onChange={(_, value) => {
            if (value) {
              setRestaurantsId(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id={params.id}
              type="text"
              name="restaurantsId"
              label="Restaurantes"
              variant="outlined"
              InputLabelProps={{
                style: { fontFamily: 'Lato', fontSize: '12px' }
              }}
            />
          )}
        />
      </Grid>
    );
  };

  // Start Time
  function handleStartDateChange(date) {
    setHasChanged(true);

    if (selectedEndTime && date > selectedEndTime) {
      setToastProps({
        severity: 'error',
        color: '',
        message: 'Por favor, informe um horário inicial abaixo do final',
        timeOutOff: false,
        autoHideDuration: 3000
      });
      setToastOpen(true);
      return;
    }

    setSelectedStartTime(date);
  }

  // End Time
  function handleEndDateChange(date) {
    setHasChanged(true);

    if (selectedStartTime && date < selectedStartTime) {
      setToastProps({
        severity: 'error',
        color: '',
        message: 'Por favor, informe um horário final acima do inicial',
        timeOutOff: false,
        autoHideDuration: 3000
      });
      setToastOpen(true);
      return;
    }

    setSelectedEndTime(date);
  }

  function ValidationLength(data, charactersCount) {
    if (data && data.length > charactersCount)
      return `${data.substring(0, charactersCount)}...`;
    return data;
  }

  // Radio
  function handleChangeRadio(event) {
    setRadioValue(event.target.value);
    event.target.value === 'MENU'
      ? setCategoryType('Sales')
      : setCategoryType('Shopping');
    setNewProductRow('');
    setNewStorageLocationRow([]);
    setNewPeriodicityRow([]);
    setUcPrimarySelectedId(null);
    setLastCountObj([]);
    setTreeView('');
    setDraft(false);
    setActive('ACTIVE');
    setResales(false);
    setControlProduction(false);
    setLowType("NORMAL");
    setItemsMenu('')
    setPriority(false);
    setProductionTime(false);
    setStorageLocation([]);
    setPeriodicities([]);
    setSelectedStartTime(null);
    setSelectedEndTime(null);
    setDaysSelect([]);
    setValidade('');
    setCostTotal(0);
    setDescription('');
    setAveragePriceSimulation(0);
    setMarkupSimulation(0);
    setCmvSimulation(0);
    setValueLoss(0);
    setProduction({
      yield: null,
      yieldTotal: null
    });
    setProductionMin('');
    setDataTable({ data: [] });
    setTransformation({
      type: 'product',
      productId: null,
      stock: 0,
      consumptionUnitsPrimaryId: null,
      consumptionUnitsPrimary: null,
      cost: 0,
      averageCost: 0,
      lastCost: 0,
      quantity: 0
    });
    setCost({
      discountPrice: 0,
      averagePrice: 0,
      costTotal: 0,
      costUnit: 0
    });
    setSales(0);
  }

  const onChangeProductImage = (e) => {
    if (!e?.target?.files?.length) return;

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setHasChanged(true);
    const reader = new FileReader();

    reader.onload = () => {
      setDataTable({
        ...dataTable,
        data: dataTable.data.map((item, index) => {
          if (index === currentProduct?.index) {
            return {
              ...item,
              images: {
                url: reader.result,
                baseImage: reader.result,
                name: files?.length && files[0]?.name
              }
            };
          }
          return {
            ...item
          };
        })
      });

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'imageCropper'
            ? { ...modal, open: true, currentSkuIndex: currentProduct?.index }
            : modal
        )
      );
    };
    reader.readAsDataURL(files?.length && files[0]);
  };

  const onChangeImage = (e) => {
    if (!e?.target?.files?.length) return;

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    setHasChanged(true);
    const reader = new FileReader();

    reader.onload = () => {
      setImageData({
        ...imageData,
        data: {
          ...imageData.data,
          images: {
            url: reader.result,
            baseImage: reader.result,
            name: files?.length && files[0]?.name
          }
        }
      });

      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'imageCropper'
            ? { ...modal, open: true, currentSkuIndex: menuId }
            : modal
        )
      );

      setImagePopoverOpen(false)
    };
    reader.readAsDataURL(files?.length && files[0]);
  };

  const getCropData = () => {
    if (currentProduct) {
      setDataTable((prevState) => ({
        ...prevState,
        data: dataTable?.data?.map((el) => {
          if (el.id === currentProduct?.id) {
            return {
              ...el,
              images: {
                ...el?.images,
                baseImage: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9),
                url: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
              }
            }
          }
          return { ...el}
        })
      }))
      setCurrentProduct({
        ...currentProduct,
        images: {
          baseImage: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9),
          url: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
        }
      })
    } else {
      setImageData({
        ...imageData,
        data: {
          ...imageData?.data,
          images: {
            ...imageData?.data?.images,
            baseImage: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9),
            url: cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9)
          }
        }
      });
    }

    setImagePopoverOpen(false)
  };

  const handleSelectImage = (_e, index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).click();
    } else {
      document.querySelector(`#fileInput`).click();
    }
  };

  const handleClearFileInput = (index) => {
    if (index || index === 0) {
      document.querySelector(`#fileInput${index}`).value = null;
    } else {
      document.querySelector(`#fileInput`).value = null;
    }
  };

  const handleRemoveImage = () => {
    setImageData({
      ...imageData,
      data: {
        ...imageData.data,
        images: null
      }
    });
  };

  const form = () => (
    <Container className="cardsMainContainer" maxWidth="lg">
      {alertErr ? (
        <CommonAlert
          open={alertErr}
          onClose={onDismiss}
          indexMessage={getAlert}
        />
      ) : null}
      {alertErrSkus ? (
        <CommonAlert
          open={alertErrSkus}
          onClose={onDismiss}
          indexMessage={getAlert}
        />
      ) : null}
      {alertErrName ? (
        <CommonAlert
          open={alertErrName}
          onClose={onDismiss}
          indexMessage={
            getAlert || 'Já existe uma ficha cadastrada com esse nome'
          }
        />
      ) : null}

      {messagePersonal ? (
        <CommonAlert
          open={messagePersonal}
          onClose={() => {
            setTimeout(() => {
              setMessagePersonal('')
            }, 2000)
          }}
          messagePersonal={messagePersonal}
          autoHideDuration={6000}
        />
      ) : null}


      <FullScreenLoading display={saving} description="Salvando" />

      <div className="dFlex alignStart mb10">
        <Grid
          container
          style={{
            paddingTop: '5px',
            paddingBottom: '5px',
            width: radioValue === 'RECIPE' ? '82%' : '100%'
          }}
          className={`cardDefault withoutInputs ${
            !radioValue || (radioValue !== 'MENU' && radioValue !== 'RECIPE')
              ? ''
              : 'mr10'
          }`}
        >
          <Grid container spacing={5} justifyContent="space-between">
            <Grid item xs={5} sm={5} className="pt0 pb0">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={radioValue}
                  onChange={handleChangeRadio}
                  style={{
                    flexDirection: 'row'
                  }}
                >
                  <FormControlLabel
                    value="RECIPE"
                    disabled={editMode}
                    control={
                      <Radio
                        id="cardFormReceitasformRadio"
                        required
                        size="small"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#333541'
                        }}
                      >
                        Receita
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="TRANSFORMATION"
                    disabled={editMode}
                    control={
                      <Radio
                        id="cardFormTransformRadio"
                        required
                        size="small"
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#333541'
                        }}
                      >
                        Transformação
                      </span>
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {radioValue !== 'TRANSFORMATION' &&
            radioValue !== 'RECIPE' &&
            radioValue !== 'MENU' ? null : (
              <div
                style={{
                  display: 'flex',
                  paddingRight: '50px',
                  justifyContent: 'end',
                  gap: '85px'
                }}
              >
                <Grid item xs={1} sm={1} className="pt0">
                  <FormControlLabel
                    className="toggleDefault m0 customToggle"
                    control={
                      <Switch
                        onChange={() => {
                          draft ? setDraft(false) : setDraft(true);
                          setHasChanged(true);
                        }}
                        name="draft"
                        checked={draft}
                        inputRef={register}
                      />
                    }
                    label="Rascunho"
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  className="pt0"
                  style={{ marginRight: '20px' }}
                >
                  <FormControlLabel
                    className="toggleDefault m0 customToggle"
                    control={
                      <Switch
                        onChange={(_, checked) => {
                          setActive(checked ? 'ACTIVE' : 'INACTIVE');
                          setHasChanged(true);
                        }}
                        name="active"
                        checked={active === 'ACTIVE'}
                      />
                    }
                    label="Ativa"
                  />
                </Grid>
              </div>
            )}
          </Grid>
          {radioValue !== 'TRANSFORMATION' &&
          radioValue !== 'RECIPE' &&
          radioValue !== 'MENU' ? null : (
            <Grid container spacing={5} className="mb20 dNone">
              {renderHeaderOptions()}
            </Grid>
          )}

          <Grid container spacing={5} justifyContent="space-between">
            {radioValue !== 'TRANSFORMATION' &&
            radioValue !== 'RECIPE' &&
            radioValue !== 'MENU' ? null : (
              <Grid
                item
                xs={6}
                sm={6}
                className="pr8"
                style={
                  radioValue !== 'TRANSFORMATION'
                    ? { maxWidth: '34%', minWidth: '34%' }
                    : { maxWidth: '28%', minWidth: '28%' }
                }
              >
                <TextField
                  onChange={(e) => {
                    setHasChanged(true);

                    setDescription(e.target.value);
                    const newArr = [...lastCountObj];

                    if (radioValue === 'RECIPE' && e.target.value) {
                      newArr[0] = {
                        description: e.target.value,
                        un: newArr[0] ? newArr[0].un : {}
                      };
                    } else {
                      newArr[0] = {
                        description: '',
                        un: newArr[0] ? newArr[0].un : {}
                      };
                    }

                    radioValue === 'RECIPE' && setLastCountObj(newArr);
                  }}
                  type="text"
                  name="description"
                  variant="outlined"
                  required
                  fullWidth
                  autoFocus
                  size="small"
                  value={description}
                  label="Descrição"
                  className={`textFieldSmall ${
                    requiredFields?.includes('description') && 'labelError'
                  } commonInput`}
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            )}

            {radioValue !== 'TRANSFORMATION' &&
            radioValue !== 'RECIPE' &&
            radioValue !== 'MENU' ? null : (
              <>
                {radioValue !== 'RECIPE' ? null : (
                  <>
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      id="cardFormTransformUnityInput"
                      className="pr8 pl8"
                      style={
                        radioValue !== 'TRANSFORMATION'
                          ? { maxWidth: '7%', minWidth: '7%' }
                          : { maxWidth: '5%', minWidth: '5%' }
                      }
                    >
                      <Autocomplete
                        id="asynchronous-demo"
                        getOptionSelected={(option, value) =>
                          option.abbreviation === value.abbreviation
                        }
                        getOptionLabel={(option) => option.abbreviation}
                        options={unitsConsumptions || []}
                        fullWidth
                        value={ucPrimarySelectedId}
                        className={`autocompleteSmall ${
                          requiredFields?.includes('ucPrimarySelectedId') &&
                          'labelError'
                        } commonInput`}
                        size="small"
                        onChange={(_, value) => {
                          setUcPrimarySelectedId(value);
                          setHasChanged(true);
                          const newArr = [...lastCountObj];

                          if (radioValue === 'RECIPE' && value) {
                            newArr[0] = {
                              description: newArr[0] ? newArr[0].description : '',
                              un: value
                            };
                          } else {
                            newArr[0] = {
                              description: newArr[0] ? newArr[0].description : '',
                              un: {}
                            };
                          }

                          radioValue === 'RECIPE' && setLastCountObj(newArr);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            label="Un."
                            required
                            error={errors.units}
                            variant="outlined"
                            placeholder="Un."
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.abbreviation}
                          </Typography>
                        )}
                      />
                    </Grid>
                    
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      className="pr8 pl8"
                      style={{ maxWidth: '10%', minWidth: '10%' }}
                    >
                      <FormControl fullWidth>
                        <CurrencyTextField
                          variant="outlined"
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          required
                          style={
                            radioValue !== 'RECIPE' ? { display: 'none' } : null
                          }
                          type="text"
                          name="rendimento"
                          fullWidth
                          value={production.yieldTotal}
                          InputProps={{
                            maxLength: 8,
                            classes: { input: classes.input },
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                          label="Rendimento"
                          className={`textFieldSmall right ${
                            requiredFields?.includes('yieldTotal') && 'labelError'
                          } commonInput`}
                          placeholder="Digite aqui..."
                          onBlur={(_, value) => {
                            setProduction({
                              yield: value,
                              yieldTotal: value
                            });
                            setHasChanged(true);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                {radioValue !== 'TRANSFORMATION' &&
                radioValue !== 'RECIPE' ? null : (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    className="pr8 pl8"
                    style={{ maxWidth: '10%', minWidth: '10%' }}
                  >
                    <FormControl fullWidth>
                        <CurrencyTextField
                          variant="outlined"
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnFocus={3}
                          value={productionMin}
                          type="text"
                          name="producaomin"
                          fullWidth
                          label="Qtde Mínima"
                          className="textFieldSmall right commonInput"
                          placeholder="Digite aqui..."
                          InputProps={{
                            maxLength: 8,
                            classes: { input: classes.input },
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                          onBlur={(_, value) => {
                            setProductionMin(value);
                            setHasChanged(true);
                          }}
                        />
                    </FormControl>
                  </Grid>
                )}

                {radioValue !== 'TRANSFORMATION' &&
                radioValue !== 'RECIPE' ? null : (
                  <>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      id="cardFormTransformValidate"
                      className="pl8 pr8"
                      style={{ maxWidth: '10%', minWidth: '10%' }}
                    >
                      <TextField
                        size="small"
                        label="Validade"
                        name="validade"
                        onChange={(e) => {
                          setValidade(e.target.value);
                          setHasChanged(true);
                        }}
                        variant="outlined"
                        value={validade}
                        className={`textFieldSmall right ${
                          requiredFields?.includes('validity') && 'labelError'
                        } commonInput labelRight`}
                        placeholder="Digite aqui..."
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          maxLength: 3,
                          classes: { input: classes.input }
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ''
                          );
                        }}
                      />
                    </Grid>
                    
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      id="CardFormCategorieInput"
                      style={
                        radioValue === 'TRANSFORMATION'
                          ? { maxWidth: '15%', minWidth: '15%', marginTop: "1px" }
                          : { maxWidth: '26%', minWidth: '26%', marginTop: "1px" }
                      }
                    >
                      <FormControl
                        fullWidth
                        error={Boolean(errors.categories)}
                        className={`selectSmallBox ${
                          requiredFields?.includes('category') && 'labelError'
                        }`}
                      >
                        <label>Categoria *</label>

                        <TreeSelect
                          suffixIcon={
                            <svg
                              className="MuiSvgIcon-root pAbsolute"
                              style={{ right: 0, bottom: '-7px' }}
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M7 10l5 5 5-5z" />
                            </svg>
                          }
                          name="categories"
                          value={treeView}
                          size="small"
                          className="selectSmall commonInput customCategorySelector"
                          style={{ width: '100%', marginTop: 25 }}
                          allowClear
                          showHitDescendants
                          mode={2}
                          onChange={(_, value) => {
                            setTreeView(value?.id);
                            setCategoriesError(false);
                            setHasChanged(true);
                          }}
                          onSelect={(_, value) => {
                            setTreeView(value?.id);
                            setCategoriesError(false);
                            setHasChanged(true);
                          }}
                          placeholder="Selecione..."
                          keygen="id"
                          clearable
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={categories}
                          showSearch
                          filterTreeNode={(search, item) =>
                            item?.title
                              ?.toLowerCase()
                              ?.normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              ?.indexOf(
                                search
                                  ?.toLowerCase()
                                  ?.normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                              ) >= 0
                          }
                          treeDefaultExpandAll
                        />
                      </FormControl>
                    </Grid>

                    {radioValue !== 'TRANSFORMATION' ? null : (
                      <>
                        <Grid
                          item
                          xs={4}
                          sm={4}
                          className="pr8 pl8 selectSmallBox"
                          style={{ maxWidth: '15%', minWidth: '15%', marginTop: "3px", paddingTop: "43px" }}
                        >
                          <label style={{ top: 48 }}>Praça *</label>
                          
                          <TreeSelect
                            suffixIcon={
                              <svg
                                className="MuiSvgIcon-root pAbsolute"
                                style={{ right: 0, bottom: '-7px' }}
                                focusable="false"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path d="M7 10l5 5 5-5z" />
                              </svg>
                            }
                            name="courts"
                            value={courtSelected?.description}
                            size="small"
                            className="selectSmall commonInput customCategorySelector"
                            style={{ width: '100%' }}
                            allowClear
                            showHitDescendants
                            mode={2}
                            onChange={(_, court) => {
                              setCourtSelected(court);
                              setHasChanged(true);
                            }}
                            onSelect={(_, court) => {
                              setCourtSelected(court);
                              setHasChanged(true);
                            }}
                            placeholder="Selecione..."
                            keygen="id"
                            clearable
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={courts}
                            showSearch
                            filterTreeNode={(search, item) =>
                              item?.title
                                ?.toLowerCase()
                                ?.normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                ?.indexOf(
                                  search
                                    ?.toLowerCase()
                                    ?.normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                                ) >= 0
                            }
                            treeDefaultExpandAll
                          />
                        </Grid>
                        
                        <Grid
                          item
                          xs={3}
                          sm={3}
                          id="CardFormCategorieInput"
                          className="pr8"
                          style={{ maxWidth: '12%', minWidth: '12%' }}
                        >
                          <FormControl fullWidth>
                            <CurrencyTextField
                              variant="outlined"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              type="text"
                              name="percentVariationAccepted"
                              fullWidth
                              value={percentAccepted?.variation}
                              InputProps={{
                                maxLength: 8,
                                classes: { input: classes.input },
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                              label="% Variação
                        Produção"
                              className={`textFieldSmall right ${
                                requiredFields?.includes('percentAccepted') &&
                                'labelError'
                              } commonInput`}
                              placeholder="Digite aqui..."
                              onBlur={(_, value) => {
                                setHasChanged(true);
                                setPercentAccepted({
                                  ...percentAccepted,
                                  variation: value !== '' ? value : null
                                });
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {radioValue !== 'MENU' ? null : (
              <Grid
                item
                xs={2}
                sm={2}
                className="pr8 pl8"
                style={{ maxWidth: '14%', minWidth: '14%' }}
              >
                <FormControl fullWidth>
                  <CurrencyTextField
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="quantity"
                    required
                    fullWidth
                    value={production.yieldTotal}
                    label="Qtd"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{
                      maxLength: 8,
                      classes: { input: classes.input }
                    }}
                    onBlur={(_, value) => {
                      setProduction({
                        yield: value,
                        yieldTotal: value
                      });
                    }}
                  />
                </FormControl>
              </Grid>
            )}

            {/* {radioValue !== 'TRANSFORMATION' &&
            radioValue !== 'RECIPE' ? null : (
              <Grid
                item
                xs={2}
                sm={2}
                className="pr8 pl8"
                style={{ maxWidth: '14%', minWidth: '14%' }}
              >
                <FormControl fullWidth>
                  <CurrencyTextField
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    value={productionMin}
                    type="text"
                    name="producaomin"
                    fullWidth
                    label="Qtde Mínima"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputProps={{
                      maxLength: 8,
                      classes: { input: classes.input },
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    onBlur={(_, value) => {
                      setProductionMin(value);
                      setHasChanged(true);
                    }}
                  />
                </FormControl>
              </Grid>
            )} */}

            {radioValue !== 'MENU' ? null : (
              <Grid item xs={2} sm={2}>
                <Checkbox
                  name="resales"
                  checked={resales}
                  onChange={() =>
                    resales ? setResales(false) : setResales(true)
                  }
                  size="small"
                />{' '}
                <span style={{ fontFamily: 'Lato', fontSize: '12px' }}>
                  Revenda
                </span>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={5} justifyContent="space-between">
            {radioValue !== 'RECIPE' ? null : (
              <Grid
                item
                xs={4}
                sm={4}
                className="pr8 pl0 selectSmallBox pRelative"
                style={{ maxWidth: '22%', minWidth: '22%', marginTop: "3px", paddingTop: "43px" }}
              >
                <label style={{ top: 0, left: 0 }}>Praça *</label>
                
                <TreeSelect
                  suffixIcon={
                    <svg
                      className="MuiSvgIcon-root pAbsolute"
                      style={{ right: 0, bottom: '-7px' }}
                      focusable="false"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M7 10l5 5 5-5z" />
                    </svg>
                  }
                  name="courts"
                  value={courtSelected?.description}
                  size="small"
                  className="selectSmall commonInput customCategorySelector"
                  style={{ width: '100%' }}
                  allowClear
                  showHitDescendants
                  mode={2}
                  onChange={(_, court) => {
                    setCourtSelected(court);
                    setHasChanged(true);
                  }}
                  onSelect={(_, court) => {
                    setCourtSelected(court);
                    setHasChanged(true);
                  }}
                  placeholder="Selecione..."
                  keygen="id"
                  clearable
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={courts}
                  showSearch
                  filterTreeNode={(search, item) =>
                    item?.title
                      ?.toLowerCase()
                      ?.normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, '')
                      ?.indexOf(
                        search
                          ?.toLowerCase()
                          ?.normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      ) >= 0
                  }
                  treeDefaultExpandAll
                />
              </Grid>
            )}
            
            {radioValue !== 'RECIPE' ? null : (
              <Grid
                item
                xs={4}
                sm={4}
                className="pr8 pl8"
                style={{ maxWidth: '22%', minWidth: '22%' }}
              >
                <Autocomplete
                  id="controllable-states-demo"
                  getOptionLabel={(option) => option.description}
                  classes={{
                    input: classes.input,
                    option: classes.option
                  }}
                  options={storageLocationOptions || []}
                  multiple
                  limitTags={1}
                  size="small"
                  value={[...new Set(storageLocation)]}
                  onChange={(_, storageLocation) => {
                    setHasChanged(true);
                    setStorageLocation(storageLocation);
                    setLocalStorageError(false);
                    const newArr = [...lastCountObj];

                    if (radioValue === 'RECIPE' && storageLocation.length) {
                      newArr[0] = {
                        description: newArr[0] ? newArr[0].description : '',
                        un: newArr[0] ? newArr[0].un : {},
                        storageLocations: storageLocation.map(
                          (element) => element.id
                        ),
                        periodicities: newArr[0] ? newArr[0].periodicities : []
                      };
                    } else {
                      newArr[0] = {
                        description: newArr[0] ? newArr[0].description : '',
                        un: newArr[0] ? newArr[0].un : {},
                        storageLocations: [],
                        periodicities: newArr[0] ? newArr[0].periodicities : []
                      };
                    }

                    radioValue === 'RECIPE' && setLastCountObj(newArr);
                  }}
                  fullWidth
                  className="autocompleteSmall commonInput"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      className="locaisDeArmazenamento"
                      required
                      name="providers"
                      label="Locais de Estoque"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        id: 'locaisDeArmazenamento',
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />

                <Tooltip
                  title="Preencha este campo."
                  placement="bottom-start"
                  open={Boolean(localStorageError)}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <span width="1px" />
                </Tooltip>
              </Grid>
            )}

            {radioValue !== 'RECIPE' ? null : (
              <Grid
                item
                xs={3}
                sm={3}
                className="pr8 pl8"
                style={{ maxWidth: '22%', minWidth: '22%' }}
              >
                <Autocomplete
                  id="controllable-states-demo"
                  getOptionSelected={(option, value) =>
                    option.description === value.description
                  }
                  getOptionLabel={(option) => option?.description}
                  options={periodicityOptions || []}
                  multiple
                  limitTags={1}
                  className="autocompleteSmall commonInput"
                  size="small"
                  value={[...new Set(periodicities)]}
                  onChange={(_, periodicity) => {
                    setHasChanged(true);
                    setPeriodicities(periodicity);
                    setPeriodicityError(false);
                    const newArr = [...lastCountObj];

                    if (radioValue === 'RECIPE' && periodicity.length) {
                      newArr[0] = {
                        description: newArr[0] ? newArr[0].description : '',
                        un: newArr[0] ? newArr[0].un : {},
                        storageLocations: newArr[0]
                          ? newArr[0].storageLocations
                          : [],
                        periodicities: periodicity.map((element) => element.id)
                      };
                    } else {
                      newArr[0] = {
                        description: newArr[0] ? newArr[0].description : '',
                        un: newArr[0] ? newArr[0].un : {},
                        storageLocations: newArr[0]
                          ? newArr[0].storageLocations
                          : [],
                        periodicities: []
                      };
                    }

                    radioValue === 'RECIPE' && setLastCountObj(newArr);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      required
                      name="providers"
                      label="Contagem"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />

                <Tooltip
                  title="Preencha este campo."
                  placement="bottom-start"
                  open={Boolean(periodicityError)}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                >
                  <span width="1px" />
                </Tooltip>
              </Grid>
            )}

            {radioValue !== 'RECIPE' ? null : (
              <>
                <Tooltip
                  title={
                  <span style={{ padding: '5px'}}>
                    % de Perda aceita na Contagem
                  </span>
                  }
                  placement="right-start"
                  arrow
                >
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    id="cardFormTransformUnityInput"
                    className={`${radioValue !== 'RECIPE' && 'dNone'} pl8`}
                    style={{ maxWidth: '14%', minWidth: '14%' }}
                  >
                    <FormControl fullWidth>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="percentLossAccepted"
                        fullWidth
                        value={percentAccepted?.loss}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input },
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        required
                        label="% Perda
                        Contagem"
                        className={`textFieldSmall right ${
                          requiredFields?.includes('yieldTotal') && 'false'
                        } commonInput minorInput`}
                        placeholder="Digite aqui..."
                        onBlur={(_, value) => {
                          setHasChanged(true);
                          setPercentAccepted({
                            ...percentAccepted,
                            loss: value
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Tooltip>

                <Tooltip
                  title={<span style={{ padding: '5px' }}>
                    % de Variação aceita na Produção
                  </span>}
                  placement="right-start"
                  arrow
                >
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    id="cardFormTransformUnityInput"
                    className="pl8"
                    style={{ maxWidth: '14%', minWidth: '14%', paddingRight: '20px' }}
                  >
                    <FormControl fullWidth>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="percentVariationAccepted"
                        fullWidth
                        value={percentAccepted?.variation}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input },
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        label="% Variação
                        Produção"
                        className={`textFieldSmall right ${requiredFields?.includes('percentAccepted') && 'labelError'
                          } commonInput minorInput`}
                        placeholder="Digite aqui..."
                        onBlur={(_, value) => {
                          setHasChanged(true);
                          setPercentAccepted({
                            ...percentAccepted,
                            variation: value !== '' ? value : null
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Tooltip>
              </>
            )}

            {radioValue !== 'MENU' ? null : (
              <>
                <Grid item xs={2}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={display}
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="averagePrice"
                    label="Preço Médio"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{ classes: { input: classes.input } }}
                    value={salesAverage}
                    disabled
                  />
                </Grid>

                <Grid item xs={2}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={display}
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="markup"
                    label="Markup"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{ classes: { input: classes.input } }}
                    value={markup}
                    disabled
                  />
                </Grid>

                <Grid item xs={2}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={display}
                    currencySymbol="%"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="cmv"
                    label="CMV"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{ classes: { input: classes.input } }}
                    value={cmv}
                    disabled
                  />
                </Grid>

                <Grid item xs={2}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    style={display}
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="margin"
                    label="Margem"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputProps={{ classes: { input: classes.input } }}
                    value={margin}
                    disabled
                  />
                </Grid>
              </>
            )}
          </Grid>

          {radioValue !== 'TRANSFORMATION' && radioValue !== 'RECIPE' ? null : (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} className="dFlex alignCenter">
                <Checkbox
                  className="checkBoxSmall"
                  name="controlProduction"
                  checked={controlProduction}
                  onChange={() => {
                    controlProduction
                      ? setControlProduction(false)
                      : setControlProduction(true);
                    setHasChanged(true);
                  }}
                  size="small"
                />{' '}
                <span className="fontSizeDefault" style={{ marginBottom: 2 }}>
                  Controlar Produção
                </span>

                {storeSegment === 'Restaurante/Kg' ? (
                  <>
                    <Checkbox
                      className="checkBoxSmall ml20"
                      name="lowType"
                      checked={lowType === "AUTOMATIC"}
                      onChange={(_, checked) => {
                        if (!checked) {
                          setItemsMenu('')
                          setItemsMenuFormatted({
                            added: [],
                            deleted: [itemsMenu],
                            noChanged: []
                          })
                        }
                        setLowType(checked ? "AUTOMATIC" : "NORMAL")
                        setHasChanged(true);
                      }}
                      size="small"
                    />{' '}

                    <span className="fontSizeDefault" style={{ marginBottom: 2 }}>
                      Venda por kg
                    </span>
                  </>
                ) : null}

                <Checkbox
                  className="checkBoxSmall ml20"
                  name="priority"
                  checked={priority}
                  onChange={() => {
                    priority ? setPriority(false) : setPriority(true);
                    setHasChanged(true);
                  }}
                  size="small"
                />{' '}
                <span className="fontSizeDefault" style={{ marginBottom: 2 }}>
                  Prioritário
                </span>

                <Checkbox
                  className="checkBoxSmall ml20"
                  name="productionTime"
                  checked={productionTime}
                  onChange={() => {
                    productionTime
                      ? setProductionTime(false)
                      : setProductionTime(true);
                    setHasChanged(true);
                  }}
                  size="small"
                />{' '}
                <span className="fontSizeDefault" style={{ marginBottom: 2 }}>
                  Definir agenda
                </span>

                <div className="dFlex ml20 alignCenter">
                  <span className="bold fontSizeDefault">Modo de Preparo</span>

                  <a
                    className={!preparationMethod?.url && 'disabledLink'}
                    download={preparationMethod?.name || 'pdf_file'}
                    href={preparationMethod?.url}
                    title={preparationMethod?.name || 'Download pdf document'}
                  >
                    <img
                      style={{
                        margin: '0 10px',
                        width: '18px'
                      }}
                      src={preparationMethod?.url ? ListIconBlue : ListIcon}
                      alt={
                        preparationMethod?.url ? 'Pdf loaded' : 'Not pdf loaded'
                      }
                    />
                  </a>

                  {preparationMethod?.url && (
                    <img
                      style={{
                        margin: '0px 10px 0px 0px',
                        width: '12px'
                      }}
                      title="Remover modo de preparo"
                      className="cursorPointer"
                      src={CloseGrayIcon}
                      alt="CloseGrayIcon"
                      onClick={() => {
                        setHasChanged(true);
                        setPreparationMethod();
                      }}
                    />
                  )}

                  <MaterialButton
                    onClick={() => handleClickFakeImportPdf()}
                    className="defaultButton backButton fontSizeDefault pr20 pl20 pt3 pb3 personal"
                  >
                    <InputFile
                      type="file"
                      title="Enviar"
                      id="pdfImport"
                      name="pdfImport"
                      onChange={(event) => uploadPdf(inputRef, event)}
                      accept="application/pdf"
                      ref={inputRef}
                      aria-label="upload"
                      className="dNone"
                    />

                    <span>Enviar</span>
                  </MaterialButton>
                </div>
              </Grid>
            </Grid>
          )}

          {(radioValue !== 'TRANSFORMATION' && radioValue !== 'RECIPE') ? null : (
            <Grid container spacing={5}>
              {storeSegment === 'Restaurante/Kg' ? (
                <Grid className={`${(lowType === "NORMAL") && 'dNone'} pt0`} item xs={3} sm={3} style={{ maxWidth: '239px', marginLeft: '155px' }}>
                  <Autocomplete
                    onFocus={() => {
                      if (menuOptions?.length) return null;

                      getOptions(
                        `${environments.catalog}/menu`,
                        'menuOptions',
                        menuOptions,
                        setMenuOptions,
                        paramsID,
                        { autoComplete: true }
                      )
                    }}
                    id="controllable-states-demo"
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.description}
                    options={menuOptions}
                    // multiple
                    // limitTags={1}
                    size="small"
                    value={itemsMenu}
                    className={`autocompleteSmall ${lowType === "NORMAL" && 'dNone'} ${requiredFields?.includes('itemsMenu') && 'labelError'}`}
                    onChange={(_, value) => {
                      if (value && !value?.lowAutomatic) {
                        setModalSettings(
                          modalSettings.map((modal) =>
                            modal.name === 'confirmItemsMenuDialog'
                              ? {
                                  ...modal,
                                  open: true,
                                  currentItem: value,
                                }
                              : modal
                          )
                        );
                        
                        setOpenModalMenu(true)
                        setMenuId(value?.id)
                        setSalesAverageDiscount(
                          value?.salesAverageDiscount
                        );
                        setPercentageDiscountValue(
                          value?.percentageDiscount
                        );
                        return null;
                      }
                      
                      setHasChanged(true);
                      setItemsMenuFormatted({
                        added: [value],
                        deleted: [itemsMenu],
                        noChanged: []
                      })
                      setItemsMenu(value)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        name="providers"
                        variant="outlined"
                        placeholder="Cardápio..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>
              ) : null}

              <Grid className={`${!productionTime && 'dNone'}`} item xs={3} sm={3} style={{ marginLeft: lowType === "AUTOMATIC" ? '17px' : '411px' }}>
                <Autocomplete
                  id="controllable-states-demo"
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.description}
                  options={formattedDaysOptions}
                  multiple
                  limitTags={1}
                  size="small"
                  value={daysSelect}
                  className="autocompleteSmall commonInput personalInputDaily"
                  onChange={(_, value) => {
                    setHasChanged(true);
                    if (value.find((item) => item.description === 'Todos')) {
                      setDaysSelect(
                        formattedDaysOptions.filter(
                          (item) => item.description !== 'Todos'
                        )
                      );
                    } else {
                      setDaysSelect(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      name="providers"
                      label="Dias da semana"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{ fontSize: '12px', fontFamily: 'Lato' }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={1} sm={1} className={`personalDatePicker ${!productionTime && 'dNone'}`}>
                  <KeyboardTimePicker
                    size="small"
                    id="time-picker"
                    label="Início"
                    ampm={false}
                    clearable
                    placeholder="00:00"
                    value={selectedStartTime}
                    inputVariant="outlined"
                    className="dateSmall commonInput"
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>

                <Grid item xs={1} sm={1} className={`pr20 personalDatePicker ${!productionTime && 'dNone'}`}>
                  <KeyboardTimePicker
                    size="small"
                    id="time-picker"
                    label="Fim"
                    ampm={false}
                    clearable
                    value={selectedEndTime}
                    inputVariant="outlined"
                    className="dateSmall"
                    placeholder="00:00"
                    InputProps={{
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time'
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
          )}
        </Grid>

        {!radioValue ||
        (radioValue !== 'MENU' && radioValue !== 'RECIPE') ? null : (
          <Grid container style={{ width: '17%' }}>
            <div className="cardDefault productRightCard pt16 w100">
              <Grid container spacing={1}>
                <Grid item xs={6} style={{ borderBottom: 'none' }} />

                <Grid item xs={6} style={{ borderBottom: 'none' }}>
                  <label className="blue" style={{ margin: 'auto' }}>
                    Simulação
                  </label>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label>Preço Médio</label>
                </Grid>

                <Grid item xs={6}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    currencySymbol="R$"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="averagePriceSimulation"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputProps={{ classes: { input: classes.input } }}
                    onBlur={(e) => {
                      const avgPrice =
                        e.target.value != '' &&
                        parseFloat(e.target.value.replace(',', '.')) != 0
                          ? parseFloat(e.target.value.replace(',', '.'))
                          : 0;
                      setAveragePriceSimulation(avgPrice);

                      if (costTotal && avgPrice) {
                        calcMarkupSimulation(
                          parseFloat(e.target.value),
                          costTotal,
                          setMarkupSimulation
                        );
                        calcCmvSimulation(
                          parseFloat(e.target.value),
                          costTotal,
                          setCmvSimulation
                        );
                      } else {
                        setMarkupSimulation(0);
                        setCmvSimulation(0);
                      }
                    }}
                    defaultValue={averagePriceSimulation}
                    disabled={false}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label>Markup</label>
                </Grid>

                <Grid item xs={6}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    currencySymbol=""
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="markupSimulation"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputProps={{
                      maxLength: 50,
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    value={markupSimulation}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <label>CMV</label>
                </Grid>

                <Grid item xs={6}>
                  <CurrencyTextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    currencySymbol="%"
                    minimumValue="0"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={3}
                    decimalPlacesShownOnFocus={3}
                    type="text"
                    name="cmvSimulation"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputProps={{
                      maxLength: 50,
                      style: { fontFamily: 'Lato', fontSize: '12px' }
                    }}
                    value={cmvSimulation}
                    disabled
                  />
                </Grid>
              </Grid>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="cardDefault productRightCard widthImageBox mt10">
                  <div className={`cropDataMediumBox `}>
                    <Tooltip
                      arrow
                      placement="right"
                      disableHoverListener={!imageData?.data?.images?.url}
                      title={
                        <div
                          className={`cropDataSmallBox skuImgPreview mr0 ${
                            imageData?.data?.images?.url && 'bgWhite'
                          } largeSize`}
                        >
                          <img src={imageData?.data?.images?.url} alt="Card" />
                        </div>
                      }
                    >
                      <div
                        className={`cropDataMediumBox ${
                          imageData?.data?.images?.url && 'bgWhite'
                        }`}
                      >
                        <img src={imageData?.data?.images?.url || DefaultImage} alt={`${imageData?.data?.images?.url ? 'Card Image' : 'No Menu Image'}`} />
                      </div>
                    </Tooltip>
                  </div>

                  <div className="dFlex flexColumn alignEnd mt8">
                    <div className='dFlex justifyBetween w100'>
                      <div className='dFlex alignCenter'> 
                        {imageData?.data?.images?.url &&
                          <img
                            style={{
                              margin: '0px 10px 0px 0px',
                              width: '12px'
                            }}
                            title="Remover imagem"
                            className="cursorPointer"
                            src={CloseGrayIcon}
                            alt="CloseGrayIcon"
                            onClick={() => {
                              setHasChanged(true);
                              handleClearFileInput(menuId)
                              handleRemoveImage()
                            }}
                          />
                        }
                      </div>

                      {imageData?.data?.images?.url ? (
                        <Button
                          className="defaultButton backButton sizeButtonMenu"
                          design="outlined"
                          label="Escolher outra imagem"
                          onClick={(e) => handleSelectImage(e, 'receiptMainImage')}
                          style={{ outline: 0 }}
                        />
                      ) : (
                        <Button
                          className="defaultButton submitButton sizeButtonMenu"
                          design="outlined"
                          label="Selecionar imagem"
                          onClick={(e) => handleSelectImage(e, 'receiptMainImage')}
                          style={{ outline: 0 }}
                        />
                      )}
                    </div>

                    <input
                      id={`fileInput${'receiptMainImage'}`}
                      type="file"
                      onChange={(e) => onChangeImage(e)}
                      className="dNone"
                    />
                    
                    <input
                      id="fileInput"
                      type="file"
                      onChange={onChangeImage}
                      className="dNone"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>

      {radioValue !== 'RECIPE' && radioValue !== 'MENU' ? null : (
        <Grid
          item
          xs={12}
          sm={12}
          className="materialTableDefault withoutBoxShadow selfShadow"
        >
          <table className="desktop">
            <thead className="headerTable">
              <tr className="headerTitle">
                <th width="35%" className="subtitle fontSizeDefault">
                  PRODUTO
                </th>

                <th width="20%" className="subtitle fontSizeDefault pl16">
                  PROCESSO
                </th>

                <th width="5%" className="subtitle fontSizeDefault right pl12">
                  ESTOQUE
                </th>

                <th width="5%" className="subtitle fontSizeDefault right pl12">
                  MÉDIO R$
                </th>

                <th width="5%" className="subtitle fontSizeDefault right pl12">
                  ÚLTIMO R$
                </th>

                <th width="15%" className="subtitle fontSizeDefault right pl12">
                  QTDE
                </th>

                <th width="5%" className="subtitle fontSizeDefault center pl12">
                  UN.
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault right pl12 pr20"
                >
                  CUSTO R$
                </th>

                <th
                  width="4%"
                  className="subtitle flex fontSizeDefault center"
                  style={{ borderLeft: '1px solid #D2D3D7' }}
                 />
              </tr>
            </thead>
            
            <tbody>
              {dataTable?.data?.length
                ? dataTable.data.map((product, productIndex) => (
                    <>
                      <tr className="lineBorder">
                        <td
                          className="description left pl12 dFlex alignCenter" 
                          style={{ height: '48px', color: product?.main ? '#0B1A8E' : '' }}
                        >
                          <Typography
                            onClick={() => {
                              if (product?.type === 'INTERNAL') {
                                getOptions(
                                  `${environments.catalog}/cards/${product?.originIdCard}`,
                                  'cards',
                                  selectedCard,
                                  setSelectedCard,
                                  originId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                              } else {
                                getOptions(
                                  `${environments.catalog}/products/${product?.id}`,
                                  'products',
                                  selectedProduct,
                                  setSelectedProduct,
                                  originId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                              }
                            }}
                            className="cursorPointer fontSizeDefault p0 mr10"
                            style={{ width: '420px', fontWeight: product?.main ? '700' : '' }}
                          >
                            {ValidationLength(product?.description || '-', 50)}
                          </Typography>

                          <div className="dFlex">
                            <div className={`${product?.images?.url ? 'visibilityVisible' : 'visibilityHidden'}`}>
                              <ImageWrapper
                                width="30px"
                                height="28px"
                                url={product?.images?.url}
                              />
                            </div>

                            <Tooltip
                              title="Produto principal"
                              placement="bottom-start"
                            >
                              <Checkbox
                                className="checkBoxSmall ml8 mt2"
                                name="productMain"
                                checked={product?.main ? product?.main : false}
                                onChange={() => {
                                  setDataTable((prevState) => {
                                    const newData = prevState?.data?.map((el, index) => {
                                      if (prevState.data.length === 1) {
                                        return {
                                          ...el,
                                          main: !el.main
                                        };
                                      } if (index === productIndex) {
                                        return {
                                          ...el,
                                          main: !el.main
                                        };
                                      } 
                                        return {
                                          ...el,
                                          main: false
                                        };
                                      
                                    });

                                    return {
                                      ...prevState,
                                      data: sortDataInfoItems(newData)
                                    };
                                  });
                                  setHasChanged(true);
                                }
                              }
                                size="small"
                              />
                            </Tooltip>
                          </div>
                        </td>

                        <td className="description left pl12">
                          <Typography
                            className="fontSizeDefault p0"
                          >
                            {product?.itemProcess ? `${product?.itemProcess?.processes?.description} (${product?.itemProcess?.percentage?.toLocaleString('pt-br', {
                                minimumFractionDigits: 2
                              })}%)` : ''
                            }
                          </Typography>
                        </td>

                        <td className="description right pl12">
                          {(product?.stock || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description right pl12">
                          {(product?.listCostAverage || 0).toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 3
                            }
                          )}
                        </td>

                        <td className="description right pl12">
                          {(product?.listCostLast || 0).toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 3
                            }
                          )}
                        </td>

                        <td
                          className="description right pl12"
                          style={{ height: 40 }}
                        >
                          <FormControl required>
                            <CurrencyTextField
                              variant="standard"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              type="text"
                              name="quantity"
                              fullWidth
                              placeholder="Qtd"
                              required={dataTable.length < 1}
                              value={product?.quantity}
                              InputProps={{
                                maxLength: 8,
                                classes: { input: classes.input }
                              }}
                              onBlur={(_, value) => {
                                setHasChanged(true);

                                let lossPercentageByProccess = 0

                                if (product?.itemProcess?.percentage) {
                                  lossPercentageByProccess = product?.itemProcess?.percentage || 0
                                }

                                const dataCost =
                                  typeof product?.costLast === 'string'
                                    ? product?.costLast
                                        ?.replace('.', '')
                                        ?.replace(/,/g, '.') || '0,000'
                                    : product?.costLast;

                                const dataConstAverage =
                                  typeof product?.costAverage === 'string'
                                    ? product?.costAverage
                                        ?.replace('.', '')
                                        ?.replace(/,/g, '.') || '0,000'
                                    : product?.costAverage;

                                const cost =
                                  value &&
                                  (parameters === 'LAST_PURCHASE' ||
                                    notHasSkus === true)
                                    ? value * parseFloat((dataCost / (1 - lossPercentageByProccess / 100)))
                                    : value
                                    ? value * parseFloat((dataConstAverage / (1 - lossPercentageByProccess / 100)))
                                    : '0,000';

                                const costTotalSum =
                                  dataTable.data
                                    ?.filter((el, index) => index !== productIndex)
                                    ?.map((el) =>
                                      typeof el.listCost === 'string'
                                        ? parseFloat(
                                            el?.listCost
                                              ?.replace('.', '')
                                              ?.replace(/,/g, '.')
                                          )
                                        : el?.listCost
                                    )
                                    ?.reduce(
                                      (acc, curr) =>
                                        parseFloat(acc) + parseFloat(curr),
                                      0
                                    ) + cost;

                                setCostTotal(costTotalSum);

                                setDataTable({
                                  ...dataTable,
                                  data: dataTable.data.map((item, index) => {
                                    if (index === productIndex) {
                                      return {
                                        ...item,
                                        listQuantity: parseFloat(
                                          value
                                        ).toLocaleString('pt-br', {
                                          minimumFractionDigits: 3
                                        }),
                                        quantity: value
                                          ? value.toLocaleString('pt-br', {
                                              minimumFractionDigits: 3
                                            })
                                          : '0,000',
                                        listCost: cost.toLocaleString('pt-br', {
                                          minimumFractionDigits: 3
                                        }),
                                        cost: cost.toLocaleString('pt-br', {
                                          minimumFractionDigits: 9
                                        })
                                      };
                                    }
                                    return {
                                      ...item
                                    };
                                  })
                                });
                              }}
                            />
                          </FormControl>
                        </td>

                        <td className="description center pl12">
                          {product?.consumptionUnitsPrimary || '-'}
                        </td>

                        <td
                          className="description right pl12 pr20"
                          style={{ borderRight: '1px solid #D2D3D7' }}
                        >
                          {(product?.listCost || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description center">
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setHasChanged(true);
                              setDataTable((prevState) => ({
                                ...prevState,
                                data: prevState?.data?.filter(
                                  (el, index) => index !== productIndex
                                )
                              }));

                              setCostTotal(
                                costTotal -
                                  parseFloat(
                                    (product?.listCost || 0)?.replace(',', '.')
                                  )
                              );
                            }}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="8" className="m10 textAlignCenter">
                          <hr />
                        </td>
                      </tr>
                    </>
                  ))
                : null}

              <tr>
                <td className="description left pl12 dFlex alignCenter" style={{ height: '53.5px' }}>
                  <FormControl
                    fullWidth
                    error={Boolean(errors.units)}
                    style={{
                      maxWidth: '420px'
                    }}
                    className="productInput mr10"
                  >
                    <Autocomplete
                      size="small"
                      id="cardFormMainProductInput"
                      includeInputInList
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      classes={{
                        input: classes.input,
                        option: classes.option
                      }}
                      onOpen={() => {
                        addUniqueIdAutoComplete();
                      }}
                      onInputChange={(_, value) => {
                        getMoreOptions(getProducts, value);
                      }}
                      getOptionDisabled={(option) =>
                      {
                        const hasAlreadyOption = option?.productProcesses?.every((el) => dataTable?.data.map((item) => item?.itemProcess?.id).includes(el?.id))
                        const hasAlreadyProductWithoutProcess = dataTable.data.filter((el) => !el.itemProcess)
                        const hasAlreadyProduct = dataTable.data.filter((item) => item?.id === option?.id)
                        return (hasAlreadyProduct.length && hasAlreadyProductWithoutProcess.length && hasAlreadyOption)
                      }
                      }
                      onChange={(e, value) => {
                        let newProductRowBody = {};
                        setHasChanged(true);
                        setProcessData(value?.productProcesses);
                        if (!newProductRow) {
                          newProductRowBody = {
                            id: null,
                            description: null,
                            quantity: null,
                            stock: null,
                            consumptionUnitsPrimary: null,
                            costAverage: null,
                            costLast: null,
                            cost: null
                          };
                        }

                        let lossPercentageByProccess = 0

                        if (value?.productProcesses && Array.isArray(value?.productProcesses) && value?.productProcesses.length > 0) {
                          if (value?.productProcesses[0]?.percentage) {
                            lossPercentageByProccess = value?.productProcesses[0]?.percentage || 0
                          }
                        }

                        const dataCost =
                          value?.lastCost ||
                          value?.lastCostStock ||
                          value?.approximateCost ||
                          0;

                        const dataConstAverage =
                          value?.averageCost || value?.averageCostStock || 0;

                        const cost =
                          parseFloat(newProductRow?.quantity) &&
                            (parameters === 'LAST_PURCHASE' ||
                              notHasSkus === true)
                            ? parseFloat(newProductRow?.quantity) *
                            parseFloat((dataCost / (1 - lossPercentageByProccess / 100)) || 0)
                            : parseFloat(newProductRow?.quantity)
                              ? parseFloat(newProductRow?.quantity) *
                              parseFloat((dataConstAverage / (1 - lossPercentageByProccess / 100)) || 0)
                              : '0,000';

                        if (value && value.inputValue) {
                          newProductRowBody = {
                            description: value.inputValue,
                            quantity:
                              parseFloat(newProductRow?.quantity) || '-',
                            costLast: editMode
                              ? !hasProduction
                                ? transformation.lastCost /
                                  (1 - valueLoss / 100)
                                : '0,000'
                              : transformation.lastCost / (1 - valueLoss / 100),
                            listCost: cost.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            cost: cost.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          };

                          setShowColumnsProducts(false);
                        } else if (value) {
                          newProductRowBody = {
                            id: value?.id,
                            originId: value?.originIdCard,
                            description: e.target.innerText,
                            quantity: parseFloat(newProductRow?.quantity) || 0,
                            productProcesses: value?.productProcesses,
                            listCostLast: (
                              value?.lastCost ||
                              value?.lastCostStock ||
                              value?.approximateCost ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            listCostAverage: (
                              value?.averageCost ||
                              value?.averageCostStock ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            lastCount: (value?.lastCount || 0).toLocaleString(
                              'pt-br',
                              { minimumFractionDigits: 3 }
                            ),
                            consumptionUnitsPrimary:
                              value?.consumptionUnitsPrimary?.abbreviation ||
                              null,
                            consumptionUnitsPrimaryId:
                              value?.consumptionUnitsPrimary?.id || null,
                            costAverage: (
                              value?.averageCost ||
                              value?.averageCostStock ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            costLast: (
                              value?.lastCost ||
                              value?.lastCostStock ||
                              value?.approximateCost ||
                              0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            stock: (value?.realStock || 0).toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            ),
                            listCost: cost.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            cost: cost.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          };

                          if (value?.skus?.length === 0) {
                            notHasSkus = true;
                          } else {
                            notHasSkus = false;
                          }
                        }

                        setNewProductRow(newProductRowBody);

                        if (value && newProductRow?.quantity) {
                          setDataTable((prevState) => ({
                            ...prevState,
                            data: [prevState?.data, newProductRowBody].flat()
                          }));

                          setCostTotal(costTotal + cost);

                          setNewProductRow({
                            description: '',
                            quantity: '',
                            frequencies: [],
                            frequenciesDescription: [],
                            storageLocations: [],
                            storageLocationsDescription: []
                          });

                          document
                            .querySelector('#cardFormMainProductInput')
                            .focus();
                        }
                      }}
                      value={newProductRow}
                      inputValue={newProductRow || newProductRow?.description}
                      getOptionLabel={(option) =>
                        option?.description ||
                        option?.inputValue ||
                        option?.title ||
                        ''
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params?.inputValue !== '') {
                          filtered.push({
                            inputValue: params?.inputValue,
                            description: `Criar "${params?.inputValue}"`
                          });
                        }
                        return filtered;
                      }}
                      getOptionSelected={(option, value) =>
                        option?.description === value?.description
                      }
                      options={products?.filter(
                        (product) =>
                          product?.description &&
                          (product.originIdCard != cardId ||
                            !product.originIdCard)
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={params.id}
                          name="produtos"
                          placeholder="Produtos"
                          inputRef={register}
                          error={errors.units}
                          InputLabelProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params?.description}
                        </Typography>
                      )}
                    />
                  </FormControl>
                  
                  <div className="dFlex">
                    <div style={{ width: 30 }}/>
                    
                    <Tooltip
                      title="Produto principal"
                      placement="bottom-start"
                    >
                      <Checkbox
                        className="checkBoxSmall ml8"
                        name="productMain"
                        checked={productMain}
                        onChange={(e) => {
                          const hasMainTrue = dataTable.data.some(item => item.main === true);

                          if(hasMainTrue) {
                            setMessagePersonal("Já existe um produto como principal!");
                            return;
                          }
                          setProductMain(e.target.checked);
                            const newProductRowBody = {
                              ...newProductRow,
                              main: e.target.checked
                            };
    
                            setNewProductRow(newProductRowBody);

                        }}
                        size="small"
                      />
                    </Tooltip>
                  </div>
                </td>

                <td className="description left pl12">
                  <FormControl style={{ width: '60%' }}>
                    <Autocomplete
                      size="small"
                      classes={{
                        input: classes.input,
                        option: classes.option
                      }}
                      value={processTable || ''}
                      inputValue={processTable?.processes?.description || ''}
                      onChange={(_, value) => {
                        handleAutocompleteChange(value);

                        const newProductRowBody = {
                          ...newProductRow,
                          itemProcess: value
                        };

                        if (value && newProductRow?.description && newProductRow?.quantity) {
                          setDataTable((prevState) => ({
                            ...prevState,
                            data: [prevState?.data, newProductRowBody].flat()
                          }));
                          setProcessData([])
                          setProcessTable(null)
                          setNewProductRow({
                            description: '',
                            quantity: '',
                            itemProcess: null
                          })

                          document
                            .querySelector('#cardFormMainProductInput')
                            .focus();
                        }
                      }}
                      getOptionSelected={(option, value) =>
                        option?.processes?.description === value?.processes?.description
                      }
                      getOptionDisabled={(option) =>
                        dataTable?.data?.every((item) => item?.id)
                          ? dataTable?.data?.some(
                              (item) => item?.itemProcess?.id === option?.id
                            )
                          : dataTable?.data?.some(
                              (item) =>
                                item?.itemProcess?.processes?.description === option?.description
                            )
                      }
                      getOptionLabel={(option) => option?.processes?.description || ''}
                      options={processData?.map((items) => items) || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={params.id}
                          placeholder="Processo"
                          name='Process'
                          InputLabelProps={{
                            style: {
                              fontFamily: 'Lato',
                              fontSize: '12px'
                            }
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </td>

                <td className="description right pl12">
                  {(newProductRow?.stock || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </td>

                <td className="description right pl12">
                  {(newProductRow?.listCostAverage || 0).toLocaleString(
                    'pt-br',
                    {
                      minimumFractionDigits: 3
                    }
                  )}
                </td>

                <td className="description right pl12">
                  {(newProductRow?.listCostLast || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </td>

                <td className="description right pl12">
                  <FormControl required>
                    {newProductRow?.consumptionUnitsPrimary === 'un' &&
                      <CurrencyTextField
                        variant="standard"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={0}
                        decimalPlacesShownOnFocus={0}
                        type="text"
                        name="quantity"
                        fullWidth
                        placeholder="Qtd"
                        required={dataTable.length < 1}
                        value={newProductRow?.quantity}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input }
                        }}
                        onBlur={(_, value) => {
                          const hasAlreadyProducts = dataTable?.data?.filter((el) => newProductRow?.id ? newProductRow?.id === el?.id : newProductRow?.description === el?.description )

                          if (value) {
                            setHasChanged(true);
                          }

                          let lossPercentageByProccess = 0

                          if (newProductRow?.productProcesses && Array.isArray(newProductRow?.productProcesses) && newProductRow?.productProcesses.length > 0) {
                            if (newProductRow?.productProcesses[0]?.percentage) {
                              lossPercentageByProccess = newProductRow?.productProcesses[0]?.percentage || 0
                            }
                          }

                          const dataCost =
                            typeof newProductRow?.costLast === 'string'
                              ? newProductRow?.costLast
                                  ?.replace('.', '')
                                  ?.replace(/,/g, '.') || '0,000'
                              : newProductRow?.costLast;
                          const dataConstAverage =
                            newProductRow?.costAverage
                              ?.replace('.', '')
                              ?.replace(/,/g, '.') || '0,000';
                          const cost =
                            value &&
                              (parameters === 'LAST_PURCHASE' ||
                                notHasSkus === true)
                              ? value * parseFloat((dataCost / (1 - lossPercentageByProccess / 100)) || 0)
                              : value
                                ? value * parseFloat((dataConstAverage / (1 - lossPercentageByProccess / 100)) || 0)
                                : '0,000';

                          const newProductRowBody = {
                            ...newProductRow,
                            listQuantity: parseFloat(value)?.toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            ),
                            quantity: (value || 0)?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            listCost: cost?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            cost: cost?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            itemProcess: processTable,
                            main: productMain
                          };

                          if (hasAlreadyProducts.length && hasAlreadyProducts.find((el) => !el?.itemProcess) && !processTable) {
                            setMessagePersonal('Você não pode adicionar o mesmo produto com o mesmo processo!')
                            setNewProductRow({
                              ...newProductRowBody,
                              listQuantity: parseFloat(value).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              quantity: (value || 0)?.toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              stock: newProductRow?.stock,
                              listCostAverage: newProductRow?.listCostAverage,
                              listCostLast: newProductRow?.listCostLast,
                              listCost: newProductRow?.listCost,
                              itemProcess: processTable,
                              main: productMain
                            });
                            return;
                          }

                          setNewProductRow({
                            ...newProductRowBody,
                            description: newProductRow?.description
                              ? ''
                              : newProductRow?.description,
                            listQuantity: newProductRow?.description
                              ? ''
                              : parseFloat(value).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                }),
                            quantity: newProductRow?.description
                              ? ''
                              : (value || 0)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                }),
                            stock: newProductRow?.description
                              ? ''
                              : newProductRow?.stock,
                            listCostAverage: newProductRow?.description
                              ? ''
                              : newProductRow?.listCostAverage,
                            listCostLast: newProductRow?.description
                              ? ''
                              : newProductRow?.listCostLast,
                            listCost: newProductRow?.description
                              ? ''
                              : newProductRow?.listCost,
                            itemProcess: processTable 
                              ? ''
                              : processTable,
                            main: newProductRow?.main ? false : newProductRow.main
                          });

                          if (value && newProductRow?.description) {
                            setDataTable((prevState) => ({
                              ...prevState,
                              data: [prevState?.data, newProductRowBody].flat()
                            }));
                            setProcessData([])
                            setProcessTable('')
                            setProductMain(false);
                            setCostTotal(costTotal + cost);

                            document
                              .querySelector('#cardFormMainProductInput')
                              .focus();
                          }

                        }}
                      />
                    }

                    {newProductRow?.consumptionUnitsPrimary !== 'un' &&
                      <CurrencyTextField
                        variant="standard"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="quantity"
                        fullWidth
                        placeholder="Qtd"
                        required={dataTable.length < 1}
                        value={newProductRow?.quantity}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input }
                        }}
                        onBlur={(_, value) => {
                          const hasAlreadyProducts = dataTable?.data?.filter((el) => el?.id === newProductRow?.id)

                          if (value) {
                            setHasChanged(true);
                          }
                          
                          let lossPercentageByProccess = 0

                          if (newProductRow?.productProcesses && Array.isArray(newProductRow?.productProcesses) && newProductRow?.productProcesses.length > 0) {
                            if (newProductRow?.productProcesses[0]?.percentage) {
                              lossPercentageByProccess = newProductRow?.productProcesses[0]?.percentage || 0
                            }
                          }

                          const dataCost =
                            typeof newProductRow?.costLast === 'string'
                              ? newProductRow?.costLast
                                  ?.replace('.', '')
                                  ?.replace(/,/g, '.') || '0,000'
                              : newProductRow?.costLast;
                          const dataConstAverage =
                            newProductRow?.costAverage
                              ?.replace('.', '')
                              ?.replace(/,/g, '.') || '0,000';
                          const cost =
                            value &&
                              (parameters === 'LAST_PURCHASE' ||
                                notHasSkus === true)
                              ? value * parseFloat((dataCost / (1 - lossPercentageByProccess / 100)) || 0)
                              : value
                                ? value * parseFloat((dataConstAverage / (1 - lossPercentageByProccess / 100)) || 0)
                                : '0,000';

                          const newProductRowBody = {
                            ...newProductRow,
                            listQuantity: parseFloat(value)?.toLocaleString(
                              'pt-br',
                              {
                                minimumFractionDigits: 3
                              }
                            ),
                            quantity: (value || 0)?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            listCost: cost?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            cost: cost?.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            }),
                            itemProcess: processTable,
                            main: productMain
                          };

                          if (hasAlreadyProducts.length && hasAlreadyProducts.find((el) => !el?.itemProcess) && !processTable) {
                            setMessagePersonal('Você não pode adicionar o mesmo produto com o mesmo processo!')
                            setNewProductRow({
                              ...newProductRowBody,
                              listQuantity: parseFloat(value).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              quantity: (value || 0)?.toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              stock: newProductRow?.stock,
                              listCostAverage: newProductRow?.listCostAverage,
                              listCostLast: newProductRow?.listCostLast,
                              listCost: newProductRow?.listCost,
                              itemProcess: processTable,
                              main: productMain
                            });
                            return;
                          }

                          setNewProductRow({
                            ...newProductRowBody,
                            description: newProductRow?.description
                              ? ''
                              : newProductRow?.description,
                            listQuantity: newProductRow?.description
                              ? ''
                              : parseFloat(value).toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                }),
                            quantity: newProductRow?.description
                              ? ''
                              : (value || 0)?.toLocaleString('pt-br', {
                                  minimumFractionDigits: 3
                                }),
                            stock: newProductRow?.description
                              ? ''
                              : newProductRow?.stock,
                            listCostAverage: newProductRow?.description
                              ? ''
                              : newProductRow?.listCostAverage,
                            listCostLast: newProductRow?.description
                              ? ''
                              : newProductRow?.listCostLast,
                            listCost: newProductRow?.description
                              ? ''
                              : newProductRow?.listCost,
                            itemProcess: processTable 
                              ? ''
                              : processTable,
                            main: newProductRow?.main ? false : newProductRow.main
                          });

                          if (value && newProductRow?.description) {
                            setDataTable((prevState) => ({
                              ...prevState,
                              data: [prevState?.data, newProductRowBody].flat()
                            }));
                            setProcessData([])
                            setProcessTable('')
                            setProductMain(false);
                            setCostTotal(costTotal + cost);

                            document
                              .querySelector('#cardFormMainProductInput')
                              .focus();
                          }

                        }}
                      />
                    }
                  </FormControl>
                </td>

                <td className="description center pl12">
                  {newProductRow?.consumptionUnitsPrimary || '-'}
                </td>

                <td
                  className="description right pl12 pr20"
                  style={{ borderRight: '1px solid #D2D3D7' }}
                >
                  {(newProductRow?.listCost || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </td>

                <td className="description flex center" />
              </tr>

              {/* {!dataTable?.data?.length ? (
                <tr>
                  <th colSpan="8" className="m10 textAlignCenter">
                    Não há dados a serem exibidos.
                  </th>
                </tr>
              ) : null} */}
            </tbody>
          </table>

          {radioValue === 'RECIPE' ? (
            <table className="resultsTable" border="0" width="100%">
              <tr>
                <td colSpan="3" height="20" />
              </tr>

              <tr>
                <td
                  width="89.3%"
                  align="right"
                  style={{ fontFamily: 'Lato', fontSize: '12px' }}
                >
                  <b>
                    Custo Total (
                    {parseFloat(production?.yieldTotal || 0).toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 3 }
                    )}
                    {ucPrimarySelectedId
                      ? ` ${ucPrimarySelectedId?.abbreviation}`
                      : ''}
                    ) :
                  </b>
                </td>

                <td
                  width="8%"
                  align="right"
                  style={{
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: '#5062F0'
                  }}
                >
                  <b>
                    {costTotal.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </b>
                </td>
                <td width="2.7%">&nbsp;</td>
              </tr>

              <tr>
                <td colSpan="3" height="5" />
              </tr>

              <tr>
                <td
                  width="89.3%"
                  align="right"
                  style={{ fontFamily: 'Lato', fontSize: '12px' }}
                >
                  <b>
                    Custo Unitário{' '}
                    {ucPrimarySelectedId
                      ? `(${ucPrimarySelectedId?.abbreviation})`
                      : null}
                    :
                  </b>
                </td>
                <td
                  width="8%"
                  align="right"
                  style={{
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    color: '#5062F0'
                  }}
                >
                  <b>
                    {production.yieldTotal && costTotal
                      ? parseFloat(production.yieldTotal) !== 0 &&
                        parseFloat(costTotal) !== 0
                        ? (
                            costTotal / parseFloat(production.yieldTotal)
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'
                      : '0,000'}
                  </b>
                </td>
                <td width="2.7%">&nbsp;</td>
              </tr>

              <tr>
                <td colSpan="3" height="20" />
              </tr>
            </table>
          ) : null}

          {radioValue === 'MENU' && costTotal ? (
            <table border="0" width="100%">
              <tr>
                <td colSpan="3" height="20" />
              </tr>

              <tr>
                <td
                  width="82%"
                  align="right"
                  style={{ fontFamily: 'Lato', fontSize: '13px' }}
                >
                  <b>Custo Total:</b>
                </td>
                <td
                  width="11%"
                  align="right"
                  style={{
                    fontFamily: 'Lato',
                    fontSize: '13px',
                    color: '#5062F0'
                  }}
                >
                  <b>
                    {costTotal.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })}
                  </b>
                </td>
                <td width="7%">&nbsp;</td>
              </tr>

              <tr>
                <td colSpan="3" height="5" />
              </tr>
            </table>
          ) : null}
        </Grid>
      )}

      {radioValue !== 'TRANSFORMATION' ? null : (
        <div className="dFlex flexColumn mb10">
          <Grid item xs={12} sm={12}>
            <TableContainer
              component={Paper}
              className="materialTableDefault withoutBoxShadow selfShadow"
            >
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead className="headerTable">
                  <TableRow>
                    <TableCell width="42%">
                      <span className="fontSizeDefault bold">
                        PRODUTO A SER TRANSFORMADO
                      </span>
                    </TableCell>

                    <TableCell
                      width="5%"
                      align="right"
                      className="fontSizeDefault bold"
                    >
                      ESTOQUE
                    </TableCell>

                    <TableCell
                      width="5%"
                      align="right"
                      className="fontSizeDefault bold"
                    >
                      MÉDIO R$
                    </TableCell>

                    <TableCell
                      width="5%"
                      align="right"
                      className="fontSizeDefault bold"
                    >
                      ÚLTIMO R$
                    </TableCell>

                    <TableCell
                      width="10%"
                      align="right"
                      className="fontSizeDefault bold"
                    >
                      QTDE
                    </TableCell>

                    <TableCell
                      width="2%"
                      align="center"
                      className="fontSizeDefault bold"
                    >
                      UN.
                    </TableCell>
                    {/* <TableCell align="right">Custo</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell
                      width="300"
                      id="cardFormProductTransformInput"
                      className={`pt0 pb0 ${
                        requiredFields?.includes('productTransformation') && 'labelError'
                      }`}
                    >
                      <div className="dFlex alignCenter">
                        <Autocomplete
                          style={{
                            maxWidth: '420px',
                            width: '80%',
                            marginRight: 10
                          }}
                          id="asynchronous-demo"
                          // getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={(option) => option?.description}
                          size="small"
                          onOpen={() => {
                            addUniqueIdAutoComplete();
                          }}
                          onInputChange={(_, value) => {
                            getMoreOptions(getProducts, value);
                          }}
                          onChange={(option, value) => {
                            setHasChanged(true);
                            if (value) {
                              if (value.skus.length === 0) {
                                notHasSkus = true;
                              } else {
                                notHasSkus = false;
                              }

                              setProductTransformation(value);

                              const lastValue = value.skus.length
                                ? value.lastCostStock
                                : value.type === 'INTERNAL'
                                ? value.lastCostStock || value.lastCost || 0
                                : value.approximateCost;

                              setTransformation({
                                ...transformation,
                                productId: value.id,
                                stock: value.realStock,
                                consumptionUnitsPrimaryId:
                                  value.consumptionUnitsPrimary.id,
                                consumptionUnitsPrimary:
                                  value.consumptionUnitsPrimary.abbreviation,
                                averageCost: value.averageCostStock,
                                lastCost: lastValue,
                                product: value,
                                quantity: transformation?.quantity || 1,
                                cost:
                                  parameters === 'LAST_PURCHASE' ||
                                  value.skus.length === 0
                                    ? lastValue
                                    : value.averageCostStock ||
                                      value.averageCost ||
                                      0
                              });
                              setProduction({
                                ...production,
                                yield: 1,
                                yieldTotal: 1
                              });
                              setCost({
                                ...cost,
                                discountPrice: value.discountPrice,
                                averagePrice: value.averagePrice,
                                costTotal:
                                  parameters === 'LAST_PURCHASE' ||
                                  value.skus.length === 0
                                    ? lastValue
                                    : value.averageCostStock ||
                                      value.averageCost ||
                                      0,
                                costUnit:
                                  parameters === 'LAST_PURCHASE' ||
                                  value.skus.length === 0
                                    ? lastValue
                                    : value.averageCostStock ||
                                      value.averageCost ||
                                      0
                              });
                            } else {
                              setProductTransformation([]);
                              notHasSkus = false;
                              setTransformation({
                                productId: null,
                                stock: null,
                                consumptionUnitsPrimaryId: null,
                                consumptionUnitsPrimary: null,
                                averageCost: null,
                                lastCost: null,
                                product: null,
                                quantity: null,
                                cost: null
                              });
                              setProduction({
                                yield: null,
                                yieldTotal: null
                              });
                              setCost({
                                discountPrice: null,
                                averagePrice: null,
                                costTotal: null,
                                costUnit: null
                              });
                            }
                          }}
                          classes={{
                            input: classes.input,
                            option: classes.option
                          }}
                          options={products?.filter(
                            (product) =>
                              product?.description &&
                              (product.originIdCard != cardId ||
                                !product.originIdCard)
                          )}
                          value={productTransformation}
                          renderInput={(params) => (
                            <FormControl fullWidth>
                              <TextField
                                {...params}
                                id={params.id}
                                placeholder="Produto"
                                size="small"
                                name="ProductsToBeTransformed"
                                inputRef={register}
                                InputLabelProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            </FormControl>
                          )}
                          renderOption={(params) => (
                            <Typography
                              style={{ fontSize: '12px', fontFamily: 'Lato' }}
                            >
                              {params?.description}
                            </Typography>
                          )}
                        />

                        <ImageWrapper
                          width="30px"
                          height="28px"
                          url={productTransformation?.images?.url}
                        />
                      </div>
                    </TableCell>

                    <TableCell
                      align="right"
                      size="small"
                      className="fontSizeDefault pt0 pb0"
                    >
                      {transformation.stock
                        ? transformation.stock.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      align="right"
                      size="small"
                      className="fontSizeDefault pt0 pb0"
                    >
                      {transformation.averageCost
                        ? transformation.averageCost.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}
                    </TableCell>

                    <TableCell
                      align="right"
                      size="small"
                      className="fontSizeDefault pt0 pb0"
                    >
                      {transformation.lastCost
                        ? transformation.lastCost.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}
                    </TableCell>

                    {/* <TableCell width="120" align="right">
                                              <FormControl fullwidth>
                                                  <CurrencyTextField
                                                      variant="standard"
                                                      size="small"
                                                      currencySymbol=""
                                                      minimumValue="0"
                                                      outputFormat="string"
                                                      decimalCharacter=","
                                                      digitGroupSeparator='.'
                                                      decimalPlaces={3}
                                                      disabled={true}
                                                      decimalPlacesShownOnFocus={3}
                                                      type="text"
                                                      name="quantityToBeTransformed"
                                                      fullWidth
                                                      placeholder="Qtd"
                                                      value={transformation.quantity}
                                                      InputProps={{ maxLength: 8, classes: { input: classes.input } }}
                                                  />
                                              </FormControl>
                                          </TableCell> */}

                    <TableCell
                      align="right"
                      style={{ fontFamily: 'Lato', fontSize: '12px' }}
                      className="pt0 pb0"
                    >
                      <CurrencyTextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol="R$"
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","

                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="realStockValue"
                        className="textFieldSmall right"
                        placeholder="Digite aqui..."
                        onBlur={(_, value) => {
                          setHasChanged(true);
                          setTransformation({
                            ...transformation,
                            quantity: value
                          });
                        }}
                        value={transformation?.quantity || 1}
                      />
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{ fontFamily: 'Lato', fontSize: '12px' }}
                    >
                      {transformation.consumptionUnitsPrimary}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <table className="resultsTable" border="0" width="100%">
                <tr>
                  <td width="87%">&nbsp;</td>
                  <td width="13%" style={{ padding: '10px 0' }}>
                    <FormControl className="mt20">
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="quantityToBeTransformed"
                        fullWidth
                        label="% de Perda esperada na Transformação"
                        className="textFieldSmall right inLineTextfield"
                        placeholder="Digite aqui..."
                        value={valueLoss}
                        InputProps={{ classes: { input: classes.input } }}
                        onBlur={(_, value) => {
                          setHasChanged(true);
                          setValueLoss(value);
                          setProduction({
                            yield: parseFloat(production.yield),
                            yieldTotal: parseFloat(
                              production.yield -
                                production.yield * (value / 100)
                            )
                          });

                          if (editMode) {
                            // setTransformation({
                            //   ...transformation,
                            //   quantity: 1 + (1 * parseFloat(value / 100)),
                            //   cost: parameters === 'LAST_PURCHASE' || notHasSkus === true
                            //     ? transformation.lastCost + (transformation.lastCost * (value / 100))
                            //     : transformation.averageCost + (transformation.averageCost * (value / 100)),
                            // })

                            !hasProduction &&
                              setDataTable({
                                ...dataTable,
                                data: dataTable.data.map((item) => ({
                                  ...item,
                                  costLast:
                                    transformation.lastCost / (1 - value / 100)
                                  // costAverage: transformation.averageCost + (transformation.averageCost * (value / 100))
                                }))
                              });
                          } else {
                            setDataTable({
                              ...dataTable,
                              data: dataTable.data.map((item) => ({
                                ...item,
                                costLast:
                                  transformation.lastCost / (1 - value / 100)
                                // costAverage: transformation.averageCost + (transformation.averageCost * (value / 100))
                              }))
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </td>
                </tr>
              </table>
            </TableContainer>
          </Grid>
        </div>
      )}

      {radioValue !== 'TRANSFORMATION' ? null : (
        <Grid
          item
          xs={12}
          sm={12}
          className="materialTableDefault withoutBoxShadow selfShadow"
          id="cardFormAddMainProduct"
        >
          <table className="desktop">
            <thead className="headerTable">
              <tr className="headerTitle">
                <th width="30%" className="subtitle fontSizeDefault pl16">
                  PRODUTO
                </th>

                <th width="4%" className="subtitle fontSizeDefault pl8">
                  UN
                </th>

                <th width="5%" className="subtitle fontSizeDefault right pr12">
                  ESTOQUE
                </th>

                {/* <th width="5%" className="subtitle fontSizeDefault right pr12">
                  MÉDIO R$
                </th>

                <th width="5%" className="subtitle fontSizeDefault right pr12">
                  ÚLTIMO R$
                </th> */}

                <th width="8%" className="subtitle fontSizeDefault right pr12">
                  CUSTO ESPERADO R$
                </th>

                <th width="3%" className="subtitle fontSizeDefault right pr12"/>

                <th width="10%" className="subtitle fontSizeDefault pl12">
                  LOCAL DE ESTOQUE
                </th>

                <th width="10%" className="subtitle fontSizeDefault pl12">
                  CONTAGEM
                </th>

                <th
                  width="10%"
                  className="subtitle fontSizeDefault pl12 right"
                  style={{ paddingRight: '10px' }}
                >
                  <Tooltip
                    title="% de Perda aceita na Contagem"
                    placement="bottom-start"
                  >
                    <span>% PERDA CONTAGEM</span>
                  </Tooltip>
                </th>

                <th
                  width="2%"
                  className="subtitle fontSizeDefault"
                  style={{ borderLeft: '1px solid #D2D3D7' }}
                 />
              </tr>
            </thead> 

            <tbody>
              {dataTable?.data?.length
                ? dataTable?.data.map((product, productIndex) => {
                  const disableProductClick = !product?.id && !product?.originIdCard || radioValue === 'TRANSFORMATION'
                  const currentProductConsumptionUnit = product?.un || product?.products?.consumptionUnitsPrimary
                  const productStorageLocations = product?.storageLocations || product?.products?.storageLocations
                  const productFrequencies = product?.frequencies || product?.products?.frequencies

                  const storageOptions = storageLocationOptions?.filter(el => productStorageLocations?.map(storage => storage?.id).includes(el?.id))
                  const frequenciesOptions = periodicityOptions?.filter(el => productFrequencies?.map(frequency => frequency?.id).includes(el?.id))

                  return (
                    <>
                      <tr 
                        className="lineBorder"
                        onMouseEnter={() => {
                          setDataTable((prevState) => {
                            const newData = prevState?.data?.map((el, index) => {
                              if (index === productIndex) {
                                return {
                                  ...el,
                                  showImage: true
                                };
                              } 

                              return { ...el };
                            });
                      
                            return {
                              ...prevState,
                              data: newData
                            };
                          });
                        }}
                        onMouseLeave={() => {
                          setDataTable((prevState) => {
                            const newData = prevState?.data?.map((el, index) => {
                              if (index === productIndex) {
                                return {
                                  ...el,
                                  showImage: false
                                };
                              } 

                              return { ...el };
                            });
                      
                            return {
                              ...prevState,
                              data: newData
                            };
                          });
                        }}
                      >
                        <td className="description left pl16 dFlex alignCenter" style={{ height: '48px' }}>
                          <Typography
                            onClick={() => {
                              if (disableProductClick) return null

                              if (product?.type === 'INTERNAL') {
                                getOptions(
                                  `${environments.catalog}/cards/${product?.originIdCard}`,
                                  'cards',
                                  selectedCard,
                                  setSelectedCard,
                                  originId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                              } else {
                                getOptions(
                                  `${environments.catalog}/products/${product?.id}`,
                                  'products',
                                  selectedProduct,
                                  setSelectedProduct,
                                  originId,
                                  null,
                                  null,
                                  null,
                                  true
                                );
                              }
                              setOnlyViewProduct(true);
                              setModalStates({
                                ...modalStates,
                                modalProduct: true
                              });
                            }}
                            className={`${!disableProductClick && 'cursorPointer lightBlue'} fontSizeDefault p0 mr10`}
                            style={{ width: '420px' }}
                          >
                            {ValidationLength(product?.description || '-', 45)}
                          </Typography>
                          
                          <div className="dFlex">
                            <div className={`${product?.images?.url || product?.showImage ? 'visibilityVisible' : 'visibilityHidden'}`}>
                              <ImageWrapper
                                customOnClick={(event) => {
                                  setAnchorEl(event?.currentTarget);
                                  setImagePopoverOpen(true)
                                  setCurrentProduct({
                                    ...product,
                                    index: productIndex
                                  })
                                }}
                                width="30px"
                                height="28px"
                                url={product?.images?.url}
                              />
                            </div>

                            <Tooltip
                              title="Produto principal"
                              placement="bottom-start"
                            >
                              <Checkbox
                                className="checkBoxSmall ml8 mt2"
                                name="productMain"
                                checked={product?.main ? product?.main : false}
                                onChange={() => {
                                  setDataTable((prevState) => {
                                    const newData = prevState?.data?.map((el, index) => {
                                      if (prevState.data.length === 1) {
                                        return {
                                          ...el,
                                          main: !el.main
                                        };
                                      } if (index === productIndex) {
                                        return {
                                          ...el,
                                          main: !el.main
                                        };
                                      } 
                                        return {
                                          ...el,
                                          main: false
                                        };
                                      
                                    });
                              
                                    return {
                                      ...prevState,
                                      data: newData
                                    };
                                  });
                                  setHasChanged(true);
                                }
                              }
                                size="small"
                              />
                            </Tooltip>
                          </div>
                        </td>

                        <td className="description right pr12">
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            id="cardFormTransformUnityInput"
                            className="pr8 pl8"
                          >
                            {product?.un &&
                              <Autocomplete
                                style={{ maxWidth: '80px' }}
                                size="small"
                                onChange={(_, value) => {
                                  setHasChanged(true);
                                  setDataTable((prevState) => ({
                                    ...prevState,
                                    data: dataTable?.data?.map((el, elIndex) => {
                                      const currentValidation = product?.id ? el?.productId === product?.id : elIndex === productIndex

                                      if (currentValidation) {
                                        return {
                                          ...el,
                                          un: value,
                                          consumptionUnitsPrimaryId: value?.id,
                                          consumptionUnitsPrimary: value
                                        }
                                      }
                                      return { ...el}
                                    })
                                  }))
                                }}
                                value={unitsConsumptions?.find(el => el?.id === currentProductConsumptionUnit?.id)}
                                getOptionSelected={(option, value) =>
                                  option?.abbreviation === value?.abbreviation
                                }
                                getOptionLabel={(option) => option?.abbreviation}
                                options={unitsConsumptions || []}
                                className="autocompleteSmallProducts withoutLabel"
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id={params.id}
                                    label="Un."
                                    placeholder="Un."
                                    error={errors.units}
                                    required
                                    InputLabelProps={{
                                      style: { fontFamily: 'Lato', fontSize: '12px' }
                                    }}
                                  />
                                )}
                                renderOption={(params) => (
                                  <Typography
                                    style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                  >
                                    {params.abbreviation}
                                  </Typography>
                                )}
                              />
                            }
                          </Grid>
                        </td>

                        <td className="description right pr12">
                          {!hasProduction
                            ? '-'
                            : (product?.stock || 0).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              })
                          }
                        </td>

                        {/* <td className="description right pr12">
                          {(product?.costAverage || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td>

                        <td className="description right pr12">
                          {(product?.costLast || 0).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })}
                        </td> */}

                        <td className="description right pr12">
                          {product?.products?.targetCost ? product?.products?.targetCost.toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          }) : '-'}
                        </td> 

                        <td className="description center">
                          <Tooltip
                            title="Efetuar Inventário?"
                            placement="bottom-start"
                            style={{ padding: 0, outline: 0 }}
                          >
                            <IconButton>
                              <img
                                style={{ width: '16px', height: 27, display: 'block', margin: 'auto' }}
                                src={product?.hasInventory ? boxBlue : boxGray}
                                alt="box"
                                onClick={() => {
                                  setDataTable((prevState) => ({
                                    ...prevState,
                                    data: dataTable.data.map((el, elIndex) => {
                                      const currentValidation = product?.id ? el?.productId === product?.id : elIndex === productIndex

                                      if (currentValidation) {
                                        return {
                                          ...el,
                                          hasInventory: !product?.hasInventory,
                                          frequencies: !product?.hasInventory ? el?.frequencies : [],
                                          storageLocations: !product?.hasInventory ? el?.storageLocations : []
                                        }
                                      }
                                      return { ...el }
                                    })
                                  }))
                                  setHasChanged(true)
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </td>

                        <td className="description left pl12">
                          {product?.hasInventory && storageOptions ?
                            <Autocomplete
                              multiple
                              limitTags={1}
                              style={{ maxWidth: '230px' }}
                              size="small"
                              onChange={(_, value) => {
                                setDataTable((prevState) => ({
                                  ...prevState,
                                  data: dataTable.data.map((el, elIndex) => {
                                    const currentValidation = product?.id ? el?.productId === product?.id : elIndex === productIndex

                                    if (
                                      currentValidation
                                    ) {
                                      return {
                                        ...el,
                                        storageLocations: value,
                                        storageLocationsDescription: value?.map(
                                          (item) => item?.description
                                        )
                                      };
                                    }

                                    return { ...el };
                                  })
                                }));
                                
                                setHasChanged(true)
                              }}
                              value={storageOptions}
                              getOptionSelected={(option, value) =>
                                option?.description === value?.description
                              }
                              getOptionLabel={(option) => option?.description}
                              getOptionDisabled={(option) =>
                                productStorageLocations?.some(
                                  (item) => item?.id === option?.id
                                )
                              }
                              options={storageLocationOptions || []}
                              className="autocompleteSmall withoutLabel"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id={params.id}
                                  label="Local Armaz."
                                  placeholder="Local de Estoque"
                                  error={errors.units}
                                  required
                                  InputLabelProps={{
                                    style: { fontFamily: 'Lato', fontSize: '12px' }
                                  }}
                                />
                              )}
                              renderOption={(params) => (
                                <Typography
                                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                >
                                  {params.description}
                                </Typography>
                              )}
                            />
                          : null}
                        </td>

                        <td className="description left pl12">
                          {product?.hasInventory && frequenciesOptions ?
                            <Autocomplete
                              size="small"
                              multiple
                              limitTags={1}
                              style={{maxWidth: '230px'}}
                              onChange={(_, value) => {
                                setDataTable((prevState) => ({
                                  ...prevState,
                                  data: dataTable.data.map((el, elIndex) => {
                                    const currentValidation = product?.id ? el?.productId === product?.id : elIndex === productIndex

                                    if (
                                      currentValidation
                                    ) {
                                      return {
                                        ...el,
                                        frequencies: value,
                                        frequenciesDescription: value?.map(
                                          (item) => item?.description
                                        )
                                      };
                                    }

                                    return { ...el };
                                  })
                                }));
                                
                                setHasChanged(true)
                              }}
                              value={frequenciesOptions}
                              getOptionSelected={(option, value) =>
                                option?.description === value?.description
                              }
                              getOptionLabel={(option) => option?.description}
                              getOptionDisabled={(option) =>
                                productFrequencies?.some(
                                  (item) => item?.id === option?.id
                                )
                              }
                              options={periodicityOptions || []}
                              className="autocompleteSmall withoutLabel"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id={params.id}
                                  label="Period."
                                  placeholder="Contagem"
                                  error={errors.units}
                                  required
                                  InputLabelProps={{
                                    style: { fontFamily: 'Lato', fontSize: '12px' }
                                  }}
                                />
                              )}
                              renderOption={(params) => (
                                <Typography
                                  style={{ fontSize: '12px', fontFamily: 'Lato' }}
                                >
                                  {params.description}
                                </Typography>
                              )}
                            />
                          : null}
                        </td>

                        <td className="description left pr12">
                          <FormControl fullWidth style={{ marginTop: '2px', paddingLeft: '10px' }}>
                            <CurrencyTextField
                              variant="outlined"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnFocus={3}
                              required
                              type="text"
                              name="percentLossAccepted"
                              fullWidth
                              value={product?.lossPercentage}
                              InputProps={{
                                maxLength: 8,
                                classes: { input: classes.input },
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                              className={`textFieldSmall right ${
                                requiredFields?.includes('yieldTotal') &&
                                'labelError'
                              }`}
                              placeholder="Digite aqui..."
                              onBlur={(_, value) => {
                                setHasChanged(true);
                                setDataTable((prevState) => ({
                                  ...prevState,
                                  data: dataTable.data.map((el) => {
                                    if (
                                      el.description === product?.description
                                    ) {
                                      return {
                                        ...el,
                                        lossPercentage: value
                                      };
                                    }

                                    return { ...el };
                                  })
                                }));
                              }}
                            />
                          </FormControl>
                        </td>

                        <td
                          className="description center"
                          style={{ borderLeft: '1px solid rgb(210, 211, 215)' }}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              setHasChanged(true);
                              setDataTable((prevState) => ({
                                ...prevState,
                                data: prevState?.data?.filter((el, elIndex) => elIndex !== productIndex)
                              }))
                            }}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="8" className="m10 textAlignCenter">
                          <hr />
                        </td>
                      </tr>
                    </>
                  )
                })
                : null
              }

              <tr>
                <td className="description left pl16 dFlex alignCenter" style={{ height: '53.5px' }}>
                  <FormControl
                    className="mr10"
                    style={{ maxWidth: '420px' }}
                    fullWidth
                    error={Boolean(errors.units)}
                  >
                    <Autocomplete
                      id="cardFormMainProductInput"
                      size="small"
                      includeInputInList
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      classes={{
                        input: classes.input,
                        option: classes.option
                      }}
                      onOpen={() => {
                        addUniqueIdAutoComplete();
                      }}
                      onInputChange={(_, value) => {
                        getMoreOptions(getProducts, value);
                      }}
                      autoComplete={newProductRow}
                      onChange={(e, value) => {
                        let newProductRowBody = {};

                        if (!value?.useOnTransformation) {
                          if (!newProductRow) {
                            newProductRowBody = {
                              id: null,
                              description: null,
                              stock: null,
                              costAverage: null,
                              costLast: editMode
                                ? !hasProduction
                                  ? transformation.lastCost /
                                    (1 - valueLoss / 100)
                                  : transformation
                                : transformation.lastCost / (1 - valueLoss / 100),
                              storageLocationId: null,
                              frequencies: [],
                              main: productMain
                            };
                          }

                          if (value && value.inputValue) {
                            newProductRowBody = {
                              ...newProductRow,
                              description: value.inputValue,
                              costLast: editMode
                                ? !hasProduction
                                  ? transformation.lastCost /
                                    (1 - valueLoss / 100)
                                  : '0,000'
                                : transformation.lastCost / (1 - valueLoss / 100),
                              main: productMain,
                              consumptionUnitsPrimaryId: unitsConsumptions?.find(el => el?.abbreviation === transformation?.consumptionUnitsPrimary)?.id || unitsConsumptions?.find(el => el?.abbreviation === "un")?.id
                            };

                            setNewStorageLocationRow([]);
                            setNewPeriodicityRow([]);

                            setShowColumnsProducts(false);
                            setHasChanged(true);
                          } else if (value) {
                            newProductRowBody = {
                              id: value?.id,
                              originId: value?.originIdCard,
                              description: e.target.innerText,
                              listCostLast: (
                                value?.lastCost ||
                                value?.approximateCost ||
                                0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              listCostAverage: (
                                value?.averageCost ||
                                value?.averageCostStock ||
                                0
                              ).toLocaleString('pt-br', {
                                minimumFractionDigits: 3
                              }),
                              lastCount: (value?.lastCount || 0).toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              ),
                              storageLocations: value?.setups?.length
                                ? value.setups
                                    .reduce(
                                      (acc, item) => [
                                        ...acc,
                                        item?.config?.storageLocation?.id
                                      ],
                                      []
                                    )
                                    .flat()
                                : [],
                              storageLocationsDescription: value?.setups?.length
                                ? [
                                    ...new Set(
                                      value.setups
                                        .reduce(
                                          (acc, item) => [
                                            ...acc,
                                            item?.config?.storageLocation
                                              ?.description
                                          ],
                                          []
                                        )
                                        .flat()
                                    )
                                  ]
                                : [],
                              frequencies: value?.setups?.length
                                ? value.setups
                                    .reduce(
                                      (acc, item) => [
                                        ...acc,
                                        item?.config?.periodicity?.id
                                      ],
                                      []
                                    )
                                    .flat()
                                : [],
                              frequenciesDescription: value?.setups?.length
                                ? [
                                    ...new Set(
                                      value.setups
                                        .reduce(
                                          (acc, item) => [
                                            ...acc,
                                            item?.config?.periodicity?.description
                                          ],
                                          []
                                        )
                                        .flat()
                                    )
                                  ]
                                : [],
                              consumptionUnitsPrimary:
                                value?.consumptionUnitsPrimary?.abbreviation ||
                                null,
                              costAverage: editMode
                                ? !hasProduction
                                  ? value.averageCost
                                    ? value.averageCostStock.toLocaleString(
                                        'pt-br',
                                        { minimumFractionDigits: 3 }
                                      )
                                    : '0,000'
                                  : '-'
                                : value.averageCost
                                ? value.averageCostStock.toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  })
                                : '0,000',
                              costLast: editMode
                                ? !hasProduction
                                  ? transformation?.lastCost /
                                    (1 - valueLoss / 100)
                                  : '-'
                                : transformation?.lastCost /
                                  (1 - valueLoss / 100),
                              lossPercentage: value?.lossPercentage,
                              main: productMain,
                              consumptionUnitsPrimaryId: unitsConsumptions?.find(el => el?.abbreviation === transformation?.consumptionUnitsPrimary)?.id || unitsConsumptions?.find(el => el?.abbreviation === "un")?.id
                            };
                          }

                          setNewProductRow({
                            description: !value?.inputValue
                              ? ''
                              : value?.inputValue,
                            costLast: !value?.inputValue
                              ? '0,000'
                              : editMode
                              ? !hasProduction
                                ? transformation?.lastCost / (1 - valueLoss / 100)
                                : '-'
                              : transformation?.lastCost / (1 - valueLoss / 100),
                            main: productMain,
                            hasInventory: !!value?.description,
                            un: unitsConsumptions?.find(el => el?.abbreviation === transformation?.consumptionUnitsPrimary) || unitsConsumptions?.find(el => el?.abbreviation === "un")
                          });

                          setHasChanged(true);

                          if (value && !value?.inputValue) {
                            setDataTable((prevState) => ({
                              ...prevState,
                              data: [prevState?.data, newProductRowBody].flat()
                            }));
                            setProductMain(false);

                            document
                              .querySelector('#cardFormMainProductInput')
                              .focus();
                          }
                        } else {
                          handleOptionSelect()
                          setNewProductRow('')
                          setUseOnTransformation('Esse produto ja é resultado de outra transformação')
                        }
                      }
                      }
                      value={newProductRow}
                      inputValue={newProductRow || newProductRow?.description}
                      getOptionLabel={(option) =>
                        option?.description ||
                        option?.inputValue ||
                        option?.title ||
                        ''
                      }
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        if (params?.inputValue !== '') {
                          filtered.push({
                            inputValue: params.inputValue,
                            description: `Criar "${params.inputValue}"`
                          });
                        }
                        return filtered;
                      }}
                      getOptionSelected={(option, value) =>
                        option?.description === value?.description
                      }
                      getOptionDisabled={(option) => {
                        if (option?.id) {
                          return dataTable?.data?.some(
                            (item) => item?.id === option?.id
                          );
                        }

                        return dataTable?.data?.some(
                          (item) => item?.description === option?.inputValue
                        );
                      }}
                      // options={
                      //   products?.filter((product) => product?.description) ||
                      //   []
                      // }
                      options={[]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={params.id}
                          placeholder="Produtos"
                          name="produtos"
                          inputRef={register}
                          error={errors.units}
                          InputLabelProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params?.description}
                        </Typography>
                      )}
                    />
                  </FormControl>

                  <div className="dFlex">
                    <div style={{ width: 30 }} />

                    <Tooltip
                      title="Produto principal"
                      placement="bottom-start"
                    >
                      <Checkbox
                        className="checkBoxSmall ml8"
                        name="productMain"
                        checked={productMain}
                        onChange={(e) => {
                          const hasMainTrue = dataTable.data.some(item => item.main === true);

                          if (hasMainTrue) {
                            setMessagePersonal("Já existe um produto como principal!");
                            return;
                          }
                          setProductMain(e.target.checked);
                          const newProductRowBody = {
                            ...newProductRow,
                            main: e.target.checked
                          };

                          setNewProductRow(newProductRowBody);

                        }}
                        size="small"
                      />
                    </Tooltip>
                  </div>
                </td>

                <td className="description pr12">
                  {newProductRow.description && 
                    <Grid
                      item
                      xs={1}
                      sm={1}
                      id="cardFormTransformUnityInput"
                      className="pr8 pl8"
                    >
                      <Autocomplete
                        id="asynchronous-demo"
                        getOptionSelected={(option, value) =>
                          option.abbreviation === value.abbreviation
                        }
                        getOptionLabel={(option) => option.abbreviation}
                        options={unitsConsumptions || []}
                        fullWidth
                        style={{minWidth: '80px'}}
                        value={newProductRow?.un}
                        className='autocompleteSmall'
                        size="small"
                        onChange={(_, value) => {
                          setHasChanged(true);

                          setNewProductRow({
                            ...newProductRow,
                            un: value
                          })
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            type="text"
                            error={errors.units}
                            variant="outlined"
                            placeholder="Un."
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.abbreviation}
                          </Typography>
                        )}
                      />
                    </Grid>
                  }
                </td>

                <td className="description pr12 right">
                  {!hasProduction
                    ? '-'
                    : (newProductRow?.stock || 0).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })
                  }
                </td>

                {/* <td className="description pr12 right">
                  {(newProductRow?.costAverage || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </td>

                <td className="description pr12 right">
                  {(newProductRow?.costLast || 0).toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })}
                </td> */}

                <td className="description pr12 right">
                  -
                </td>

                <td className="description center">
                  {newProductRow.description && 
                    <Tooltip
                      title="Efetuar Inventário?"
                      placement="bottom-start"
                      style={{ padding: 0, outline: 0 }}
                    >
                      <IconButton>
                        {!newProductRow?.hasInventory &&
                          <img
                            style={{ width: '16px', height: 27 }}
                            className="dBlock mAuto"
                            src={boxGray}
                            alt="box"
                            onClick={() => {
                              setNewProductRow({
                                ...newProductRow,
                                hasInventory: true
                              })
                            }}
                          />
                        }

                        {newProductRow?.hasInventory &&
                          <img
                            style={{ width: '16px', height: 27 }}
                            className="dBlock mAuto"
                            src={boxBlue}
                            alt="box"
                            onClick={() => {

                              setNewProductRow({
                                ...newProductRow,
                                hasInventory: false,
                              });
                            }}
                          />
                        }
                      </IconButton>
                    </Tooltip>
                  }
                </td>

                <td className="description left pl12 pr16">
                  {!newProductRow?.hasInventory || showColumnsProducts ? (
                    newProductRow?.storageLocationsDescription || '-'
                  ) : (
                    <Autocomplete
                      multiple
                      limitTags={1}
                      size="small"
                      onChange={(_, value) => {
                        const formatedBody = {
                          ...newProductRow,
                          storageLocations: value,
                          storageLocationsDescription: value?.map(
                            (el) => el?.description
                          )
                        };

                        if (value) {
                          setNewStorageLocationRow(value);
                          setNewProductRow(formatedBody);
                        }
                      }}
                      onBlur={() => {
                        setHasChanged(true);
                        if (
                          newProductRow?.description &&
                          newPeriodicityRow?.length
                        ) {
                          setDataTable((prevState) => ({
                            ...prevState,
                            data: [prevState?.data, newProductRow].flat()
                          }));
                          setNewProductRow({
                            description: '',
                            frequencies: [],
                            frequenciesDescription: [],
                            storageLocations: [],
                            storageLocationsDescription: [],
                            lossPercentage: '',
                            un: ''
                          });
                          setShowColumnsProducts(true);
                          document
                            .querySelector('#cardFormMainProductInput')
                            .focus();
                        }
                      }}
                      value={newStorageLocationRow}
                      getOptionSelected={(option, value) =>
                        option?.description === value?.description
                      }
                      getOptionLabel={(option) => option?.description}
                      getOptionDisabled={(option) =>
                        newStorageLocationRow?.some(
                          (item) => item?.id === option?.id
                        )
                      }
                      options={storageLocationOptions || []}
                      className="autocompleteSmall withoutLabel"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={params.id}
                          label="Local Armaz."
                          placeholder="Local de Estoque"
                          error={errors.units}
                          required
                          InputLabelProps={{
                            style: { fontFamily: 'Lato', fontSize: '12px' }
                          }}
                        />
                      )}
                      renderOption={(params) => (
                        <Typography
                          style={{ fontSize: '12px', fontFamily: 'Lato' }}
                        >
                          {params.description}
                        </Typography>
                      )}
                    />
                  )}
                </td>

                <td className="description left pl12">
                  {!newProductRow?.hasInventory || showColumnsProducts ? (
                    newProductRow?.frequenciesDescription || '-'
                  ) : (
                    <FormControl fullWidth error={Boolean(errors.frequencies)}>
                      <Autocomplete
                        size="small"
                        multiple
                        limitTags={1}
                        onChange={(_, value) => {
                          const formatedBody = {
                            ...newProductRow,
                            frequencies: value,
                            frequenciesDescription: value?.map(
                              (el) => el?.description
                            )
                          };

                          if (value) {
                            setNewPeriodicityRow(value);
                            setNewProductRow(formatedBody);
                          }
                        }}
                        onBlur={() => {
                          setHasChanged(true);
                          if (
                            newProductRow?.description &&
                            newStorageLocationRow?.length
                          ) {
                            setDataTable((prevState) => ({
                              ...prevState,
                              data: [prevState?.data, newProductRow].flat()
                            }));
                            setNewProductRow({
                              description: '',
                              frequencies: [],
                              frequenciesDescription: [],
                              storageLocations: [],
                              storageLocationsDescription: [],
                              lossPercentage: '',
                              un: ''
                            });
                            setShowColumnsProducts(true);
                            document
                              .querySelector('#cardFormMainProductInput')
                              .focus();
                          }
                        }}
                        value={newPeriodicityRow}
                        getOptionSelected={(option, value) =>
                          option?.description === value?.description
                        }
                        getOptionLabel={(option) => option?.description}
                        getOptionDisabled={(option) =>
                          newPeriodicityRow?.some(
                            (item) => item?.id === option?.id
                          )
                        }
                        options={periodicityOptions || []}
                        className="autocompleteSmall withoutLabel"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            label="Period."
                            placeholder="Contagem"
                            error={errors.units}
                            required
                            InputLabelProps={{
                              style: { fontFamily: 'Lato', fontSize: '12px' }
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.description}
                          </Typography>
                        )}
                      />
                    </FormControl>
                  )}
                </td>

                <td className="description pl12 pr12 left">
                  {newProductRow.description && 
                    <FormControl fullWidth style={{ marginTop: '2px', paddingLeft: '10px' }}>
                      <CurrencyTextField
                        variant="outlined"
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        required
                        type="text"
                        name="percentLossAccepted"
                        fullWidth
                        value={newProductRow?.lossPercentage || ''}
                        InputProps={{
                          maxLength: 8,
                          classes: { input: classes.input },
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        className={`textFieldSmall right ${
                          requiredFields?.includes('yieldTotal') &&
                          'labelError'
                        }`}
                        placeholder="Digite aqui..."
                        onBlur={(_, value) => {
                          setHasChanged(true);
                          setNewProductRow({
                            ...newProductRow,
                            lossPercentage: value
                          })
                        }}
                      />
                    </FormControl>
                  }
                </td>

                <td
                  className="description"
                  style={{ borderLeft: '1px solid #D2D3D7' }}
                >
                  <Tooltip
                    placement="bottom"
                    title="Adicionar Produto"
                  >
                    <IconButton
                      className="modalButton dBlock"
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={() => {
                        const hasStorageLocations = newProductRow?.storageLocations?.length
                        const hasFrequencies = newProductRow?.frequencies?.length

                        if ((hasStorageLocations && !hasFrequencies) || (!hasStorageLocations && hasFrequencies)) {
                          setMessagePersonal("Por favor, preencha as informações para contagem");
                          return null;
                        }

                        setDataTable((prevState) => ({
                          ...prevState,
                          data: [prevState?.data, newProductRow].flat()
                        }));

                        setNewProductRow({
                          description: '',
                          frequencies: [],
                          frequenciesDescription: [],
                          storageLocations: [],
                          storageLocationsDescription: [],
                          lossPercentage: '',
                          un: ''
                        });
                      }}
                      disabled={!newProductRow?.description || validStorages()}
                    >
                      <img
                        style={{ width: '16px', height: 27, display: 'block', margin: 'auto' }}
                        src={!newProductRow?.description || validStorages() ? AddIconDisabled : AddIcon}
                        alt="AddIcon"
                      />
                    </IconButton>
                  </Tooltip>
                </td>
              </tr>

              {/* {!dataTable?.data?.length ? (
                <tr>
                  <th colSpan="8" className="m10 textAlignCenter">
                    Não há dados a serem exibidos.
                  </th>
                </tr>
              ) : null} */}
            </tbody>
          </table>

          {radioValue === 'TRANSFORMATION' ? (
            <Grid
              item
              xs={12}
              sm={12}
              align="right"
              style={{
                background: '#FFF',
                borderBottomRightRadius: '4px',
                borderBottomLeftRadius: '4px',
                paddingBottom: '12px'
              }}
            >
              <table className="resultsTable" border="0" width="100%">
                <tr>
                  <td colSpan="4" height="20" />
                </tr>

                <tr>
                  <td
                    width="84%"
                    align="right"
                    style={{ fontFamily: 'Lato', fontSize: '12px' }}
                  >
                    <b>Rendimento Total:</b>
                  </td>
                  <td
                    colSpan={2}
                    width="11%"
                    align="right"
                    style={{
                      fontSize: '12px',
                      color: '#5062F0',
                      paddingRight: '44px'
                    }}
                  >
                    <b>
                      {transformation.quantity
                        ? (
                            transformation.quantity *
                            (1 - valueLoss / 100)
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : '0,000'}{' '}
                      {ucPrimarySelectedId?.abbreviation || 'un'}
                    </b>
                  </td>
                </tr>

                <tr>
                  <td colSpan="4" height="5" />
                </tr>
              </table>
            </Grid>
          ) : null}
        </Grid>
      )}

      <Snackbar
        open={toastOpen}
        onClose={handleCloseToast}
        autoHideDuration={toastProps?.autoHideDuration || 2000}
        id="catalogToastMessage"
      >
        <Alert
          onClose={handleCloseToast}
          color={toastProps?.severity}
          severity={toastProps?.severity}
        >
          {toastProps?.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={5} className="actionButtons alignCenter">
        {editMode && currentCardDateInfos?.created_at && (
          <div className="dFlex flexColumn mr20">
            <span className="bold fontSizeDefault">
              Data da criação:{' '}
              {moment(currentCardDateInfos?.created_at).format('DD/MM/YYYY')}
            </span>

            <span className="bold fontSizeDefault">
              Última atualização:{' '}
              {moment(currentCardDateInfos?.updated_at).format('DD/MM/YYYY')}
            </span>

            {currentCardDateInfos?.user ? (
              <span className="bold fontSizeDefault">
                Usuário: {currentCardDateInfos?.user}
              </span>
            ) : null}
          </div>
        )}

        <Link
          to={{
            pathname: `/catalogo/fichas/${originId}`,
            state: { prevPath: 'cardsForm' }
          }}
        >
          <Button
            className="defaultButton backButton"
            design="outlined"
            label="Voltar"
          />
        </Link>

        {controlProduction &&
        (radioValue === 'RECIPE'
          ? true
          : lastCountObj?.some((item) => !item.lastCount)) ? (
          <Button
            className="defaultButton submitButton"
            onClick={() => {
              if (!ucPrimarySelectedId) {
                setToastProps({
                  severity: 'error',
                  color: '',
                  message: 'Por favor, preencha a unidade de medida.',
                  timeOutOff: false
                });
                setToastOpen(true);
              } else if (
                editMode
                  ? !lastCountObj.length
                  : lastCountObj.length && radioValue === 'RECIPE'
                    ? !lastCountObj[0].description || !lastCountObj[0].un
                    : lastCountObj.every((item) => item.lastCount)
              ) {
                setToastProps({
                  severity: 'error',
                  color: '',
                  message:
                    radioValue === 'RECIPE'
                      ? 'Por favor, preencha a descrição e a unidade de medida.'
                      : 'Por favor, selecione pelo menos 1 produto.',
                  timeOutOff: false
                });
                setToastOpen(true);
              } else if (editMode && hasControlProduction) {
                onSubmit();
              } else {
                setPopoverOpen(true);
              }
            }}
            design="contained"
            style={{ marginLeft: '20px' }}
            label={saving ? 'Salvando...' : 'Salvar'}
            disabled={blockUserNoAdm || !hasChanged || loading}
          />
        ) : (
          <Button
            disabled={blockUserNoAdm || !hasChanged || loading}
              onClick={() => {
                // if (hasDivergentUnitsTransformation()) {
                //   setInternalModalSettings(
                //     internalModalSettings.map((modal) => modal.name === "confirmDialog" ? {
                //       ...modal,
                //       open: true
                //     } : modal)
                //   )
                // } else {
                  onSubmit()
                // }
              }}
            className="defaultButton submitButton"
            type="submit"
            design="contained"
            style={{ marginLeft: '20px' }}
            label={saving ? 'Salvando...' : 'Salvar'}
          />
        )}
      </Grid>

      <CommonAlert
        open={UseOnTransformation}
        onClose={() => {
          setTimeout(() => {
            setUseOnTransformation('')
          }, 2000)
        }}
        messagePersonal={UseOnTransformation}
        autoHideDuration={6000}
      />

      {
        confirmDialogInfo?.open && (
          <ConfirmModal
            open={
              confirmDialogInfo.open
            }
            title={
              confirmDialogInfo?.title
            }
            content={
              <Typography className='bold'>
                {`Identificamos que há uma diferença entre a unidade de consumo da ficha (${ucPrimarySelectedId?.abbreviation}) e a unidade de consumo do produto a ser transformado (${transformation?.consumptionUnitsPrimary}). Deseja prosseguir mesmo assim?`}
              </Typography>
            }
            confirmText="Sim"
            onConfirm={() => {
              onSubmit()
              setInternalModalSettings(
                internalModalSettings.map((modal) => modal.name === "confirmDialog" ? {
                  ...modal,
                  open: false
                } : modal)
              )
            }}
            cancelText="Não"
            onClose={() => {
              setInternalModalSettings(
                internalModalSettings.map((modal) => modal.name === "confirmDialog" ? {
                  ...modal,
                  open: false
                } : modal)
              )
            }}
          />
        )
      }

      <Popover
        open={hasControlProduction && editMode ? false : popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setPopoverOpen(false)}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', width: '70%' }
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <Grid
          container
          xs={12}
          justify="center"
          spacing={1}
          style={{ padding: '10px', margin: 0, borderRadius: '8px' }}
        >
          <Grid item xs={12} className="mb10">
            <Typography className="bold textAlignCenter">
              Adicione o estoque atual
            </Typography>
          </Grid>

          <table
            className="desktop quoteSolicitationsTable"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th width="25%" className="subtitle pr12">
                  &nbsp;
                </th>
                <th width="30%" className="subtitle">
                  LOCAL DE ESTOQUE
                </th>
                <th width="30%" className="subtitle pl12">
                  CONTAGEM
                </th>
                <th width="13%" className="subtitle pl12">
                  QUANTIDADE
                </th>
                <th width="2%" className="subtitle">
                  &nbsp;
                </th>
              </tr>
            </thead>

            <tbody>
              {lastCountObj?.length &&
                (radioValue === 'RECIPE'
                  ? lastCountObj
                  : lastCountObj.filter((item) => !item.lastCount)
                ).map((product, index) => (
                  <tr>
                    <td id={`description${index}`} className="description p12">
                      {product.description &&
                        ValidationLength(product.description, 30)}
                    </td>

                    <td
                      id={`storageLocations${index}`}
                      className="description pb10"
                    >
                      <Autocomplete
                        options={storageLocationOptions || []}
                        getOptionLabel={(option) => option.description}
                        className="autocompleteSmall"
                        multiple
                        size="small"
                        limitTags={1}
                        value={
                          product.storageLocations &&
                          storageLocationOptions?.filter((item) =>
                            product?.storageLocations?.includes(item?.id)
                          )
                        }
                        onChange={(_, value) => {
                          setStorageLocation(value);
                          setLocalStorageError(false);

                          setLastCountObj(
                            lastCountObj.map((element) => {
                              if (element.description === product.description) {
                                return {
                                  ...element,
                                  storageLocations: value.map(
                                    (storageLocation) => storageLocation.id
                                  )
                                };
                              }
                              return element;
                            })
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            type="text"
                            name="storageLocation"
                            variant="outlined"
                            required
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.description}
                          </Typography>
                        )}
                      />
                    </td>

                    <td
                      id={`periodicity${index}`}
                      className="description p12 pb10"
                    >
                      <Autocomplete
                        options={periodicityOptions || []}
                        getOptionLabel={(option) => option.description}
                        className="autocompleteSmall"
                        multiple
                        size="small"
                        limitTags={1}
                        value={
                          (product?.frequencies || product?.periodicities) &&
                          periodicityOptions?.filter(
                            (item) =>
                              product?.frequencies?.includes(item.id) ||
                              product?.periodicities?.includes(item.id)
                          )
                        }
                        onChange={(_, value) => {
                          setPeriodicities(value);
                          setPeriodicityError(false);

                          setLastCountObj(
                            lastCountObj.map((element) => {
                              if (element.description === product.description) {
                                return {
                                  ...element,
                                  frequencies: value.map(
                                    (periodicity) => periodicity.id
                                  ),
                                  edited: true
                                };
                              }
                              return element;
                            })
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id={params.id}
                            type="text"
                            name="periodicity"
                            variant="outlined"
                            required
                            placeholder="Selecione..."
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password'
                            }}
                          />
                        )}
                        renderOption={(params) => (
                          <Typography
                            style={{ fontSize: '12px', fontFamily: 'Lato' }}
                          >
                            {params.description}
                          </Typography>
                        )}
                      />
                    </td>

                    <td
                      id={`quantity${index}`}
                      className="description p12 pb10"
                    >
                      <CurrencyTextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        currencySymbol="R$"
                        minimumValue="0"
                        outputFormat="string"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        name="realStockValue"
                        className="textFieldSmall right"
                        placeholder="Digite aqui..."
                        onBlur={(_, value) => {
                          setLastCountObj(
                            lastCountObj.map((element) => {
                              if (element.description === product.description) {
                                return {
                                  ...element,
                                  realStock: value
                                };
                              }
                              return element;
                            })
                          );
                        }}
                        defaultValue={product.realStock}
                        disabled={false}
                      />
                    </td>

                    <td id={`unit${index}`} className="description p12">
                      {product.consumptionUnitsPrimary
                        ? product.consumptionUnitsPrimary
                        : product.un?.abbreviation}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          <Grid
            container
            spacing={5}
            xs={12}
            className="dFlex justifyCenter mt10"
          >
            <Grid item xs={6} className="dFlex justifyEnd">
              <Button
                design="outlined"
                style={{
                  width: '40px',
                  borderRadius: '8px',
                  borderColor: '#313347',
                  color: '#313347',
                  outline: 0
                }}
                onClick={() => setPopoverOpen(false)}
                label="Cancelar"
                color="primary"
              />
            </Grid>

            <Grid item xs={6} className="dFlex justifyStart">
              <Button
                autoFocus
                style={{ width: '40px', borderRadius: '8px', outline: 0 }}
                design="contained"
                onClick={() => {
                  if (
                    radioValue === 'TRANSFORMATION'
                      ? lastCountObj.filter((item) => !item.lastCount).length
                        ? lastCountObj
                            .filter((item) => !item.lastCount)
                            .map(
                              (item) =>
                                !item.frequencies ||
                                !item.frequencies.length ||
                                !item.storageLocations ||
                                !item.storageLocations.length ||
                                !item.realStock
                            )
                            ?.includes(true)
                        : false
                      : lastCountObj.length
                      ? lastCountObj
                          .map(
                            (item) =>
                              ((!item.frequencies ||
                                !item.frequencies.length) &&
                                (!item.periodicities ||
                                  !item.periodicities.length)) ||
                              !item.storageLocations ||
                              !item.storageLocations.length ||
                              !item.realStock
                          )
                          ?.includes(true)
                      : false
                  ) {
                    setLastCountErr(true);
                  } else {
                    onSubmit();
                  }
                }}
                label="Confirmar"
                color="primary"
              />
            </Grid>
          </Grid>
        </Grid>

        <Snackbar
          open={lastCountErr}
          autoHideDuration={2000}
          onClose={() => setLastCountErr(false)}
        >
          <Alert
            onClose={() => setLastCountErr(false)}
            severity="error"
            color="error"
          >
            Por favor, preencha todos os campos
          </Alert>
        </Snackbar>
      </Popover>

      <Popover
        open={imagePopoverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setImagePopoverOpen(false)
          setCurrentProduct('')
          setAnchorEl(null);
        }}
        className="cardProductsImagePopover"
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', width: '70%' }
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <div className="productRightCard widthImageBox">
          <div className={`cropDataMediumBox `}>
            <Tooltip
              arrow
              placement="right"
              disableHoverListener={!currentProduct?.images?.url}
              title={
                <div
                  className={`cropDataSmallBox skuImgPreview mr0 ${
                    currentProduct?.images?.url && 'bgWhite'
                  } largeSize`}
                >
                  <img src={currentProduct?.images?.url} alt="Card" />
                </div>
              }
            >
              <div
                className={`cropDataMediumBox ${
                  currentProduct?.images?.url && 'bgWhite'
                }`}
              >
                <img src={currentProduct?.images?.url || DefaultImage} alt={`${currentProduct?.images?.url ? 'Card Image' : 'No Menu Image'}`} />
              </div>
            </Tooltip>
          </div>

          <div className="dFlex flexColumn alignEnd mt8">
            <div className='dFlex justifyBetween w100'>
              <div className='dFlex alignCenter'> 
                {currentProduct?.images?.url &&
                  <img
                    style={{
                      margin: '0px 10px 0px 0px',
                      width: '12px'
                    }}
                    title="Remover imagem"
                    className="cursorPointer"
                    src={CloseGrayIcon}
                    alt="CloseGrayIcon"
                    onClick={() => {
                      setHasChanged(true);
                      handleClearFileInput(currentProduct?.index)
                      handleRemoveImage()
                    }}
                  />
                }
              </div>
              
              {currentProduct?.images?.url ? (
                <Button
                  className="defaultButton backButton sizeButtonMenu"
                  design="outlined"
                  label="Escolher outra imagem"
                  onClick={(e) => handleSelectImage(e, currentProduct?.id)}
                  style={{ outline: 0 }}
                />
              ) : (
                <Button
                  className="defaultButton submitButton sizeButtonMenu"
                  design="outlined"
                  label="Selecionar imagem"
                  onClick={(e) => handleSelectImage(e, currentProduct?.id)}
                  style={{ outline: 0 }}
                />
              )}
            </div>

            <input
              id={`fileInput${currentProduct?.id}`}
              type="file"
              onChange={(e) => onChangeProductImage(e)}
              className="dNone"
            />
            
            <input
              id="fileInput"
              type="file"
              onChange={onChangeProductImage}
              className="dNone"
            />
          </div>
        </div>
      </Popover>
    </Container>
  );

  const buildCardProducts = (card) => {
    const arrayProduct = [];
    let noSkuCard = false;

    switch (card.type) {
      case 'RECIPE':
        card.frequencies = card.frequencies
          ? {
              _id: card.frequencies.frequencyId,
              description: card.frequencies.description
            }
          : null;

        card.storageLocation = card.storageLocation
          ? {
              _id: card.storageLocation.storageLocationId,
              description: card.storageLocation.description
            }
          : null;

        if (card.items) {
          card.items.forEach((product, productIndex) => {
            noSkuCard = !(
              noSkuCard === false && product.products.approximateCost
            );

            if (product.products) {
              arrayProduct.push({
                productIndex,
                id: product?.products?.id,
                originIdCard: product?.products?.originIdCard,
                type: product?.products?.type,
                description: product?.products?.description || '',
                consumptionUnitsPrimary:
                  product?.products?.consumptionUnitsPrimary?.abbreviation ||
                  null,
                consumptionUnitsPrimaryId:
                  product?.products?.consumptionUnitsPrimary?.id || null,
                listQuantity: product.quantity,
                quantity: product.quantity,
                lastCount: product.products?.lastCount || '0,000',
                listCostAverage:
                  product.products?.averageCost ||
                  product.products?.averageCostStock ||
                  '0,000',
                listCostLast:
                  product.products?.lastCost ||
                  product.products?.lastCostStock ||
                  product.products?.approximateCost ||
                  '0,000',
                costAverage: product.products.averageCost
                  ? product.products.averageCostStock
                  : '0,000',
                costLast:
                  product.products.lastCostStock &&
                  !product.products.approximateCost
                    ? product.products.lastCostStock
                    : product.products.type === 'INTERNAL'
                    ? product.products.lastCost ||
                      product.products.lastCostStock ||
                      0
                    : product.products.approximateCost || '0,000',
                stock: product.products.realStock
                  ? product.products.realStock
                  : '0,000',
                listCost: product.products ? product.cost : '0,000',
                cost: product.products ? product.cost : '0,000',
                itemProcess: product?.itemProcess || '',
                main: product?.main,
                images: product?.image,
                showImage: product?.products?.skus?.length 
                  ? !!product?.products?.skus[0]?.skus?.skuBloom?.images
                  : false,
              });
            }
          });
        }

        card.items = arrayProduct.sort((a, b) =>
          a.productIndex > b.productIndex ? 1 : -1
        );
        break;

      case 'TRANSFORMATION':
        if (
          !card.itemsTransformations ||
          !card.itemsTransformations.unitsMeasurements
        )
          return;

        const {
          itemsTransformations: {
            productId = '',
            products: { realStock = '' } = {},
            unitsMeasurements: { id = '', abbreviation = '' } = {},
            cost = '',
            products: {
              averageCost,
              averageCostStock,
              lastCost,
              lastCostStock,
              approximateCost
            } = {},
            quantity = '',
            products = ''
          } = {}
        } = card;

        card.transformation = {
          type: 'PRODUCT',
          productId,
          stock: realStock,
          consumptionUnitsPrimaryId: id,
          consumptionUnitsPrimary: abbreviation,
          cost,
          averageCost: averageCostStock || averageCost,
          lastCost: lastCost || lastCostStock || approximateCost || '0,000',
          quantity,
          product: {
            ...products,
            images: card?.itemsTransformations?.image
          },
          images: card?.itemsTransformations?.image
        };

        if (card.items) {
          card.items.forEach((product, productIndex) => {
            noSkuCard = !(
              noSkuCard === false && product?.products?.skus?.length
            );

            if (product.products) {
              arrayProduct.push({
                ...product,
                productIndex,
                id: product.productId,
                originIdCard: product?.products?.originIdCard,
                un: product?.products?.consumptionUnitsPrimary,
                hasInventory: product?.products?.storageLocations?.length && product?.products?.frequencies?.length,
                type: product?.products?.type,
                description: product.products
                  ? product.products.description
                  : '',
                listQuantity: product.quantity,
                quantity: product.quantity,
                lastCount:
                  product.products && product.products.lastCount
                    ? product.products.lastCount
                    : '0,000',
                listCostAverage:
                  product.products?.averageCostStock ||
                  product.products?.averageCost ||
                  '0,000',
                listCostLast:
                  product.products &&
                  product.products.lastCost &&
                  product.products.skus.length
                    ? product.products.lastCostStock
                    : product.products.approximateCost
                    ? product.products.approximateCost
                    : '0,000',
                costAverage:
                  product.products?.averageCostStock ||
                  product.products?.averageCost ||
                  '0,000',
                costLast:
                  product?.products?.lastCostStock &&
                  !product?.products?.approximateCost
                    ? product.products.lastCostStock
                    : product.products.type === 'INTERNAL'
                    ? product.products.lastCost ||
                      product.products.lastCostStock ||
                      0
                    : product?.products?.approximateCost || '0,000',
                stock: product?.products?.realStock || '0,000',
                listCost: product.products ? product.cost : '0,000',
                cost: product.products ? product.cost : '0,000',
                lossPercentage: product?.products
                  ? product?.products?.lossPercentage
                  : 0,
                main: product?.main,
                images: product?.image,
                showImage: !!product?.products?.image?.url
              });
            }
          });
        }

        card.items = arrayProduct;
        break;
      default:
        break;
    }

    card.noSkuCard = noSkuCard;
  };

  const getCategories = useCallback(
    (query = '') => {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/categories?page=1&size=10000&originId=${originId}&type=${categoryType}&description=${query}`
        );
        const category = await response.json();
        setCategories(category?.content);
      })();
    },
    [environments.catalog, originId, categoryType]
  );

  const getProducts = useCallback(
    (query = '') => {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/products?page=1&size=10&originId=${originId}&useOnCards=true&description=${query}`
        );
        const jsonProducts = await response.json();
        setProducts(jsonProducts.content);
      })();
    },
    [environments.catalogV2, originId]
  );

  const getPeriodicities = useCallback(
    () => {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/periodicities?page=1&size=100&originId=${originId}`
        );
        const jsonPeriodicities = await response.json();
        setPeriodicityOptions(jsonPeriodicities?.content || []);
        setEnableToLoadPeriodicities(true)
      })();
    },
    [environments.catalog, originId]
  );

  const getStorageLocations = useCallback(
    () => {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/storageLocations?page=1&size=100&originId=${originId}`
        );
        const jsonStorageLocations = await response.json();
        setStorageLocations(jsonStorageLocations?.content || []);
        setEnableToLoadStorageLocations(true)
      })();
    },
    [environments.catalog, originId]
  );

  const getMenuOptions = useCallback(
    () => {
      (async () => {
        const response = await fetch(
          `${environments.catalog}/menu?page=1&size=100&originId=${originId}&autoComplete=true`
        );
        const jsonMenuOptions = await response.json();
        setMenuOptions(jsonMenuOptions?.content || []);
        setEnableToLoadMenuOptions(true)
      })();
    },
    [environments.catalog, originId]
  );

  const getUnitOptions = useCallback(
    () => {
      (async () => {
        axios
          .get(`${environments.catalog}/consumptionUnits?page=1&size=50`)
          .then((response) => {
            const units = response.data.content;
            setUnitsConsumptions(units);
            setEnableToLoadUnits(true)
          })
          .catch((error) => {});
      })();
    },
    [environments.catalog, originId]
  );

  const BodyModal = (
    <Register
      userInfo={userInfo}
      menuInfo={menuInfo}
      handleModalToggle={() => {
        setOpenModalMenu(!openModalMenu);
      }}
      menuId={menuId}
      salesAverageDiscount={salesAverageDiscount}
      percentageDiscountValue={percentageDiscountValue}
      openModalMenu={openModalMenu}
      setSalesAverageDiscount={setSalesAverageDiscount}
      onlyView
    />
  );

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    setOriginId(userInfo.companiesActive.id);
    setUserGroups({ groups: userInfo.companies.groups });
  }, [userInfo]);

  // Seta Origin ID e Name
  useEffect(() => {
    setOriginId(paramsID);
    getCategories();
    getProducts();
    getCourts();
    getPeriodicities();
    getStorageLocations();
    getMenuOptions();
    getUnitOptions()
  }, [paramsID]);

  useEffect(() => {
    if (selectedProduct) {
      const arraySkus = [];
      selectedProduct.skus.forEach((skus) => {
        arraySkus.push({
          id: skus.skus ? skus.skus.id : null,
          description: skus.skus ? skus.skus.description : null,
          quantity: skus.skus
            ? parseFloat(skus.skus.quantity).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          unitsMeasurements: skus.skus
            ? skus.skus.unitsMeasurements.abbreviation
            : null,
          purchaseAverage:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseAverage
              : null,
          purchaseLast:
            skus.skus && skus.skus.restaurants.length
              ? skus.skus.restaurants[0].purchaseLast
              : null,
          conversion: skus.skus
            ? parseFloat(skus.conversion).toLocaleString('pt-br', {
                minimumFractionDigits: 3
              })
            : null,
          priceUc: skus ? skus.priceUc : null
        });
      });

      selectedProduct.skus = arraySkus;
    }

    setEditProducts(selectedProduct);
    setOnlyViewProduct(true);
    setModalStates({
      ...modalStates,
      modalProduct: true
    });
  }, [selectedProduct]);

  // Parâmetros
  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${originId}/parameters`
      );
      const jsonParameters = await response.json();
      if (jsonParameters) {
        const { product: { type = '' } = {} } = jsonParameters;
        setParameters(type);
      }
    })();
  }, [originId]);

  useEffect(() => {
    if (cardId && enableToLoadPeriodicities && enableToLoadStorageLocations && enableToLoadMenuOptions && enableToLoadUnits) {
      setLoading(true);
      getCardProductionInfo();

      /* GET CARD */
      axios
        .get(`${environments.catalog}/cards/${cardId}?originId=${originId}`)
        .then((response) => {
          const currentCard = response?.data;          
          const cardDaysIds = currentCard?.daysWeeks?.map(
            (item) => item?.daysWeekId
          );
          const storageLocationIds =
            currentCard?.product[0]?.setups
              .reduce(
                (acc, item) => [
                  ...acc,
                  item?.config && item?.config?.storageLocation?.id
                ],
                []
              )
              .flat() || [];

          setCourtPreSelected(response?.data.court);
          const periodicitiesIds =
            currentCard?.product[0]?.setups
              .reduce(
                (acc, item) => [
                  ...acc,
                  item?.config && item?.config?.periodicity?.id
                ],
                []
              )
              .flat() || [];

          buildCardProducts(currentCard);

          setPreparationMethod(currentCard?.preparationMethod);
          setCurrentCardDateInfos({
            created_at: currentCard?.created_at,
            updated_at: currentCard?.updated_at,
            user: currentCard?.user || null,
            restaurantId: currentCard?.restaurantId
          });
          setProductTransformation(currentCard?.transformation?.product);

          setStorageLocation(
            storageLocationOptions?.filter((el) =>
              storageLocationIds?.includes(el.id)
            )
          );
          setPeriodicities(
            periodicityOptions?.filter((el) =>
              periodicitiesIds?.includes(el.id)
            )
          );
          setImageData({
            ...imageData,
            data: {
              ...imageData.data,
              images: currentCard?.product?.length ? currentCard?.product[0]?.image : ''
            }
          });

          setPercentAccepted(
            currentCard?.type === 'RECIPE'
              ? {
                  variation: currentCard?.product[0]?.variationPercentage,
                  loss: currentCard?.product[0]?.lossPercentage
                }
              : {
                  variation:
                    currentCard?.itemsTransformations?.products
                      ?.variationPercentage || 0,
                  loss: currentCard?.items?.length
                    ? currentCard?.items[0].products?.lossPercentage
                    : 0
                }
          );
          setRestaurants(currentCard);
          setTreeView(currentCard?.categories?.id || '');
          setUcPrimarySelectedId(
            currentCard?.type === 'RECIPE'
            ? currentCard?.consumptionUnits ||
                currentCard?.unitsMeasurementsMenu?.unitsMeasurements || null
            : currentCard?.items[0]?.unitsMeasurements
          );
          setHasControlProduction(currentCard?.controlProduction);
          setRadioValue(currentCard?.type || '');
          setDraft(currentCard?.draft || false);
          setActive(currentCard?.status || 'ACTIVE');
          setResales(currentCard?.resale || false);
          setControlProduction(currentCard?.controlProduction || false);
          setLowType(currentCard?.lowType || "NORMAL")

          if (currentCard?.itemsMenu?.length) {
            setItemsMenu(menuOptions?.find(menu => menu.id === currentCard?.itemsMenu[0]?.menu?.id) || '')
            setItemsMenuFormatted({
              added: [],
              deleted: [],
              noChanged: [menuOptions?.find(menu => menu.id === currentCard?.itemsMenu[0]?.menu?.id)]
            })
          }

          setPriority(currentCard?.priority || false);
          setSelectedStartTime(
            currentCard?.startTime
              ? setMinutes(
                  setHours(
                    new Date(),
                    currentCard?.startTime[0] + currentCard?.startTime[1]
                  ),
                  currentCard?.startTime[3] + currentCard?.startTime[4]
                )
              : null
          );
          setSelectedEndTime(
            currentCard?.endTime
              ? setMinutes(
                  setHours(
                    new Date(),
                    currentCard?.endTime[0] + currentCard?.endTime[1]
                  ),
                  currentCard?.endTime[3] + currentCard?.endTime[4]
                )
              : null
          );
          setDaysSelect(
            formattedDaysOptions?.filter((day) =>
              cardDaysIds?.includes(day.id)
            ) || []
          );
          setValidade(currentCard?.quantityValidDays || null);
          setProductionTime(
            currentCard?.startTime || currentCard?.endTime
              ? true
              : !!(currentCard?.daysWeeks && currentCard?.daysWeeks?.length > 0)
          );
          setCostTotal(currentCard?.costs?.costTotal || 0);
          setDescription(currentCard?.description?.trim() || '');
          setAveragePriceSimulation(currentCard?.averagePriceSimulation || 0);
          setMarkupSimulation(currentCard?.markupSimulation || 0);
          setCmvSimulation(currentCard?.cmvSimulation || 0);
          setValueLoss(currentCard?.productions?.loss || 0);
          setLastCountObj(
            currentCard?.type === 'RECIPE'
              ? [
                  {
                    ...currentCard?.product[0],
                    description: currentCard?.description,
                    un: currentCard?.consumptionUnits,
                    storageLocations: storageLocationIds,
                    periodicities: periodicitiesIds
                  }
                ]
              : currentCard?.items
          );
          setMargin(
            currentCard?.menu?.length ? currentCard?.menu[0]?.margin : ''
          );
          setCmv(currentCard?.menu?.length ? currentCard?.menu[0]?.cmv : '');
          setMarkup(
            currentCard?.menu?.length ? currentCard?.menu[0]?.markup : ''
          );
          setSalesAverage(
            currentCard?.menu?.length ? currentCard?.menu[0]?.salesAverage : ''
          );
          setProduction({
            yield: currentCard?.productions?.yield || null,
            yieldTotal: currentCard?.productions?.yieldTotal || null
          });
          setProductionMin(currentCard?.productionMin || '');
          setTransformation(
            currentCard?.transformation || {
              type: 'product',
              productId: null,
              stock: 0,
              consumptionUnitsPrimaryId: null,
              consumptionUnitsPrimary: null,
              cost: 0,
              averageCost: 0,
              lastCost: 0,
              quantity: 0
            }
          );
          setCategoryType(currentCard?.type === 'MENU' ? 'Sales' : 'Shopping');
          setDataTable({ data: sortDataInfoItems(currentCard?.items) })

          setEditMode(true);
          setLoading(false);
        })
        .catch((error) => {
          setToastOpen(true);
          setToastProps({
            description: `Ficha não encontrada, por favor confirme se o id do restaurante e da ficha estão corretos`,
            status: 'error',
            message: 'Por favor, preencha as informações de produção.',
            timeOutOff: false
          });
          setLoading(false);
          console.log(error);
        });
    } else {
      const storageLocationIds =
        cardInfo?.data?.product?.length &&
        cardInfo?.data?.product[0]?.setups
          .reduce(
            (acc, item) => [
              ...acc,
              item?.config && item?.config?.storageLocation?.id
            ],
            []
          )
          .flat() || [];

      const periodicitiesIds = cardInfo?.data?.product?.length
        ? cardInfo?.data?.product[0]?.setups
            .reduce(
              (acc, item) => [
                ...acc,
                item?.config && item?.config?.periodicity?.id
              ],
              []
            )
            .flat() || []
        : [];

      setStorageLocation(
        storageLocationOptions?.filter((el) =>
          storageLocationIds?.includes(el.id)
        )
      );
      setPeriodicities(
        periodicityOptions?.filter((el) => periodicitiesIds?.includes(el.id))
      );
    }
  }, [enableToLoadPeriodicities, enableToLoadStorageLocations, enableToLoadMenuOptions, enableToLoadUnits]);

  useEffect(() => {
    if (courtPreSelected && courts.length) {
      setCourtSelected(courtPreSelected);
    }
  }, [courtPreSelected, courts]);

  return (
    <Layout>
      <GlobalStyle />

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        className="technicalSheetRegisterContainer mb16"
      >
        <div className="mainContainers">
          <Grid
            className="mb16"
            container
            item
            xs={12}
            justify="flex-start"
            align="center"
          >
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '16px' }}
            >
              <b>Cadastro de fichas</b>
            </Typography>
          </Grid>

          {form()}
        </div>
      </Grid>

      {modalStates.modalProduct && productInfo?.data && (
        <RegisterProductModal
          dialogOpen={modalStates.modalProduct}
          onlyView={onlyViewProduct}
          isModal
          consumptionUnitOptions={unitsConsumptions}
          periodicityOptions={periodicities}
          productDataModal={{
            data: selectedProduct
          }}
          storageLocationOptions={storageLocationOptions}
          onClose={() => {
            setEditProducts('');
            setModalStates({ ...modalStates, modalProduct: false });
          }}
        />
      )}

      {selectedCard && (
        <TechnicalSheetDialog
          maxWidth={null}
          open={modalStates.modalTechnicalSheet}
          handleClose={() =>
            setModalStates({ ...modalStates, modalTechnicalSheet: false })
          }
          cardDataTable={cardDataTable}
          cardTransformation={cardTransformation}
          selectedCard={selectedCard}
          ucPrimarySelectedId={ucPrimarySelectedId}
        />
      )}

      {openModalMenu && (
        <Dialog
          open={openModalMenu}
          onClose={() => {
            setOpenModalMenu(false);
            setMenuId(null)
            setSalesAverageDiscount(null);
            setPercentageDiscountValue(null);
          }}
          className="defaultModal menuModal"
          maxWidth="md"
          fullWidth
          PaperComponent={PaperComponent}
        >
          <DialogTitle
            id="draggable-dialog-title"
            className="modalHeader cursorMove"
          >
            <Typography
              className="bold textAlignCenter"
              style={{
                fontSize: 16
              }}
            >
              CADASTRO DE CARDÁPIO
            </Typography>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenModalMenu(false);
                setMenuId(null)
                setSalesAverageDiscount(null);
                setPercentageDiscountValue(null);
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none' }}
            >
              <img src={CloseIcon} alt="CloseIcon" />
            </IconButton>
          </DialogTitle>

          <DialogContent className="p16">{BodyModal}</DialogContent>
        </Dialog>
      )}

      {modalSettings.find((modal) => modal.name === 'imageCropper')?.open && (
        <ImageCropper
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          getCropData={getCropData}
          obj={imageData}
          objMultiple={currentProduct ? dataTable : ''}
          currentItem={dataTable?.data?.find((item) => item?.id === currentProduct?.id)}
          setObj={setImageData}
          setObjMultiple={setDataTable}
          setCropper={setCropper}
          handleSelectImage={handleSelectImage}
          handleClearFileInput={handleClearFileInput}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'confirmItemsMenuDialog')?.open && (
        <ConfirmModal
          disableBackDrop
          customClass="fakeDialogTitle"
          open={
            modalSettings.find((modal) => modal.name === 'confirmItemsMenuDialog').open
          }
          title={
            <Typography className="bold">
              Deseja realmente utilizar esse item na baixa de venda?
            </Typography>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmItemsMenuDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentItem: null,
                    }
                  : modal
              )
            );
            
            setOpenModalMenu(false);
            setMenuId(null)
            setSalesAverageDiscount(null);
            setPercentageDiscountValue(null);
          }}
          cancelText="Não"
          onConfirm={() => {
            const {currentItem} = modalSettings.find((modal) => modal.name === 'confirmItemsMenuDialog')
            setHasChanged(true);

            setItemsMenuFormatted({
              added: [currentItem],
              deleted: [itemsMenu],
              noChanged: []
            })

            setItemsMenu(currentItem)

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmItemsMenuDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentItem: null,
                    }
                  : modal
              )
            );
            
            setOpenModalMenu(false);
            setMenuId(null)
            setSalesAverageDiscount(null);
            setPercentageDiscountValue(null);
          }}
          confirmText="Sim"
        />
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products,
  menuInfo: state.menu,
  cardInfo: state.cards
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...UserActions, ...ProductsActions, ...MenuActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterManufacturer);
