/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, Suspense, useMemo } from 'react';

import {
  IconButton,
  Tooltip
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import CmvAndCountSetupDialog from 'components/Dialogs/CmvAndCountSetup';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import CmvAndCountSetupIcon from 'images/icons/objects/boxesAndPackages/packageGrayConfigYellowLightBlueBgRounded.svg';
import StorageLocationForm from 'pages/inventory/storage-Locations/StorageLocationForm';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';

import CmvTheoreticalInfos from './CmvTheoreticalInfos';
import PerformedCmvInfos from './PerformedCmvInfos';
import SalesInfos from './SalesInfos';
import SimpleLineGraphic from './SimpleLineGraphic';

const DifferenceInCount = React.lazy(() =>
  import('components/Dialogs/DifferenceInCount')
);

function CmvDashboard({ userInfo }) {
  const actualDate = moment();
  const actualDateLessOne = moment(actualDate)?.subtract(1, 'day')?.format('YYYY-MM-DD');
  const actualDateLessSeven = moment(actualDate)?.subtract(7, 'day')?.format('YYYY-MM-DD');
  const { id: paramsID } = useParams();
  const queryParams = useLocation()?.search;
  const companyActive = userInfo.companiesActive.name;
  const [getId, setId] = useState(paramsID);
  const [filters] = useState([]);
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [loading, setLoading] = useState(false);
  const [salesInfoType, setSalesInfoType] = useState(true);
  const [salesInfos, setSalesInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvTheoreticalInfos, setCmvTheoreticalInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvPerformedInfos, setCmvPerformedInfos] = useState({
    loading: true,
    content: ''
  });
  const [cmvChartInfos, setCmvChartInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [showGraphArea, setShowGraphArea] = useState(
    queryParams?.includes('showGraph')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('showGraph'))
          ?.split('=')[1]
      : ''
  );
  const environments = Environment(process.env.REACT_APP_ENV);
  const [updateStorageLocations, setUpdateStorageLocations] = useState(false)
  const [loadedStartDateDays, setLoadedStartDateDays] = useState(false)
  const [loadedEndDateDays, setLoadedEndDateDays] = useState(false)
  const [startDateDisabledDays, setStartDateDisabledDays] = useState('')
  const [endDateDisabledDays, setEndDateDisabledDays] = useState('')
  const [modalSettings, setModalSettings] = useState([
    { id: 1, name: 'cmvAndCountSetupDialog', open: false, fullScreen: false },
    { id: 2, name: 'storageLocationDialog', open: false, fullScreen: false },
    { id: 3, name: 'differenceInCount', currentRestaurant: '', open: false, fullScreen: null, maxWidth: null },
  ]);
  const onDateFilterStartMonthChange = (startDate, endDate, isFirstLoad = false) => {
    const currentStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').startOf('month')?.format('YYYY-MM-DD')
    const currentEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').endOf('month')?.format('YYYY-MM-DD')

    axios
      .get(
        `${environments.dashBoard}/cmvCount/dates?restaurantId=${getId}&startDate=${currentStartDate}&endDate=${currentEndDate}`
      )
      .then((response) => {
        const defaultSevenDays = [...Array(7).keys()]
        const hasDates = response?.data?.dates?.length
        const hasPeriodicity = response?.data?.periodicity
        const disabledDays = hasDates 
          ? response?.data?.dates?.sort((a, b) => (a > b ? 1 : -1)) 
          : hasPeriodicity && isFirstLoad
            ? defaultSevenDays?.map(el => moment()?.subtract(el + 1, 'day')?.format('YYYY-MM-DD'))
            : ''
        
        const newFilters = [...filterFields];

        if (isFirstLoad) {
          const currentDate = disabledDays !== '' 
            ? disabledDays?.length > 1 
              ? moment(disabledDays?.at(-2)).add(3, 'hours') 
              : moment(disabledDays[0]).add(3, 'hours') 
            : moment(actualDateLessSeven).add(3, 'hours')
          const hasStartDate = filtersChanged?.find(el => el?.param === 'startDate')

          newFilters[0][0].initialDate = currentDate;

          let updatedFiltersChanged = []

          if (hasStartDate) {
            updatedFiltersChanged = filtersChanged?.map(el => {
              if (el?.param === 'startDate') {
                return {
                  ...el,
                  value: currentDate?.format('YYYY-MM-DD'), 
                  urlParam: `startDate=${currentDate?.format('YYYY-MM-DD')}`
                }
              }

              return {
                ...el
              }
            })
          } else {
            updatedFiltersChanged = [
              ...filtersChanged, 
              {
                param: 'startDate', 
                value: currentDate?.format('YYYY-MM-DD'), 
                urlParam: `startDate=${currentDate?.format('YYYY-MM-DD')}`
              }
            ]
          }
          
          setFiltersChanged(updatedFiltersChanged)

          onDateFilterEndMonthChange("", "", isFirstLoad, updatedFiltersChanged)
        }

        newFilters[0][0].shouldDisableStartDate = disabledDays !== '' ? disabledDays?.map(el => moment(el)?.format('D')) : disabledDays;

        setFilterFields(newFilters);
        
        setLoadedStartDateDays(true)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  
  const onDateFilterEndMonthChange = (startDate, endDate, isFirstLoad = false, updatedFiltersChanged) => {
    const currentStartDate = startDate ? moment(startDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').startOf('month')?.format('YYYY-MM-DD')
    const currentEndDate = endDate ? moment(endDate)?.format('YYYY-MM-DD') : moment(actualDateLessSeven).add(3, 'hours').endOf('month')?.format('YYYY-MM-DD')

    axios
      .get(
        `${environments.dashBoard}/cmvCount/dates?restaurantId=${getId}&startDate=${currentStartDate}&endDate=${currentEndDate}`
      )
      .then((response) => {
        const defaultSevenDays = [...Array(7).keys()]
        const hasDates = response?.data?.dates?.length
        const hasPeriodicity = response?.data?.periodicity
        const disabledDays = hasDates 
          ? response?.data?.dates?.sort((a, b) => (a > b ? 1 : -1))  
          : hasPeriodicity && isFirstLoad
            ? defaultSevenDays?.map(el => moment()?.subtract(el + 1, 'day')?.format('YYYY-MM-DD'))
            : ''

        const newFilters = [...filterFields];

        if (isFirstLoad) {
          const currentDate = disabledDays !== '' ? moment(disabledDays[disabledDays?.length - 1]).add(3, 'hours') : moment(actualDateLessOne).add(3, 'hours');
          const hasEndDate = updatedFiltersChanged?.find(el => el?.param === 'endDate')

          newFilters[0][0].endDate = currentDate

          let formattedFiltersChanged = []

          if (hasEndDate) {
            formattedFiltersChanged = updatedFiltersChanged?.map(el => ({
              ...el
            }))
          } else {
            formattedFiltersChanged = [
              ...updatedFiltersChanged, 
              {
                param: 'endDate', 
                value: currentDate?.format('YYYY-MM-DD'), 
                urlParam: `endDate=${currentDate?.format('YYYY-MM-DD')}`
              }
            ]
          }
          
          setFiltersChanged(formattedFiltersChanged)
        }
        
        newFilters[0][0].shouldDisableEndDate = disabledDays !== '' ? disabledDays?.map(el => moment(el)?.format('D')) : disabledDays;

        setFilterFields(newFilters);

        setLoadedEndDateDays(true)
      })
      .catch((error) => {
        console.log(error);
        
      });
  }
  
  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'startDate',
        filterNameSecond: 'endDate',
        shouldDisableStartDate: startDateDisabledDays,
        shouldDisableEndDate: endDateDisabledDays,
        onMonthStartDateChange: onDateFilterStartMonthChange,
        onMonthEndDateChange: onDateFilterEndMonthChange
      },
      // {
      //   label: 'Contagem',
      //   filterName: 'periodicityId',
      //   placeHolder: 'Selecione...',
      //   type: 'autoComplete',
      //   urlPath: `${environments.catalog}/periodicities`,
      //   key: 'periodicityId',
      //   optionReference: 'description',
      //   size: 'medium',
      //   options: []
      // }
    ],
  ]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const getSalesInfos = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setSalesInfos({
      ...salesInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/sales?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setSalesInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setSalesInfos({
          loading: false,
          content: ''
        });
      });
  };

  const getCmvTheoretical = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvTheoreticalInfos({
      ...cmvTheoreticalInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/theory?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setCmvTheoreticalInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setCmvTheoreticalInfos({
          loading: false,
          content: ''
        });
      });
    
    setLoading(false);
  };

  const getCmvPerformed = (currentFilters) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvPerformedInfos({
      ...cmvPerformedInfos,
      loading: true,
    });

    axios
      .get(
        `${environments.dashBoard}/cmvCount/realized?restaurantId=${getId}&${params}`
      )
      .then((response) => {
        setCmvPerformedInfos({
          loading: false,
          content: response?.data || ''
        });
      })
      .catch((error) => {
        console.log(error);
        setCmvPerformedInfos({
          loading: false,
          content: ''
        });
      });
    
    setLoading(false);
  };

  const getCmvChartInfos = (currentFilters, currentSalesInfoType) => {
    const params = currentFilters.map((filter) => filter.urlParam).join('&');

    setCmvChartInfos({
      ...cmvChartInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cmvCount/graph?restaurantId=${getId}&${params}`

    axios.get(URL)
      .then((response) => {
        const mainObj = response?.data?.content?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setCmvChartInfos({
          contentFormatted: [
            {
              title: 'cmv realizado',
              data: mainObj?.map(el => parseFloat(!currentSalesInfoType ? el?.cmv?.realized : el?.cmv?.realizedDiscount)),
              dataFormatted: mainObj?.map((el, index) => {
                const currentValue = !currentSalesInfoType ? el?.cmv?.realizedMonetary : el?.cmv?.realizedDiscountMonetary

                return {
                  index,
                  item: `${parseFloat(el?.cmv?.realizedDiscount).toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}% (R$ ${currentValue?.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })})`
                }
              }),
              color: '#5062F0'
            },
            {
              title: 'cmv teórico',
              data: mainObj?.map(el => parseFloat(el?.cmv?.theoretical)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${parseFloat(el?.cmv?.theoretical).toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}%  (R$ ${el?.cmv?.theoreticalMonetary?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })})`
              })),
              color: '#F2B90D'
            },
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          customYLabelAfter: '%',
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          dates: mainObj?.map(el => el?.date),
          loading: false
        })
      })
      .catch((error) => {
        setCmvChartInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const handleFilter = (param, value) => {
    handleFilterOptions(param, value, filtersChanged, setFiltersChanged);
  };

  const handleMultipleFiltersFunc = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged
    );
  };

  const cmvAndCountSetupButton = () => (
    <Tooltip title="Contagem necessária para compor seu CMV" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="add"
        component="span"
        onClick={() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'cmvAndCountSetupDialog' ? { ...modal, open: true } : modal
            )
          );
        }}
      >
        <img
          style={{ width: '25px' }}
          src={CmvAndCountSetupIcon}
          alt="CmvAndCountSetupIcon"
        />
      </IconButton>
    </Tooltip>
  );

  useEffect(() => {
    if (loadedStartDateDays && loadedEndDateDays) {
      getSalesInfos(filtersChanged);
      getCmvTheoretical(filtersChanged);
      getCmvPerformed(filtersChanged)
      getCmvChartInfos(filtersChanged, salesInfoType)
    }
  }, [filtersChanged, loadedStartDateDays, loadedEndDateDays]);

  useEffect(() => {
    setId(paramsID);

    onDateFilterStartMonthChange("", "", true)
  }, [paramsID]);

  return (
    <Layout>
      <TitleHead title="CMV Dashboard" />

      <GlobalStyle />

      <Container className="cmvDashboardPage">
        <PageTitleAndFilter
          title="CMV"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          handleMultipleFilters={handleMultipleFiltersFunc}
          getFilterhandle={getFilterhandle}
          additionalbutton={cmvAndCountSetupButton}
          dynamicJustifyOff
        />

        <section className="cmvDashboardContainer">
          <SalesInfos
            salesInfos={salesInfos}
            salesInfoType={salesInfoType}
            setSalesInfoType={setSalesInfoType}
            getCmvChartInfos={getCmvChartInfos}
            filtersChanged={filtersChanged}
          />

          <div className="dFlex">
            <CmvTheoreticalInfos 
              cmvTheoreticalInfos={cmvTheoreticalInfos}
            />

            <PerformedCmvInfos
              cmvPerformedInfos={cmvPerformedInfos}
              salesInfoType={salesInfoType}
              setModalSettings={setModalSettings}
              modalSettings={modalSettings}
              filtersChanged={filtersChanged}
              companyActive={companyActive}
            />
          </div>

          {true ?
            <div className="cmvChartInfosCard">
              <SimpleLineGraphic
                paramsID={paramsID}
                mainContent={cmvChartInfos}
                // actualDateLessOne={actualDateLessOne}
                // actualDateLessFourteen={actualDateLessFourteen}
              />
            </div>
          : null}
        </section>
      </Container>

      <CommonAlert
        open={!!message.description}
        onClose={() => {
          setMessage({ description: '', status: '' })
        }}
        severity={message.status}
        indexMessage={message.description}
        messagePersonal={message.description}
      />

      {modalSettings.find((modal) => modal.name === 'cmvAndCountSetupDialog').open && (
        <Suspense fallback={<span />}>
          <CmvAndCountSetupDialog
            open={modalSettings.find((modal) => modal.name === 'cmvAndCountSetupDialog').open}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
            updateStorageLocations={updateStorageLocations}
            setMessage={setMessage}
            userInfo={userInfo}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'differenceInCount')
        .open && (
        <Suspense fallback={<span>Carregando...</span>}>
          <DifferenceInCount
            open={
              modalSettings.find((modal) => modal.name === 'differenceInCount')
                .open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'differenceInCount'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setModalSettings={setModalSettings}
            modalSettings={modalSettings}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'storageLocationDialog').open && (
        <StorageLocationForm
          isModal
          openModal={modalSettings.find((modal) => modal.name === 'storageLocationDialog').open}
          redirectPath={null}
          setUpdateList={setUpdateStorageLocations}
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'storageLocationDialog' ? { ...modal, open: false } : modal
              )
            );
          }}
          createMode
        />
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

export default connect(mapStateToProps)(CmvDashboard);