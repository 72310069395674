/* eslint-disable no-unsafe-optional-chaining */
import React, { Suspense, useEffect, useState } from 'react';

import { Dialog, IconButton, Paper } from '@material-ui/core';
import Axios from 'axios';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import CopilotDialog from 'components/Dialogs/Copilot';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import CloseIconDialog from 'images/icons/signals/closesAndCancels/closeDialog.svg';
import { DialogSalesProjection } from 'pages/production/Copilot/Dialogs/SalesProjection';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';

import CountInfosArea from './cockpitInfos/CountInfosArea';
import ProductionInfosArea from './cockpitInfos/ProductionInfosArea';
import ReceivementInfosArea from './cockpitInfos/ReceivementInfosArea';
import SalesInfosArea from './cockpitInfos/SalesInfosArea';
import ShoppingInfosArea from './cockpitInfos/ShoppingInfosArea';
import SkusInfosArea from './cockpitInfos/SkusInfosArea';

const environments = Environment(process.env.REACT_APP_ENV);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);

const DiscardPercentageAndValue = React.lazy(() =>
  import('components/Dialogs/DiscardPercentageAndValue')
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel='[class*="MuiDialogContent-root"]'
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Cockpit({ userInfo }) {
  const { id: paramsID } = useParams();
  const actualDate = new Date();
  const actualDateLessOne = moment(actualDate)?.subtract(1, 'day')?.format('YYYY-MM-DD');
  const actualDateLessFourteen = moment(actualDate)?.subtract(14, 'day')?.format('YYYY-MM-DD');
  const [shoppingFirstDate, setShoppingFirstDate] = useState(moment().startOf('isoWeek').subtract(2, 'week'))
  const [shoppingOrderType, setShoppingOrderType] = useState('positiveEvolution')
  const [filters] = useState([
    { param: 'originId', value: paramsID, urlParam: `originId=${paramsID}` },
    { param: 'days', value: 7, urlParam: `days=7` }
  ]);
  const [loadingCloseProgramming, setLoadingCloseProgramming] = useState(false);
  const [productModal, setProductModal] = useState({});
  const [checkConsume, setCheckConsume] = useState(false);
  const [detailsDiscard, setDetailsDiscard] = useState({})
  const [filtersChanged, setFiltersChanged] = useState(filters);
  const [cmvInfos, setCmvInfos] = useState()
  const [parameters, setParameters] = useState()
  const [modalStates, setModalStates] = useState({
    modalSalesProjection: false
  });
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'copilotConfig',
      fullScreen: false,
      maxWidth: 'lg',
    },
    {
      id: 2,
      name: 'discardPercentageAndValue',
      open: false,
      fullScreen: null,
      maxWidth: null
    },
    {
      id: 3,
      name: 'extracts',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      data: [],
      descriptionSku: null
    },
  ]);
  const [receivementInfos, setReceivementInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [countInfos, setCountInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [salesInfos, setSalesInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [skusInfos, setSkusInfos] = useState({
    content: [],
    contentFormatted: [],
  })
  const [productionInfos, setProductionInfos] = useState({
    content: [],
    contentFormatted: [],
    labels: [],
    loading: false,
  })
  const [shoppingInfos, setShoppingInfos] = useState({
    content: [],
    loading: false,
  })
  const [shoppingTotalInfos, setShoppingTotalInfos] = useState({
    content: [],
    loading: false,
  })

  const closeProgramming = () => {
    setLoadingCloseProgramming(true)
    const uri = `${environments.manufactures}/copilot/refused?originId=${paramsID}&date=${moment().format('YYYY-MM-DD')}&user=${userInfo.user}`

    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      setLoadingCloseProgramming(false)
    }, 1000);
  };

  const getCmvInfos = () => {
    setCmvInfos({
      ...cmvInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cockpit/cmv?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setCmvInfos({
          ...cmvInfos,
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setCmvInfos({
          ...cmvInfos,
          content: '',
          loading: false
        })
        console.log(error);
      });
  }

  const getSalesInfos = () => {
    setSalesInfos({
      ...salesInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cockpit/salesProjection?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        const mainObj = response?.data?.content?.vendas?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setSalesInfos({
          contentFormatted: [
            {
              title: 'vendas',
              data: mainObj?.map(el => parseFloat(el?.sales)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `R$ ${el?.sales?.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}`
              })),
              color: '#5062F0'
            },
            {
              title: 'projeção',
              data: mainObj?.map(el => parseFloat(el?.projection)),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `R$ ${el?.projection?.toLocaleString('pt-br', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}`
              })),
              color: '#F2B90D'
            },
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          labels: mainObj?.map(el => moment(el?.date)?.format('DD/MM')),
          dates: mainObj?.map(el => el?.date),
          loading: false,
          projectionOfDay: response?.data?.content?.projectionOfDay
        })
      })
      .catch((error) => {
        setSalesInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getSkusInfos = () => {
    setSkusInfos({
      ...productionInfos,
      loading: true
    })
    
    const URL = `${environments.dashBoard}/cockpit/skus?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setSkusInfos({
          content: response?.data,
          loading: false
        })
      })
      .catch((error) => {
        setSkusInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getProductionInfos = () => {
    setProductionInfos({
      ...productionInfos,
      loading: true
    })
    
    const URL = `${environments.dashBoard}/cockpit/manufactures?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setProductionInfos({
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setProductionInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getCountInfos = () => {
    setCountInfos({
      ...countInfos,
      loading: true
    })

    const URL = `${environments.dashBoard}/cockpit/counts?restaurantId=${paramsID}&lastDays=14`

    Axios.get(URL)
      .then((response) => {
        setCountInfos({
          content: response?.data?.content,
          loading: false
        })
      })
      .catch((error) => {
        setCountInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getReceivementInfos = () => {
    setReceivementInfos({
      ...receivementInfos,
      loading: true
    })

    Axios.get(`${environments.dashBoardV3}/home/orders/total?originId=${paramsID}&rangeDays=14`)
      .then((response) => {
        const mainObj = response?.data?.content?.orders?.sort((a, b) => (a?.date > b?.date ? 1 : -1))
        const yMinEl = Math.min(...mainObj?.map(el => el?.date)?.map(el => el))
        const yMaxEl = Math.max(...mainObj?.map(el => el?.date)?.map(el => el))

        setReceivementInfos({
          contentFormatted: [
            {
              title: 'à receber',
              data: mainObj?.map(el => el?.count),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.count} ${el?.count === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#F2B90D'
            },
            {
              title: 'recebido',
              data: mainObj?.map(el => el?.received),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.received} ${el?.received === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#08AA15'
            },
            {
              title: 'recebido s/ catalogar',
              data: mainObj?.map(el => el?.receivedWithoutCataloging),
              dataFormatted: mainObj?.map((el, index) => ({
                index,
                item: `${el?.receivedWithoutCataloging} ${el?.receivedWithoutCataloging === 1 ? 'pedido' : 'pedidos'}`
              })),
              color: '#F53D4C'
            }
          ],
          yMin: yMinEl,
          yMax: yMaxEl,
          labels: mainObj?.map(el => moment(el?.date).add(3, 'hours')?.format('DD/MM')),
          loading: false,
          pendingReceipts: response?.data?.content?.pendingReceipts
        })
      })
      .catch((error) => {
        setReceivementInfos({
          content: [],
          contentFormatted: [],
          labels: [],
          loading: false
        })
        console.log(error);
      });
  }

  const getShoppingInfos = () => {
    setShoppingInfos({
      ...shoppingInfos,
      loading: true
    })
    const firstDateFormatted = moment(shoppingFirstDate).format('YYYY-MM-DD')

    Axios.get(`${environments.dashBoard}/cockpit/monthlyEvolution?restaurantId=${paramsID}&startDates=${`${firstDateFormatted}`}&orderBy=${shoppingOrderType}`)
    .then((response) => {
      setShoppingInfos({
        content: response?.data?.content,
        loading: false
      })
    })
    .catch((error) => {
      setShoppingInfos({
        content: [],
        loading: false
      })
      console.log(error);
    });
  }

  const getShoppingTotalInfos = () => {
    setShoppingTotalInfos({
      ...shoppingTotalInfos,
      loading: true
    })
    const firstDateFormatted = moment(shoppingFirstDate).format('YYYY-MM-DD')

    Axios.get(`${environments.dashBoard}/cockpit/monthlyEvolution?restaurantId=${paramsID}&startDates=${`${firstDateFormatted}&totals=true`}`)
    .then((response) => {
      setShoppingTotalInfos({
        content: response?.data?.content,
        loading: false
      })
    })
    .catch((error) => {
      setShoppingTotalInfos({
        content: [],
        loading: false
      })
      console.log(error);
    });
  }

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${paramsID}/parameters`
      );
      const parametersResponse = await response.json();
      setParameters(parametersResponse || '');
    })();
  }, [paramsID]);

  useEffect(() => {
    getCmvInfos()
    getSalesInfos()
    getSkusInfos()
    getProductionInfos()
    getCountInfos()
    getReceivementInfos()
  }, [paramsID])

  useEffect(() => {
    getShoppingInfos()
    getShoppingTotalInfos()
  }, [paramsID, shoppingOrderType])

  return (
    <Layout>
      <GlobalStyle/>
      
      <TitleHead title="Cockpit" />

      <Container className="cockpitInfosPage mb0">
        <PageTitleAndFilter
          title={`Cockpit | últimos ${14} dias`}
        />

        <div className="dFlex w100">
          <SalesInfosArea 
            salesInfos={salesInfos}
            cmvInfos={cmvInfos}
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
            setModalStates={setModalStates}
            modalStates={modalStates}
            parametersInfos={parameters}
          />

          <SkusInfosArea 
            skusInfos={skusInfos} 
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
          /> 
        </div> 

        <div className="dFlex w100">
          <div className="dFlex flexColumn" style={{ width: "49%", marginRight: ".5vw" }}>
            <div className="dFlex w100">
              <ProductionInfosArea 
                productionInfos={productionInfos} 
                paramsID={paramsID}
                actualDateLessOne={actualDateLessOne}
                actualDateLessFourteen={actualDateLessFourteen}
                setModalSettings={setModalSettings}
                modalSettings={modalSettings}
              />

              <CountInfosArea 
                countInfos={countInfos} 
                paramsID={paramsID}
                actualDateLessOne={actualDateLessOne}
                actualDateLessFourteen={actualDateLessFourteen}
                setModalSettings={setModalSettings}
                modalSettings={modalSettings}
              />
            </div>

            <ReceivementInfosArea 
              receivementInfos={receivementInfos}
              paramsID={paramsID}
              actualDateLessOne={actualDateLessOne}
              actualDateLessFourteen={actualDateLessFourteen}
            />          
          </div>

          <ShoppingInfosArea 
            shoppingInfos={shoppingInfos}
            shoppingOrderType={shoppingOrderType}
            shoppingTotalInfos={shoppingTotalInfos}
            setShoppingOrderType={setShoppingOrderType}
            shoppingFirstDate={shoppingFirstDate}
            paramsID={paramsID}
            actualDateLessOne={actualDateLessOne}
            actualDateLessFourteen={actualDateLessFourteen}
          />
        </div>
      </Container>

      {modalStates?.modalSalesProjection && (
        <Dialog
          fullWidth
          className='dialogSalesProjection'
          open={modalStates?.modalSalesProjection}
          onClose={() => {
            setModalStates({
              ...modalStates,
              modalSalesProjection: false
            });
          }}
          PaperComponent={PaperComponent}
        >
          <article
            id="draggable-dialog-title"
            className="dFlex justifyBetween"
            style={{ background: 'rgb(31, 36, 69)', padding: '15px' }}
          >
            <span className="white fontSize16">
              <b>Projeção de Vendas</b>
            </span>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setModalStates({
                  ...modalStates,
                  modalSalesProjection: false
                });
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right', right: 0, padding: '0' }}
            >
              <img
                src={CloseIconDialog}
                alt="CloseIconNavy"
                style={{ width: 12 }}
              />
            </IconButton>
          </article>

          <DialogSalesProjection 
            userInfo={userInfo} 
            onClose={() => 
              setModalStates({
                ...modalStates,
                modalSalesProjection: false
              })
            }
          />
        </Dialog>
      )}

      {modalSettings.find(modal => modal.name === 'copilotConfig').open && (
        <CopilotDialog
          open={modalSettings.find((modal) => modal.name === 'copilotConfig').open}
          modalSettings={modalSettings}
          closeProgramming={closeProgramming}
          loadingCloseProgramming={loadingCloseProgramming}
          setLoadingCloseProgramming={setLoadingCloseProgramming}
          setModalSettings={setModalSettings}
          handleClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'copilotConfig'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'discardPercentageAndValue')
        .open && (
        <Suspense fallback={<span>Carregando...</span>}>
          <DiscardPercentageAndValue
            open={
              modalSettings.find(
                (modal) => modal.name === 'discardPercentageAndValue'
              ).open
            }
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'discardPercentageAndValue'
                    ? { ...modal, open: false }
                    : modal
                )
              );
            }}
            setProductModal={setProductModal}
            setDetailsDiscard={setDetailsDiscard}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
          />
        </Suspense>
      )}

      {modalSettings.find((modal) => modal.name === 'extracts').open && (
        <Suspense fallback={<span />}>
          <TheoryStockDialog
            getId={paramsID}
            openModal={{
              modalProduct: modalSettings.find(
                (modal) => modal.name === 'extracts'
              ).open,
              modalDiscard: modalSettings.find(
                (modal) => modal.name === 'discardPercentageAndValue'
              ).open
            }}
            handleClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'extracts' ? { ...modal, open: false } : modal
                )
              );
            }}
            checkConsume={checkConsume}
            setCheckConsume={setCheckConsume}
            productModal={productModal}
            setProductModal={setProductModal}
            parameters={parameters}
            originPage="stocksPage"
            defaultStartDate={
              new Date(
                moment(
                  modalSettings.find((modal) => modal.name === 'discardPercentageAndValue').currentStartDate
                )
              ) || 
              new Date(
                moment(
                  modalSettings.find((modal) => modal.name === 'extracts').startDate
                )
              )
            }
            countDate={
              modalSettings.find((modal) => modal.name === 'extracts').endDate
            }
            defaultExtractType={
              modalSettings.find((modal) => modal.name === 'extracts').extractType
            }
            filterDiscardId={
              modalSettings.find((modal) => modal.name === 'extracts').discardId
            }
          />
        </Suspense>
      )}
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cockpit);