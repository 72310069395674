/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import DateInputField from 'components/PageTitleAndFilter/DateInputField/DateInputField';
import PaperComponent from 'components/PaperComponent';
import CloseIcon from 'images/icons/signals/closesAndCancels/close.svg';
import * as ProductsActions from 'store/actions/products';
import Environment from 'utils/environments';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

const environments = Environment(process.env.REACT_APP_ENV);

function Discard({
  isMenu,
  open,
  fullScreen,
  maxWidth,
  onClose,
  onSubmit,
  item,
  setItem,
  loading,
  currentDiscardReason,
  setCurrentDiscardReason,
  initialDate,
  originPage = 'PRODUCT',
  userInfo
}) {
  const { id: paramsID } = useParams();
  const isManager = userInfo?.profile?.[0]?.[0]?.name === 'manager';
  const isRestaurantAdmin = userInfo?.profile?.[0]?.[0]?.name === 'restaurants';
  const [optionsDiscard, setOptionsDiscard] = useState([]);
  const [date, setDate] = useState(initialDate);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const isEnabledToSubmit = isManager || isRestaurantAdmin;
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const [loadingManagerValidationText, setLoadingManagerValidationText] = useState('');
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [managerInfos, setManagerInfos] = useState('');
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'confirmManagerValidationDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
  ]);

  const validateManager = (manager) => {
    setLoadingManagerValidationText('Autorizando...')

    const body = {
      credential: manager?.user,
      password: manager?.password
    }

    axios.post( 
      `${environments.auth}/users/verifyProfile?groupId=${userInfo?.companies?.groups[0]?.id}`,
      body
    ).then((response) => {
      if(response.data.asAllowed){
        setMessage({
          ...message,
          description: 'Credenciais validadas',
          status: 'success' 
        })
        setEnableSubmitButton(true)
        setLoadingManagerValidationText('')
        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'confirmManagerValidationDialog'
              ? {
                  ...modal,
                  open: false,
                }
              : modal
          )
        );
      }else{
        setMessage({
          ...message,
          description: 'Credenciais inválidas',
          status: 'error' 
        })
        setLoadingManagerValidationText('')
      }
    })
    .catch((error) => {
      setMessage({
        ...message,
        description: 'Credenciais inválidas',
        status: 'error' 
      })
      setLoadingManagerValidationText('')
      console.log(error);
    });
  }

  const handleValidateManager = (manager) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      validateManager(manager);
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  // Discards
  useEffect(() => {
    (async () => {
      await axios
        .get(
          `${environments.catalog}/discards?type=${originPage}&size=1000&page=1&originId=${paramsID}`
        )
        .then((response) => {
          const items = response.data.content;
          setOptionsDiscard(items);
        })
        .catch((error) => {
          setMessage({
            ...message,
            description: error?.response?.data?.message,
            status: 'error'
          });
        });
    })();
  }, [paramsID]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        PaperComponent={PaperComponent}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal discardDialog"
      >
        <DialogTitle
          className="modalHeader bold textAlignCenter cursorMove discardHeader"
        >
          <Typography 
            id="draggable-dialog-title"
            className="textAlignLeft"
            style={{ width: '95%' }}
          >
            Lançar descarte
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              onClose();
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent discart">
          <Grid container spacing={5} className="w100 m0">
            <Grid item xs={12} className="pl0 pr0">
              <div className="fixedInfo">
                <span className="labelName big">Produto</span>
                <Typography className="productDescription">
                  {item?.data?.description}
                </Typography>
              </div>
            </Grid>

            <Grid container item spacing={5} className="w100 m0 p0 mt20">
              <Grid item style={{ padding: '0px' }} className="datePicker">
                <DateInputField
                  label="Data"
                  handleFilter={(param, value) => {
                    setItem({
                      ...item,
                      discard: item.discard.map((el) => ({
                        ...el,
                        eventDate: value
                      }))
                    });
                    setDate(value)
                  }}
                  filterName="date"
                  size="small"
                  inputSizes={{
                    small: 70,
                    medium: 155,
                    medium_plus: 210,
                    large: 250
                  }}
                  initialDate={initialDate}
                  maxDate={new Date()}
                />
              </Grid>

              <Grid item xs={4} style={{ padding: '0px' }}>
                <CurrencyTextField
                  variant="outlined"
                  size="small"
                  currencySymbol=""
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  decimalPlaces={3}
                  decimalPlacesShownOnFocus={3}
                  type="text"
                  name="producaomin"
                  fullWidth
                  label="Qtde"
                  className="textFieldSmall childTextAlignRight childPr0 labelTextAlignRight"
                  placeholder="Digite aqui..."
                  onChange={(e) =>
                    setItem({
                      ...item,
                      discard: item.discard.map((el) => ({
                        ...el,
                        type: isMenu ? 3 : 2,
                        quantity:
                          parseFloat(e.target.value.replace(',', '.')) *
                          parseFloat(el.initialQuantity || 1)
                      }))
                    })
                  }
                  InputProps={{
                    maxLength: 8,
                    classes: { input: '' },
                    style: { fontSize: '11px' }
                  }}
                />
              </Grid>

              <Grid item style={{ padding: '0px', marginLeft: '10px' }}>
                <div className="fixedInfo">
                  <span className="labelName">Un.</span>

                  <Typography className="productDescription">
                    {item?.data?.unitsMeasurementsAbbreviation}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              spacing={5}
              className="w100 m0 p0 mt20 discardType"
            >
              <Grid item xs={12} className="pl0 pr0 pb0">
                <Autocomplete
                  size="small"
                  onChange={(_, value) => {
                    setCurrentDiscardReason(value);
                    setItem({
                      ...item,
                      discard: item.discard.map((el) => ({
                        ...el,
                        discardId: value?.id
                      }))
                    });
                  }}
                  getOptionLabel={(option) => option.description}
                  options={optionsDiscard}
                  value={
                    optionsDiscard.filter(
                      (option) => option.description === currentDiscardReason
                    )[0]
                  }
                  className="autocompleteSmall discard"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="discardType"
                      label="Tipo de descarte"
                      variant="outlined"
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <DialogActions style={{ padding: '0px' }}>
            <Button
              onClick={() => {
                const isBefore = moment(date).isBefore(moment(), 'day')

                if (enableSubmitButton) {
                  onSubmit()
                  return;
                }

                if (!isEnabledToSubmit && isBefore) {
                  setModalSettings(
                    modalSettings.map((modal) =>
                      modal.name === 'confirmManagerValidationDialog'
                        ? {
                            ...modal,
                            open: true,
                            title: "AUTORIZAR DESCARTE"
                          }
                        : modal
                    )
                  );

                  return;
                }

                onSubmit()
              }}
              disabled={
                item?.discard?.some((el) => !el?.quantity || !el?.discardId) ||
                loading
              }
              className="defaultButton submitButton"
              type="submit"
              design="contained"
              label={loading ? 'Salvando...' : 'Salvar'}
              style={{ outline: 0 }}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>

      <CommonAlert
        open={message?.description}
        onClose={() => {
          setMessage({
            description: '',
            status: ''
          })
        }}
        messagePersonal={message?.description}
        indexMessage={messageError}
        statusCode={statusCode}
        severity={message?.status}
        width="50%"
      />

      {modalSettings.find((modal) => modal.name === 'confirmManagerValidationDialog')?.open && (
        <ConfirmModal
          open={
            modalSettings.find((modal) => modal.name === 'confirmManagerValidationDialog').open
          }
          title={
            modalSettings.find((modal) => modal.name === 'confirmManagerValidationDialog')?.title
          }
          content={
            <div className="dFlex flexColumn cardDefault pt16 pb0">
              <TextField
                className="newPassword textFieldSmall mt16"
                type="string"
                label="Usuário Gestor"
                value={managerInfos?.user}
                placeholder="Digite aqui..."
                onChange={(e) => {
                  setManagerInfos({
                    ...managerInfos,
                    user: e.target.value
                  });
                }}
              />

              <TextField
                className="newPassword textFieldSmall mt16"
                type="password"
                label="Senha"
                value={managerInfos?.password}
                placeholder="Digite aqui..."
                onChange={(e) => {
                  setManagerInfos({
                    ...managerInfos,
                    password: e.target.value
                  });
                }}
              />

              <Typography className="mt20" style={{ color: '#666770' }}>
                * Descartes com data retroativa necesssitam de autorização de um gestor do restaurante
              </Typography>
            </div>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmManagerValidationDialog'
                  ? {
                      ...modal,
                      open: false,
                    }
                  : modal
              )
            );
          }}
          onConfirm={() => {
            handleValidateManager(managerInfos)
          }}
          loadingConfirmText={loadingManagerValidationText}
          disabledButton={!managerInfos?.user || !managerInfos?.password || loadingManagerValidationText}
          confirmText="Autorizar"
          customClass="confirmManagerValidationDialog"
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  productInfo: state.products,
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Discard);
